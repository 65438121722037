import React from "react";
import { useState } from "react";
import { ModalCompletarDia } from "./ModalCompletarDia";
import { useModalCompletarDia } from "./useModalCompletarDia";
import { CARITASTOLEDO, ECINNOVA, INSERTATOLEDO, PROJECT_APP } from "../../../js/project";

export const ModalsCompletarDia = ({ disableButtonf, botonDiaCompleto }) => {
  const [isOpenModal, openModal, closeModal] = useModalCompletarDia(false);
  const [comentarioDiaCompleto, setComentarioDiaCompleto] = useState("");
  const [disableButtonPausa, setDisableButtonPausa] = useState(false);
  const [comentarioError, setComentarioError] = useState("");

  async function onClickAceptar() {
    if (comentarioDiaCompleto === "") {
      setComentarioError("Por favor indica motivo")
    }
    else {
      setComentarioError("")
      setDisableButtonPausa(true);
      disableButtonf();
      botonDiaCompleto(comentarioDiaCompleto)
    }
  }

  return (
    <>
      <button
        className="allDayButton"
        disabled={disableButtonPausa}
        onClick={openModal}
      >
        Dia completo
      </button>

      <ModalCompletarDia isOpen={isOpenModal} closeModal={closeModal}>
        <div className="textleft titulo2">Completar día</div>
        <hr />

        <div className="textleft">
          {PROJECT_APP === ECINNOVA && 
          <>
            <div className="textleft pbottom-16">
              Motivo
              <sup className="colorred"> *obligatorio</sup>
            </div>
            <textarea
              className="hw-100"
              rows={4}
              placeholder="Viaje de negocios..."
              value={comentarioDiaCompleto}
              onChange={(e) => {
                setComentarioDiaCompleto(e.target.value);
                setComentarioError("")
              }}
            />
          </>
          }
          {PROJECT_APP === INSERTATOLEDO && 
          <>
            {/* Nada porque en insertatoledo no fichan dia completo */}
          </>
          }
          {PROJECT_APP === CARITASTOLEDO && 
          <>
            <div className="paddingy-8">Esta acción permite fichar el dia completo por uno de los siguientes motivos:<sup className="colorred"> *obligatorio</sup></div>
            <select className="marginbottom-8" onChange={(e) => {setComentarioDiaCompleto(e.target.value); setComentarioError("") }} defaultValue={""}>
              <option key="" value=""></option>
              <option key="ENCUENTRO DE TRABAJADORES" value="ENCUENTRO DE TRABAJADORES">ENCUENTRO DE TRABAJADORES</option>
              <option key="FORMACION" value="FORMACION">FORMACION</option>
            </select>
          </>
          }
        </div>
        <div className="colorred textleft marginbottom-80">{comentarioError}</div>
        <div className="textright">
          <button
            disabled={disableButtonPausa}
            className="cancelButton"
            onClick={closeModal}
          >
            Cancelar
          </button>
          <button disabled={disableButtonPausa} className="acceptButton" onClick={onClickAceptar}>
            Aceptar
          </button>
        </div>
      </ModalCompletarDia>
    </>
  );
};
