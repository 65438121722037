import React from "react";
import { ControlHorarioTrabajadores } from "./routes/ControlHorarioTrabajadores";
import { Login } from "./routes/Login";
import { Raiz } from "./routes/Raiz"
import { Routes, Route, HashRouter } from "react-router-dom";
import { Update } from "./routes/Update";
import { ControlHorarioAdmin } from "./routes/ControlHorarioAdmin";
import { ControlHorarioProvider } from "./components/controlhorariotrabajadores/ControlHorarioContext";
import { ChangePassword } from "./routes/ChangePassword";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="controlHorario" element={
          <ControlHorarioProvider>
            <ControlHorarioTrabajadores />
          </ControlHorarioProvider>
        }/>
        <Route path="/" element={<Raiz />} />
        <Route path="admin" element={<ControlHorarioAdmin />} />
        <Route path="update" element={<Update />} />
        <Route path="login" element={<Login />} />
        <Route path="changePassword" element={<ChangePassword />} />
        <Route path="*" element={<div>Error 404 page not found</div>} />
      </Routes>
    </HashRouter>
  );
}

export default App;