import { WEEK_DAYS, MONTHS } from "./consts";

/**
 * 0 => DOMINGO ANTERIOR
 * 1 => LUNES
 * 2 => MARTES
 * 3 => MIERCOLES
 * 4 => JUEVES
 * 5 => VIERNES
 * 6 => SABADO
 * 7 => DOMINGO
 */

// Devuelve el dia de la semana actual segun la lista de arriba
export function diaSemana() {
  if (new Date().getDay() !== 0) {
    return new Date().getDay();
  } 
  else {
    return 7;
  } 
}

// Devuelve el dia anterior al actual de la semana (ayer) segun la lista de arriba
export function diaAnteriorSemana() {
  if (new Date().getDay() !== 0) { 
    return new Date().getDay() - 1;
  } 
  else {
    return 6; 
  } 
}

// Formatea la hora en formato HH:MM
export function formatHour(horas, minutos) {
  if (horas < 10) horas = "0" + horas;
  if (minutos < 10) minutos = "0" + minutos;

  return `${horas}:${minutos}`;
}

// Transforma segundos a horas y minutos
export function formatSeconds(seconds) {
  let horas = parseInt(seconds / 3600);
  let minutos = parseInt((seconds % 3600) / 60);

  if (horas < 10) horas = "0" + horas;
  if (minutos < 10) minutos = "0" + minutos;

  return `${horas}:${minutos}`;
}

// Transforma un timestamp a una fecha en formato YYYY-MM-DD
export function formatDate(date) { // date: timestamp
  let mes = date.getMonth() + 1;
  if (mes < 10) mes = "0" + mes;
  let dia = date.getDate();
  if (dia < 10) dia = "0" + dia;

  return date.getFullYear() + "-" + mes + "-" + dia;
}

// Transforma una fecha de formato YYYY-MM-DD a YYYY/MM/DD
export function formatFecha(fecha) { // string: YYYY-MM-DD
  const [año, mes, dia] = (fecha).split("-")
  return `${año}/${mes}/${dia}`
}

// Devuelve el año y el mes de la fecha en formato YYYY-MM
export function formatAñoMes(date) { // date: timestamp
  let mes = date.getMonth() + 1;
  if (mes < 10) mes = "0" + mes;

  return date.getFullYear() + "-" + mes;
}

// Agrega un 0 delante a los numeros menores a 10
export function formatNumber(number) {
  if (number < 10) return `0${number}`
  return number
}

// Devuelve el dia de una fecha de formato YYYY-MM-DD 
export function getDiaByFecha(fecha) { // string: YYYY-MM-DD
  const [año, mes, dia] = (fecha).split("-")
  return dia
}

// Devuelve el año de una fecha de formato YYYY-MM-DD 
export function getAñoByFecha(fecha) { // string: YYYY-MM-DD
  const [año, mes, dia] = (fecha).split("-")
  return año
}

// Devuelve el nombre del mes de una fecha de formato YYYY-MM-DD 
export function getNombreMesByFecha(fecha) { // string: YYYY-MM-DD
  const [año, mes, dia] = (fecha).split("-")
  return MONTHS[parseInt(mes) - 1].abbr
}

// Devuelve el nombre del dia de la semana de una fecha de formato YYYY-MM-DD 
export function getNombreDiaSemanaByFecha(fecha) { // string: YYYY-MM-DD
  return WEEK_DAYS[new Date(fecha).getDay()]
}

// Suma o resta dias depende del argumento que se le pase a la funcion
export function sumarDias(date, dias) { // date: timestap, dias: number
  date.setDate(date.getDate() + dias);
  return date;
}

// Devuelve el mes de una fecha (timestamp) ya formateado
// export function formatMes(date) { // date: timestap
//   let mes = date.getMonth() + 1;
//   if (mes < 10) mes = "0" + mes;

//   return `${mes}`;
// }

// Devuelve el mes actual ya formateado
export function getFormatActualMonth() {
  let mes = new Date().getMonth() + 1;
  if (mes < 10) mes = "0" + mes;

  return `${mes}`
}

// Devuelve el año actual ya formateado
export function getFormatActualYear() {
  return `${new Date().getFullYear()}`
}

// Devuelve el string recibido con la primera letra mayuscula y las demás en minuscula
export function formatMayus(string) {
  let stringMinuscula = string.toLowerCase()
  return stringMinuscula.charAt(0).toUpperCase() + stringMinuscula.slice(1);
}

// Comprueba si el string es vacío y si no devuelve el string con ":" y un espacio delante
export function comprobarDosPuntos(string) {
  if (string === "") return ""
  else return `: ${string}`
}

// Comprueba si el alias del usuario es vacío y si no devuelve el alias con un espacio delante
export function comprobarAlias(user) {
  if (user.alias !== "") return ` (${user.alias})`
  else return ""
}

// Devuelve si el dispositivo usado es movil o ordenador
export function detectarMovil() {
  if (navigator.userAgent.match(/Android/i) || 
    navigator.userAgent.match(/Mobile/i) || 
    navigator.userAgent.match(/webOS/i) || 
    navigator.userAgent.match(/iPhone/i) || 
    navigator.userAgent.match(/iPad/i) || 
    navigator.userAgent.match(/iPod/i) || 
    navigator.userAgent.match(/BlackBerry/i) || 
    navigator.userAgent.match(/Windows Phone/i)) {
      return ("MOVIL")
  } else {
      return ("ORDENADOR")
  }
}

// Calcula las horas semanales respecto a un horario pasado como parametro (admin)
export function calcHorasSemanales(horario) {
  if (horario === undefined || horario === "") return "00:00"
  let minutosTotalesSemanales = 0;
  let horasSemanales = ""
  let minutosSemanales = ""
  for(let i=0; i<=6; i++) minutosTotalesSemanales = calcHoras(i, horario) + minutosTotalesSemanales
  
  if (minutosTotalesSemanales/60 < 10) horasSemanales = `0${parseInt(minutosTotalesSemanales/60)}`
  else horasSemanales = `${parseInt(minutosTotalesSemanales/60)}`
  if (minutosTotalesSemanales%60 < 10) minutosSemanales = `0${parseInt(minutosTotalesSemanales%60)}`
  else minutosSemanales = `${parseInt(minutosTotalesSemanales%60)}`
  
  if (parseInt(minutosSemanales) > -1) return horasSemanales + ":" + minutosSemanales
  else return "Formato Incorrecto"
}

// Calcula el for de la funcion anterior (calcHorasSemanales(horarios))
function calcHoras(diaSemana, horario) {
  if (horario[diaSemana].inicio === "") return 0
  if (horario[diaSemana].dobleDia === true) {
    const arrIni = (horario[diaSemana].inicio).split(":")
    const arrFin = (horario[diaSemana].fin).split(":")
    const minutosInicio = parseInt(arrIni[0]) * 60 + parseInt(arrIni[1])
    const minutosFin = parseInt(arrFin[0]) * 60 + parseInt(arrFin[1]) + 1440

    if (arrIni[0] < 24 && arrIni[0] > -1 && arrFin[0] < 24 && arrFin[0] > -1 && arrIni[1] < 60 && arrIni[1] > -1 && arrFin[1] < 60 && arrFin[1] > -1) {
      return minutosFin - minutosInicio
    }
    else {
      return "error"
    } 
  }
  else if (horario[diaSemana].inicioTarde !== "") {
    const arrIni = (horario[diaSemana].inicio).split(":")
    const arrFin = (horario[diaSemana].fin).split(":")
    const arrIniTarde = (horario[diaSemana].inicioTarde).split(":")
    const arrFinTarde = (horario[diaSemana].finTarde).split(":")

    const minutosInicio = parseInt(arrIni[0]) * 60 + parseInt(arrIni[1])
    const minutosFin = parseInt(arrFin[0]) * 60 + parseInt(arrFin[1])
    const minutosInicioTarde = parseInt(arrIniTarde[0]) * 60 + parseInt(arrIniTarde[1])
    const minutosFinTarde = parseInt(arrFinTarde[0]) * 60 + parseInt(arrFinTarde[1])

    if (arrIni[0] < 24 && arrIni[0] > -1 && arrFin[0] < 24 && arrFin[0] > -1 && arrIniTarde[0] < 24 && arrIniTarde[0] > -1 && arrFinTarde[0] < 24 && arrFinTarde[0] > -1 && arrIni[1] < 60 && arrIni[1] > -1 && arrFin[1] < 60 && arrFin[1] > -1 && arrIniTarde[1] < 60 && arrIniTarde[1] > -1 && arrFinTarde[1] < 60 && arrFinTarde[1] > -1) {
        return ((minutosFin - minutosInicio) + (minutosFinTarde - minutosInicioTarde));
    } 
    else {
        return "error"
    } 
  }
  else {
    const arrIni = (horario[diaSemana].inicio).split(":")
    const arrFin = (horario[diaSemana].fin).split(":")
    const minutosInicio = parseInt(arrIni[0]) * 60 + parseInt(arrIni[1])
    const minutosFin = parseInt(arrFin[0]) * 60 + parseInt(arrFin[1])

    if (arrIni[0] < 24 && arrIni[0] > -1 && arrFin[0] < 24 && arrFin[0] > -1 && arrIni[1] < 60 && arrIni[1] > -1 && arrFin[1] < 60 && arrFin[1] > -1) {
      return minutosFin - minutosInicio
    }
    else {
      return "error"
    } 
  }
}