export const ModalVerRegistros = ({children, isOpen, closeModal}) => {

  const handleModalContainerClick = e => e.stopPropagation()
  
  return (
    <article className={`modal-ver-registros ${isOpen && "is-open"}`} onClick={closeModal}>
        <div className="modal-container-ver-registros" onClick={handleModalContainerClick}>
          <button className="modal-close-pausa cancelButton" onClick={closeModal}>X</button>
            {children}
        </div>
    </article>
  )
}