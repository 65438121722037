import { useContext } from "react";
import Header from "../components/controlhorariotrabajadores/Header";
import HorasDiaSemana from "../components/controlhorariotrabajadores/HorasDiaSemana";
import Botones from "../components/controlhorariotrabajadores/Botones";
import TablaHoras from "../components/controlhorariotrabajadores/TablaHoras";
import DatosUsuarioActual from "../components/controlhorariotrabajadores/DatosUsuarioActual";
import DatosUsuarioActualLoading from "../components/controlhorariotrabajadores/DatosUsuarioActualLoading";
import Footer from "../components/controlhorariotrabajadores/Footer";
import HeaderLoading from "../components/controlhorariotrabajadores/HeaderLoading";
import HorasDiaSemanaLoading from "../components/controlhorariotrabajadores/HorasDiaSemanaLoading";
import BotonesLoading from "../components/controlhorariotrabajadores/BotonesLoading";
import TablaHorasLoading from "../components/controlhorariotrabajadores/TablaHorasLoading";
import { ControlHorarioContext } from "../components/controlhorariotrabajadores/ControlHorarioContext";
import { colorProject } from "./../js/project"

// ruta predeterminada trabajadores

export function ControlHorarioTrabajadores() {
  const {
    loading,
    user,
    semana,
    botonIniciar,
    botonFin,
    botonPausa,
    botonReiniciarPausa,
    botonDiaCompleto
    
  } = useContext(ControlHorarioContext);

  // console.log("---------------------");
  // console.log({loading});
  // console.log({user});
  // console.log({semana});

  if (loading === true) {
    return (
      <div style={{backgroundColor: colorProject}}>
        <div className="container1140px">
          <HeaderLoading />
          <main className="padding-20">
            <DatosUsuarioActualLoading />
            <HorasDiaSemanaLoading />
            <BotonesLoading />
            <TablaHorasLoading />
          </main>
          <Footer />
        </div>
      </div>
    );
  }
  else if (loading === false) {
    return (
      <div style={{backgroundColor: colorProject}}>
        <div className="container1140px">
          <Header trabajador={user} />
          <main className="padding-20">
            <DatosUsuarioActual user={user} />
            <HorasDiaSemana semana={semana} />
            <Botones user={user} semana={semana} botonIniciar={botonIniciar} botonFin={botonFin} botonPausa={botonPausa} botonReiniciarPausa={botonReiniciarPausa} botonDiaCompleto={botonDiaCompleto} />
            <TablaHoras semana={semana} />
          </main>
          <Footer />
        </div>
      </div>
    );
  }
}
