export const ModalAjustes = ({children, isOpen, closeModal}) => {

  const handleModalContainerClick = e => e.stopPropagation()
  
  return (
    <article className={`modal-ajustes ${isOpen && "is-open"}`} onClick={closeModal}>
      <div className="modal-container-ajustes" onClick={handleModalContainerClick}>
      <button className="modal-close-ajustes cancelButton" onClick={closeModal}>X</button>
        {children}
      </div>
    </article>
  )
}