import { comprobarDosPuntos, formatMayus } from "../../js/functions";

export function ColocarMotivoPausa({ registro }) {

  if (registro.trabajado === false) return "" 
  
  if (registro.pausas !== undefined && registro.pausas !== null) {
    return (
      <>
        {registro.pausas.map((pausa, index) => (
          <div key={index}>
            {pausa.tipo === 0 && 
              <div>Pausa jornada partida</div>
            }
            {pausa.tipo === 1 && 
              <div>Pausa personal{comprobarDosPuntos(pausa.comentarioPausa)}</div>
            }
            {pausa.tipo === 2 && 
              <div>Pausa justificada - {formatMayus(pausa.tipoPausaJustificada)}{comprobarDosPuntos(pausa.comentarioPausa)}</div>
            }
          </div>
        ))}
      </>
    );
  }
}
  