import React from "react"
import { signOut } from "firebase/auth";
import { auth, addHorario, updateHorario, getDiasFestivosPorAñoyMes } from "../../firebase/firebase"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import settings from "../../img/settings.png"
import logoblanco from "../../img/logo-blanco.svg"
import { ModalAjustes } from "./modalAjustes/ModalAjustes"
import { ModalDiasFestivos } from "./modalDiasFestivos/ModalDiasFestivos"
import { useModalAjustes } from "./modalAjustes/UseModalAjustes"
import { useModalDiasFestivos } from "./modalDiasFestivos/UseModalDiasFestivos"
import { RegistroDiasFestivos } from "./RegistroDiasFestivos"
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO, colorProject } from "./../../js/project"
import { calcHorasSemanales, formatNumber, getFormatActualMonth, getFormatActualYear } from "../../js/functions";
import { BLANK, MONTHS, YEARS } from "../../js/consts";

export default function Header({ trabajador, horariosPrestablecidos }) {
  const navigate = useNavigate();
  const [isOpenModal, openModal, closeModal] = useModalAjustes(false)
  const [isOpenModalDiasFestivos, openModalDiasFestivos, closeModalDiasFestivos] = useModalDiasFestivos(false)
  // const [nombre, setNombre] = useState(trabajador.name)
  // const [apellidos, setApellidos] = useState(trabajador.apellidos)
  // const [dni, setDNI] = useState(trabajador.dni)
  // const [mensajeCambioNombreyApellidos, setMensajeCambioNombreyApellidos] = useState("")
  // const [cambioConfirmado, setCambioConfirmado] = useState(false)
  const [nombreHorario, setNombreHorario] = useState("")
  const [mensajeNombreHorario, setMensajeNombreHorario] = useState("Establece nombre para nuevo horario")
  const [horarioPre, setHorarioPre] = useState("")
  const horarioVacio = [
    {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //DOMINGO     0
    {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //LUNES       1
    {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //MARTES      2
    {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //MIERCOLES   3
    {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //JUEVES      4
    {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //VIERNES     5
    {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //SABADO      6
  ]
  const [horarioIndividual, setHorarioIndividual] = useState(horarioVacio)

  const stateDiasHorario = [
    {mañana:true, tarde:false, dobleDia:false}, //DOMINGO     0
    {mañana:true, tarde:false, dobleDia:false}, //LUNES       1
    {mañana:true, tarde:false, dobleDia:false}, //MARTES      2
    {mañana:true, tarde:false, dobleDia:false}, //MIERCOLES   3
    {mañana:true, tarde:false, dobleDia:false}, //JUEVES      4
    {mañana:true, tarde:false, dobleDia:false}, //VIERNES     5
    {mañana:true, tarde:false, dobleDia:false}, //SABADO      6
  ]
  const [booleansHorarioIndividual, setBooleansHorarioIndividual] = useState(stateDiasHorario)
  const [tardeLunes, setTardeLunes] = useState(false)
  const [mañanaLunes, setMañanaLunes] = useState(true)
  const [dobleDiaLunes, setDobleDiaLunes] = useState(false)
  const [tardeMartes, setTardeMartes] = useState(false)
  const [mañanaMartes, setMañanaMartes] = useState(true)
  const [dobleDiaMartes, setDobleDiaMartes] = useState(false)
  const [tardeMiercoles, setTardeMiercoles] = useState(false)
  const [mañanaMiercoles, setMañanaMiercoles] = useState(true)
  const [dobleDiaMiercoles, setDobleDiaMiercoles] = useState(false)
  const [tardeJueves, setTardeJueves] = useState(false)
  const [mañanaJueves, setMañanaJueves] = useState(true)
  const [dobleDiaJueves, setDobleDiaJueves] = useState(false)
  const [tardeViernes, setTardeViernes] = useState(false)
  const [mañanaViernes, setMañanaViernes] = useState(true)
  const [dobleDiaViernes, setDobleDiaViernes] = useState(false)
  const [tardeSabado, setTardeSabado] = useState(false)
  const [mañanaSabado, setMañanaSabado] = useState(false)
  const [dobleDiaSabado, setDobleDiaSabado] = useState(false)
  const [tardeDomingo, setTardeDomingo] = useState(false)
  const [mañanaDomingo, setMañanaDomingo] = useState(false)
  const [dobleDiaDomingo, setDobleDiaDomingo] = useState(false)
  const [mensajeErrorHorarioIndividual, setMensajeErrorHorarioIndividual] = useState("")
  const [totalHorasSemanales, setTotalHorasSemanales] = useState("00:00")
  const [stateNuevoHorario, setStateNuevoHorario] = useState(true)
  const [horarioSeleccionado, setHorarioSeleccionado] = useState(false)

  const [bRegistros, setBRegistros] = useState(false);
  const [diasMes, setDiasMes] = useState([]);
  const [añoSelect, setAñoSelect] = useState(getFormatActualYear())
  const [mesSelect, setMesSelect] = useState(getFormatActualMonth())

  const [display, setDisplay] = useState("none")

  async function onClickAceptar() {
    comprobarCheckboxes()
    for (let i = 0; i < horarioIndividual.length; i++) {
      const arrInicio = horarioIndividual[i].inicio.split(":")
      const arrFin = horarioIndividual[i].fin.split(":")
      if (parseInt(arrInicio[0]) < 24 && parseInt(arrInicio[0]) >= 0) {
      } else {
        if (i === 0 && mañanaDomingo === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Domingo (turno mañana)"); return "" }
        if (i === 1 && mañanaLunes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Lunes (turno mañana)"); return "" }
        if (i === 2 && mañanaMartes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Martes (turno mañana)"); return "" }
        if (i === 3 && mañanaMiercoles === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Miercoles (turno mañana)"); return "" }
        if (i === 4 && mañanaJueves === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Jueves (turno mañana)"); return "" }
        if (i === 5 && mañanaViernes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Viernes (turno mañana)"); return "" }
        if (i === 6 && mañanaSabado === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Sabado (turno mañana)"); return "" }
      }

      if (parseInt(arrInicio[1]) < 60 && parseInt(arrInicio[1]) >= 0) {
      } else {
        if (i === 0 && mañanaDomingo === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Domingo (turno mañana)"); return "" }
        if (i === 1 && mañanaLunes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Lunes (turno mañana)"); return "" }
        if (i === 2 && mañanaMartes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Martes (turno mañana)"); return "" }
        if (i === 3 && mañanaMiercoles === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Miercoles (turno mañana)"); return "" }
        if (i === 4 && mañanaJueves === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Jueves (turno mañana)"); return "" }
        if (i === 5 && mañanaViernes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Viernes (turno mañana)"); return "" }
        if (i === 6 && mañanaSabado === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Sabado (turno mañana)"); return "" }
      } 

      if (parseInt(arrFin[0]) < 24 && parseInt(arrFin[0]) >= 0) {
      } else {
        if (i === 0 && mañanaDomingo === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Domingo (turno mañana)"); return "" }
        if (i === 1 && mañanaLunes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Lunes (turno mañana)"); return "" }
        if (i === 2 && mañanaMartes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Martes (turno mañana)"); return "" }
        if (i === 3 && mañanaMiercoles === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Miercoles (turno mañana)"); return "" }
        if (i === 4 && mañanaJueves === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Jueves (turno mañana)"); return "" }
        if (i === 5 && mañanaViernes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Viernes (turno mañana)"); return "" }
        if (i === 6 && mañanaSabado === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Sabado (turno mañana)"); return "" }
      } 

      if (parseInt(arrFin[1]) < 60 && parseInt(arrInicio[1]) >= 0) {
      } else {
        if (i === 0 && mañanaDomingo === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Domingo (turno mañana)"); return "" }
        if (i === 1 && mañanaLunes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Lunes (turno mañana)"); return "" }
        if (i === 2 && mañanaMartes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Martes (turno mañana)"); return "" }
        if (i === 3 && mañanaMiercoles === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Miercoles (turno mañana)"); return "" }
        if (i === 4 && mañanaJueves === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Jueves (turno mañana)"); return "" }
        if (i === 5 && mañanaViernes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Viernes (turno mañana)"); return "" }
        if (i === 6 && mañanaSabado === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Sabado (turno mañana)"); return "" }
      }
    }
    for (let i = 0; i < horarioIndividual.length; i++) {
      const arrInicio = horarioIndividual[i].inicioTarde.split(":")
      const arrFin = horarioIndividual[i].finTarde.split(":")
      if (parseInt(arrInicio[0]) < 24 && parseInt(arrInicio[0]) >= 0) {
      } else {
        if (i === 0 && tardeDomingo === true && !dobleDiaDomingo) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Domingo (turno tarde)"); return "" }
        if (i === 1 && tardeLunes === true && !dobleDiaLunes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Lunes (turno tarde)"); return "" }
        if (i === 2 && tardeMartes === true && !dobleDiaMartes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Martes (turno tarde)"); return "" }
        if (i === 3 && tardeMiercoles === true && !dobleDiaMiercoles) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Miercoles (turno tarde)"); return "" }
        if (i === 4 && tardeJueves === true && !dobleDiaJueves) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Jueves (turno tarde)"); return "" }
        if (i === 5 && tardeViernes === true && !dobleDiaViernes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Viernes (turno tarde)"); return "" }
        if (i === 6 && tardeSabado === true && !dobleDiaSabado) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Sabado (turno tarde)"); return "" }
      }

      if (parseInt(arrInicio[1]) < 60 && parseInt(arrInicio[1]) >= 0) {
      } else {
        if (i === 0 && tardeDomingo === true && !dobleDiaDomingo) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Domingo (turno tarde)"); return "" }
        if (i === 1 && tardeLunes === true && !dobleDiaLunes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Lunes (turno tarde)"); return "" }
        if (i === 2 && tardeMartes === true && !dobleDiaMartes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Martes (turno tarde)"); return "" }
        if (i === 3 && tardeMiercoles === true && !dobleDiaMiercoles) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Miercoles (turno tarde)"); return "" }
        if (i === 4 && tardeJueves === true && !dobleDiaJueves) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Jueves (turno tarde)"); return "" }
        if (i === 5 && tardeViernes === true && !dobleDiaViernes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Viernes (turno tarde)"); return "" }
        if (i === 6 && tardeSabado === true && !dobleDiaSabado) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Sabado (turno tarde)"); return "" }
      } 

      if (parseInt(arrFin[0]) < 24 && parseInt(arrFin[0]) >= 0) {
      } else {
        if (i === 0 && tardeDomingo === true && !dobleDiaDomingo) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Domingo (turno tarde)"); return "" }
        if (i === 1 && tardeLunes === true && !dobleDiaLunes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Lunes (turno tarde)"); return "" }
        if (i === 2 && tardeMartes === true && !dobleDiaMartes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Martes (turno tarde)"); return "" }
        if (i === 3 && tardeMiercoles === true && !dobleDiaMiercoles) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Miercoles (turno tarde)"); return "" }
        if (i === 4 && tardeJueves === true && !dobleDiaJueves) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Jueves (turno tarde)"); return "" }
        if (i === 5 && tardeViernes === true && !dobleDiaViernes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Viernes (turno tarde)"); return "" }
        if (i === 6 && tardeSabado === true && !dobleDiaSabado) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Sabado (turno tarde)"); return "" }
      } 

      if (parseInt(arrFin[1]) < 60 && parseInt(arrInicio[1]) >= 0) {
      } else {
        if (i === 0 && tardeDomingo === true && !dobleDiaDomingo) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Domingo (turno tarde)"); return "" }
        if (i === 1 && tardeLunes === true && !dobleDiaLunes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Lunes (turno tarde)"); return "" }
        if (i === 2 && tardeMartes === true && !dobleDiaMartes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Martes (turno tarde)"); return "" }
        if (i === 3 && tardeMiercoles === true && !dobleDiaMiercoles) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Miercoles (turno tarde)"); return "" }
        if (i === 4 && tardeJueves === true && !dobleDiaJueves) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Jueves (turno tarde)"); return "" }
        if (i === 5 && tardeViernes === true && !dobleDiaViernes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Viernes (turno tarde)"); return "" }
        if (i === 6 && tardeSabado === true && !dobleDiaSabado) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Sabado (turno tarde)"); return "" }
      }
    }
    setMensajeErrorHorarioIndividual("")

    if (stateNuevoHorario === true) {
      const id = `${parseInt(horariosPrestablecidos[horariosPrestablecidos.length-1].id) + 1}`
            
      // if (id >= 1 && id <= 9) id = `00${id}`
      // else if (id >= 10 && id <= 99) id = `0${id}`

      const horario = {
        name: nombreHorario,
        dias: horarioIndividual,
        id: id
      }
      await addHorario(horario)
      window.location.reload()

    }
    else if (stateNuevoHorario === false && horarioSeleccionado === true) {
      const id = `${horariosPrestablecidos[horarioPre].id}`
      const horario = {
        name: nombreHorario,
        dias: horarioIndividual,
        id: id
      }
      await updateHorario(horario)
      window.location.reload()

    } else setMensajeErrorHorarioIndividual("No has seleccionado horario para editar")
  }

  function comprobarCheckboxes() {
    if (mañanaLunes === false) { horarioIndividual[1].inicio = ""; horarioIndividual[1].fin = ""; horarioIndividual[1].inicioTarde = ""; horarioIndividual[1].finTarde = "" }
    if (mañanaMartes === false) { horarioIndividual[2].inicio = ""; horarioIndividual[2].fin = ""; horarioIndividual[2].inicioTarde = ""; horarioIndividual[2].finTarde = "" }
    if (mañanaMiercoles === false) { horarioIndividual[3].inicio = ""; horarioIndividual[3].fin = ""; horarioIndividual[3].inicioTarde = ""; horarioIndividual[3].finTarde = "" }
    if (mañanaJueves === false) { horarioIndividual[4].inicio = ""; horarioIndividual[4].fin = ""; horarioIndividual[4].inicioTarde = ""; horarioIndividual[4].finTarde = "" }
    if (mañanaViernes === false) { horarioIndividual[5].inicio = ""; horarioIndividual[5].fin = ""; horarioIndividual[5].inicioTarde = ""; horarioIndividual[5].finTarde = "" }
    if (mañanaSabado === false) { horarioIndividual[6].inicio = ""; horarioIndividual[6].fin = ""; horarioIndividual[6].inicioTarde = ""; horarioIndividual[6].finTarde = "" }
    if (mañanaDomingo === false) { horarioIndividual[0].inicio = ""; horarioIndividual[0].fin = ""; horarioIndividual[0].inicioTarde = ""; horarioIndividual[0].finTarde = "" }

    if (tardeLunes === false || dobleDiaLunes === true) { horarioIndividual[1].inicioTarde = ""; horarioIndividual[1].finTarde = "" }
    if (tardeMartes === false || dobleDiaMartes === true) { horarioIndividual[2].inicioTarde = ""; horarioIndividual[2].finTarde = "" }
    if (tardeMiercoles === false || dobleDiaMiercoles === true) { horarioIndividual[3].inicioTarde = ""; horarioIndividual[3].finTarde = "" }
    if (tardeJueves === false || dobleDiaJueves === true) { horarioIndividual[4].inicioTarde = ""; horarioIndividual[4].finTarde = "" }
    if (tardeViernes === false || dobleDiaViernes === true) { horarioIndividual[5].inicioTarde = ""; horarioIndividual[5].finTarde = "" }
    if (tardeSabado === false || dobleDiaSabado === true) { horarioIndividual[6].inicioTarde = ""; horarioIndividual[6].finTarde = "" }
    if (tardeDomingo === false || dobleDiaDomingo === true) { horarioIndividual[0].inicioTarde = ""; horarioIndividual[0].finTarde = "" }
  }

  function onChangeMañanaLunes(e) {
    if (e.target.value === "true") { setMañanaLunes(false); setTardeLunes(false); setDobleDiaLunes(false); horarioIndividual[1].dobleDia = false }
    if (e.target.value === "false") { setMañanaLunes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaMartes(e) {
    if (e.target.value === "true") { setMañanaMartes(false); setTardeMartes(false); setDobleDiaMartes(false); horarioIndividual[2].dobleDia = false }
    if (e.target.value === "false") { setMañanaMartes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaMiercoles(e) {
    if (e.target.value === "true") { setMañanaMiercoles(false); setTardeMiercoles(false); setDobleDiaMiercoles(false); horarioIndividual[3].dobleDia = false }
    if (e.target.value === "false") { setMañanaMiercoles(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaJueves(e) {
    if (e.target.value === "true") { setMañanaJueves(false); setTardeJueves(false); setDobleDiaJueves(false); horarioIndividual[4].dobleDia = false }
    if (e.target.value === "false") { setMañanaJueves(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaViernes(e) {
    if (e.target.value === "true") {  setMañanaViernes(false); setTardeViernes(false); setDobleDiaViernes(false); horarioIndividual[5].dobleDia = false }
    if (e.target.value === "false") { setMañanaViernes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaSabado(e) {
    if (e.target.value === "true") { setMañanaSabado(false); setTardeSabado(false); setDobleDiaSabado(false); horarioIndividual[6].dobleDia = false }
    if (e.target.value === "false") { setMañanaSabado(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaDomingo(e) {
    if (e.target.value === "true") { setMañanaDomingo(false); setTardeDomingo(false); setDobleDiaDomingo(false); horarioIndividual[0].dobleDia = false }
    if (e.target.value === "false") { setMañanaDomingo(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeLunes(e) {
    if (e.target.value === "true") { setTardeLunes(false) }
    if (e.target.value === "false") { setTardeLunes(true); setMañanaLunes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeMartes(e) {
    if (e.target.value === "true") { setTardeMartes(false) }
    if (e.target.value === "false") { setTardeMartes(true); setMañanaMartes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeMiercoles(e) {
    if (e.target.value === "true") { setTardeMiercoles(false) }
    if (e.target.value === "false") { setTardeMiercoles(true); setMañanaMiercoles(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeJueves(e) {
    if (e.target.value === "true") { setTardeJueves(false) }
    if (e.target.value === "false") { setTardeJueves(true); setMañanaJueves(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeViernes(e) {
    if (e.target.value === "true") { setTardeViernes(false) }
    if (e.target.value === "false") { setTardeViernes(true); setMañanaViernes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeSabado(e) {
    if (e.target.value === "true") { setTardeSabado(false) }
    if (e.target.value === "false") { setTardeSabado(true); setMañanaSabado(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeDomingo(e) {
    if (e.target.value === "true") { setTardeDomingo(false) }
    if (e.target.value === "false") { setTardeDomingo(true); setMañanaDomingo(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeDobleDiaLunes(e) {
    if (e.target.value === "true") {
      setDobleDiaLunes(false)
      horarioIndividual[1].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaLunes(true); 
      horarioIndividual[1].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeDobleDiaMartes(e) {
    if (e.target.value === "true") {
      setDobleDiaMartes(false); 
      horarioIndividual[2].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaMartes(true); 
      horarioIndividual[2].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeDobleDiaMiercoles(e) {
    if (e.target.value === "true") {
      setDobleDiaMiercoles(false); 
      horarioIndividual[3].dobleDia = false  
    } 
    else if (e.target.value === "false") {
      setDobleDiaMiercoles(true); 
      horarioIndividual[3].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeDobleDiaJueves(e) {
    if (e.target.value === "true") {
      setDobleDiaJueves(false); 
      horarioIndividual[4].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaJueves(true); 
      horarioIndividual[4].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeDobleDiaViernes(e) {
    if (e.target.value === "true") {
      setDobleDiaViernes(false); 
      horarioIndividual[5].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaViernes(true); 
      horarioIndividual[5].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeDobleDiaSabado(e) {
    if (e.target.value === "true") {
      setDobleDiaSabado(false); 
      horarioIndividual[6].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaSabado(true); 
      horarioIndividual[6].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeDobleDiaDomingo(e) {
    if (e.target.value === "true") {
      setDobleDiaDomingo(false); 
      horarioIndividual[0].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaDomingo(true); 
      horarioIndividual[0].dobleDia = true
    }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function handleStateNuevoHorario(e) {
    if (e.target.value === "false") {
      setStateNuevoHorario(true)
      setMensajeNombreHorario("Establece nombre para nuevo horario")
      calcHorasSemanales("")
      setNombreHorario("")
      setHorarioIndividual(horarioVacio)
      setBooleansDefault()
    }
  }

  function handleStateNuevoHorario2(e) {
    if (e.target.value === "false") {
      setStateNuevoHorario(false)
      setMensajeNombreHorario("Edita el nombre de este horario")
      if(horarioPre !== "") {
          setNombreHorario(horariosPrestablecidos[horarioPre].name)
          setHorarioIndividual(horarioVacio)
          setHorarioIndividual(horariosPrestablecidos[horarioPre].dias)
          setBooleans(horariosPrestablecidos[horarioPre].dias)
          setTotalHorasSemanales(calcHorasSemanales(horariosPrestablecidos[horarioPre].dias))
          //colocarHorarios(horariosPrestablecidos[horarioPre])
      }
    }
  }

  function onChangeSelect(e) {
    if (e.target.value !== BLANK) { 
      setHorarioPre(e.target.value); 
      setNombreHorario(horariosPrestablecidos[e.target.value].name);
      setMensajeNombreHorario("Edita el nombre de este horario")
      setHorarioIndividual(horariosPrestablecidos[e.target.value].dias) 
      setBooleans(horariosPrestablecidos[e.target.value].dias)
      setTotalHorasSemanales(calcHorasSemanales(horariosPrestablecidos[e.target.value].dias))
      setHorarioSeleccionado(true) 
    }
    else {
      setHorarioPre("")
      setNombreHorario("")
      setMensajeNombreHorario("Selecciona horario para editar")
      setBooleansDefault()
      setHorarioIndividual(horarioVacio) 
      calcHorasSemanales("")
      setHorarioSeleccionado(false)
    }
  }

  function setBooleans(horarioTrabajador) {
    for (let i = 0; i < horarioTrabajador.length; i++) {
      if(horarioTrabajador[i].inicio !== "") {
        booleansHorarioIndividual[i].mañana = true
      } 
      else {
        booleansHorarioIndividual[i].mañana = false
      } 

      if(horarioTrabajador[i].inicioTarde !== "") {
        booleansHorarioIndividual[i].tarde = true
      } 
      else {
        booleansHorarioIndividual[i].tarde = false
      } 

      if(horarioTrabajador[i].dobleDia === true) {
        booleansHorarioIndividual[i].dobleDia = true
      } 
      else {
        booleansHorarioIndividual[i].dobleDia = false
      } 
    }
    setBooleansCheckboxes()
  }

  function setBooleansCheckboxes() {
    if (booleansHorarioIndividual[0].mañana === true) setMañanaDomingo(true); else setMañanaDomingo(false)
    if (booleansHorarioIndividual[0].tarde === true) setTardeDomingo(true); else setTardeDomingo(false)
    if (booleansHorarioIndividual[0].dobleDia === true) setDobleDiaDomingo(true); else setDobleDiaDomingo(false)

    if (booleansHorarioIndividual[1].mañana === true) setMañanaLunes(true); else setMañanaLunes(false)
    if (booleansHorarioIndividual[1].tarde === true) setTardeLunes(true); else setTardeLunes(false)
    if (booleansHorarioIndividual[1].dobleDia === true) setDobleDiaLunes(true); else setDobleDiaLunes(false)

    if (booleansHorarioIndividual[2].mañana === true) setMañanaMartes(true); else setMañanaMartes(false)
    if (booleansHorarioIndividual[2].tarde === true) setTardeMartes(true); else setTardeMartes(false)
    if (booleansHorarioIndividual[2].dobleDia === true) setDobleDiaMartes(true); else setDobleDiaMartes(false)

    if (booleansHorarioIndividual[3].mañana === true) setMañanaMiercoles(true); else setMañanaMiercoles(false)
    if (booleansHorarioIndividual[3].tarde === true) setTardeMiercoles(true); else setTardeMiercoles(false)
    if (booleansHorarioIndividual[3].dobleDia === true) setDobleDiaMiercoles(true); else setDobleDiaMiercoles(false)

    if (booleansHorarioIndividual[4].mañana === true) setMañanaJueves(true); else setMañanaJueves(false)
    if (booleansHorarioIndividual[4].tarde === true) setTardeJueves(true); else setTardeJueves(false)
    if (booleansHorarioIndividual[4].dobleDia === true) setDobleDiaJueves(true); else setDobleDiaJueves(false)

    if (booleansHorarioIndividual[5].mañana === true) setMañanaViernes(true); else setMañanaViernes(false)
    if (booleansHorarioIndividual[5].tarde === true) setTardeViernes(true); else setTardeViernes(false)
    if (booleansHorarioIndividual[5].dobleDia === true) setDobleDiaViernes(true); else setDobleDiaViernes(false)

    if (booleansHorarioIndividual[6].mañana === true) setMañanaSabado(true); else setMañanaSabado(false)
    if (booleansHorarioIndividual[6].tarde === true) setTardeSabado(true); else setTardeSabado(false)
    if (booleansHorarioIndividual[6].dobleDia === true) setDobleDiaSabado(true); else setDobleDiaSabado(false)
  }

  function setBooleansDefault() {
    setMañanaLunes(true)
    setTardeLunes(false)
    setDobleDiaLunes(false)

    setMañanaMartes(true)
    setTardeMartes(false)
    setDobleDiaMartes(false)

    setMañanaMiercoles(true)
    setTardeMiercoles(false)
    setDobleDiaMiercoles(false)

    setMañanaJueves(true)
    setTardeJueves(false)
    setDobleDiaJueves(false)

    setMañanaViernes(true)
    setTardeViernes(false)
    setDobleDiaViernes(false)

    setMañanaSabado(false)
    setTardeSabado(false)
    setDobleDiaSabado(false)

    setMañanaDomingo(false)
    setTardeDomingo(false)
    setDobleDiaDomingo(false)
  }

  function inputDisabled() {
    if (horarioSeleccionado === false && stateNuevoHorario === false) {
        return true
    } else return false
  }

//   async function onClickNombreApellidos() {
//       trabajador.nombre = nombre
//       trabajador.apellidos = apellidos
//       trabajador.dni = dni
//       setCambioConfirmado(true)
//       await updateTrabajador(trabajador)
//       setMensajeCambioNombreyApellidos("Cambio confirmado")
//   }

  function logout() {
    signOut(auth)
      .then(() => {
        // console.log("OUT");
        navigate("/login");
      })
      .catch((error) => {
        // console.error(error);
        // console.log("error logout");
      });
  }

  async function establecerDiasMes(año, mes) {
    const diasMesAux = new Date(año, mes, 0).getDate();
    const diasMesFestivos = await getDiasFestivosPorAñoyMes(`${año}-${mes}`)

    for (let dia = 1; dia <= diasMesAux; dia++) {
      const fechaDia = `${año}-${mes}-${formatNumber(dia)}`
      diasMes.push({fechaDia: fechaDia, estado: "LABORAL"})

      for (let i = 0; i < diasMesFestivos.length; i++) {
        if (fechaDia === diasMesFestivos[i].dia) {
          diasMes[dia-1] = {fechaDia: fechaDia, estado: "FESTIVO"}
        }
      }
      if (new Date(fechaDia).getDay() === 0) {
        diasMes[dia-1] = {fechaDia: fechaDia, estado: "DOMINGO"}
      }
    }
    setBRegistros(true)
  }

  function onClickDiasFestivos () {
    establecerDiasMes(añoSelect, mesSelect)
  }

  function onChangeAño (e) {
    setAñoSelect(e.target.value)
    setRegistrosNull()
  }

  function onChangeMes (e) {
    setMesSelect(e.target.value)
    setRegistrosNull()
  }

  function setRegistrosNull() {
    setBRegistros(false)
    setDiasMes([])
  }

  function controlHorario() {
    navigate("/controlHorario")
  }

  window.document.body.addEventListener("click", () => {
    if (display === "block") setDisplay("none")
  }) 

  function onChangeDisplay() {
    // Aplico un timeout para que se ejecute antes el window.document.body.addEventListener y luego este if
    setTimeout(() => {
      if (display === "none") setDisplay("block")
      else setDisplay("none")
    }, 10)
  }

  return (
    <>
      <nav style={{backgroundColor: colorProject}} className="navheader">
      { PROJECT_APP === ECINNOVA && 
          <>
            <a href="/#/update">
              <img src={logoblanco}
                width="50"
                height="50"
                alt="logo_empresa"
              />
            </a>
            <a href="/#/update">
              <h1 className="colorwhite title-page">EC-INNOVA</h1>
            </a>
          </>
        }
        { PROJECT_APP === INSERTATOLEDO && 
          <a href="/#/update">
            <h1 className="colorwhite pm-0">INSERTA TOLEDO</h1>
          </a>
        }
        { PROJECT_APP === CARITASTOLEDO && 
          <a href="/#/update">
            <h1 className="colorwhite pm-0">CARITAS TOLEDO</h1>
          </a>
        }

        <div className="dropdowncss mleft-auto" onClick={onChangeDisplay}>
          { PROJECT_APP === ECINNOVA && 
            <>
              <img src={settings}
                width="20"
                height="20"
                className="margintop-2"
                alt="logo_empresa"
              />
              <span className="vdropdown">v</span>
            </>
          }
          { PROJECT_APP === INSERTATOLEDO && 
            <span className="marginleft-8">v</span>
          }
          { PROJECT_APP === CARITASTOLEDO && 
            <span className="marginleft-8">v</span>
          }
          <div style={{display: display}} className="dropdownmenucss">
            <button onClick={openModal}>Ajustes</button>
            <button onClick={controlHorario}>Control horario</button>
            <button onClick={openModalDiasFestivos}>Dias festivos</button>
            <button onClick={logout}>Cerrar sesión</button>
          </div>
        </div>

        <ModalDiasFestivos isOpen={isOpenModalDiasFestivos} closeModal={closeModalDiasFestivos}>
          <div className="titulo2">Establecer dias festivos</div>
          <hr /> 
          <div className="marginbottom-8">
            Año:&nbsp;
            <select onChange={onChangeAño} defaultValue={añoSelect}>
              {YEARS.map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
            &nbsp;

            Mes:&nbsp;
            <select onChange={onChangeMes} defaultValue={mesSelect}>
              {MONTHS.map((month) => (
                <option key={month.number} value={month.number}>{month.name}</option>
              ))}
            </select>
            &nbsp;&nbsp;
            <button className="acceptButton" onClick={onClickDiasFestivos}>Ver Dias</button>
          </div>
          <RegistroDiasFestivos diasMes={diasMes} bRegistros={bRegistros}/>
        </ModalDiasFestivos>
        <ModalAjustes isOpen={isOpenModal} closeModal={closeModal}>
          <article id="settings">
            <div className="titulo2">Ajustes</div>
            <hr />
            {/* <h3>Mis datos</h3>
            <table>
              <tbody>
                <tr>
                  <td><label>Correo electronico</label></td>
                  <td><input type="text" disabled={true} value={trabajador.email} /></td>
                </tr>
                <tr>
                  <td><label>Nombre</label></td>
                  <td><input type="text" value={nombre} onChange={(e) => {setNombre(e.target.value)}} /></td>
                </tr>
                <tr>
                  <td><label>Apellidos</label></td>
                  <td><input type="text" value={apellidos} onChange={(e) => {setApellidos(e.target.value)}} /></td>
                </tr>
                <tr>
                  <td><label>DNI</label></td>
                  <td><input type="text" value={dni} onChange={(e) => {setDNI(e.target.value)}} /></td>
                </tr>
              </tbody>
            </table>
            <div>
              <Button disabled={cambioConfirmado} onClick={onClickNombreApellidos}>Confirmar cambio de nombre y/o apellidos</Button>
              <span>&nbsp;{mensajeCambioNombreyApellidos}</span>
            </div>
            <hr /> */}
            <div className="titulo3">Horarios Prestablecidos</div>
            <div>
              <label>
                <input type="checkbox" checked={stateNuevoHorario} value={stateNuevoHorario} onChange={handleStateNuevoHorario}/>
                <span className="text-bold">&nbsp;Añadir nuevo horario</span>
              </label>
            </div>
            <br />
            <div>
              <label>
                <input type="checkbox" checked={!stateNuevoHorario} value={!stateNuevoHorario} onChange={handleStateNuevoHorario2}/>
                <span className="text-bold">&nbsp;Editar horario &nbsp;</span>
              </label>
              <select onChange={onChangeSelect} disabled={stateNuevoHorario}>
                <option key={BLANK} value={BLANK} title={BLANK}></option>
              {horariosPrestablecidos.map((horario, index) => (
                <option key={index} value={index} title={horario.name}>{horario.name}</option>
              ))}
              </select>
            </div>
            <div>
              <span>{mensajeNombreHorario}</span>
              <span>&nbsp;&nbsp;<input type="text" disabled={inputDisabled()} value={nombreHorario} onChange={(e) => {setNombreHorario(e.target.value)}} /></span>
            </div>
            <br />
            <div>Turno mañana/jornada continua</div>
            <table className="scheduleTable">
              <tbody>
                <tr>
                  <td><input type="checkbox" checked={mañanaLunes} value={mañanaLunes} onChange={onChangeMañanaLunes}/></td>
                  <td>Lunes</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaLunes} defaultValue={horarioIndividual[1].inicio} onChange={(e) => {horarioIndividual[1].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaLunes} defaultValue={horarioIndividual[1].fin} onChange={(e) => {horarioIndividual[1].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                  <td><input type="checkbox" disabled={!mañanaLunes} checked={dobleDiaLunes} value={dobleDiaLunes} onChange={onChangeDobleDiaLunes}/></td>
                </tr>
                <tr>
                  <td><input type="checkbox" checked={mañanaMartes} value={mañanaMartes} onChange={onChangeMañanaMartes}/></td>
                  <td>Martes</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaMartes} defaultValue={horarioIndividual[2].inicio} onChange={(e) => {horarioIndividual[2].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaMartes} defaultValue={horarioIndividual[2].fin} onChange={(e) => {horarioIndividual[2].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                  <td><input type="checkbox" disabled={!mañanaMartes} checked={dobleDiaMartes} value={dobleDiaMartes} onChange={onChangeDobleDiaMartes}/></td>
                </tr>
                <tr>
                  <td><input type="checkbox" checked={mañanaMiercoles} value={mañanaMiercoles} onChange={onChangeMañanaMiercoles}/></td>
                  <td>Miercoles</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaMiercoles} defaultValue={horarioIndividual[3].inicio} onChange={(e) => {horarioIndividual[3].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaMiercoles} defaultValue={horarioIndividual[3].fin} onChange={(e) => {horarioIndividual[3].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                  <td><input type="checkbox" disabled={!mañanaMiercoles} checked={dobleDiaMiercoles} value={dobleDiaMiercoles} onChange={onChangeDobleDiaMiercoles}/></td>
                </tr>
                <tr>
                  <td><input type="checkbox" checked={mañanaJueves} value={mañanaJueves} onChange={onChangeMañanaJueves}/></td>
                  <td>Jueves</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaJueves} defaultValue={horarioIndividual[4].inicio} onChange={(e) => {horarioIndividual[4].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaJueves} defaultValue={horarioIndividual[4].fin} onChange={(e) => {horarioIndividual[4].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                  <td><input type="checkbox" disabled={!mañanaJueves} checked={dobleDiaJueves} value={dobleDiaJueves} onChange={onChangeDobleDiaJueves}/></td>
                </tr>
                <tr>
                  <td><input type="checkbox" checked={mañanaViernes} value={mañanaViernes} onChange={onChangeMañanaViernes}/></td>
                  <td>Viernes</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaViernes} defaultValue={horarioIndividual[5].inicio} onChange={(e) => {horarioIndividual[5].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaViernes} defaultValue={horarioIndividual[5].fin} onChange={(e) => {horarioIndividual[5].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                  <td><input type="checkbox" disabled={!mañanaViernes} checked={dobleDiaViernes} value={dobleDiaViernes} onChange={onChangeDobleDiaViernes}/></td>
                </tr>
                <tr>
                  <td><input type="checkbox" checked={mañanaSabado} value={mañanaSabado} onChange={onChangeMañanaSabado}/></td>
                  <td>Sabado</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaSabado} defaultValue={horarioIndividual[6].inicio} onChange={(e) => {horarioIndividual[6].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaSabado} defaultValue={horarioIndividual[6].fin} onChange={(e) => {horarioIndividual[6].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                  <td><input type="checkbox" disabled={!mañanaSabado} checked={dobleDiaSabado} value={dobleDiaSabado} onChange={onChangeDobleDiaSabado}/></td>
                </tr>
                <tr>
                  <td><input type="checkbox" checked={mañanaDomingo} value={mañanaDomingo} onChange={onChangeMañanaDomingo}/></td>
                  <td>Domingo</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaDomingo} defaultValue={horarioIndividual[0].inicio} onChange={(e) => {horarioIndividual[0].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!mañanaDomingo} defaultValue={horarioIndividual[0].fin} onChange={(e) => {horarioIndividual[0].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                  <td><input type="checkbox" disabled={!mañanaDomingo} checked={dobleDiaDomingo} value={dobleDiaDomingo} onChange={onChangeDobleDiaDomingo}/></td>
                </tr>
              </tbody>
            </table>

            <div>Turno tarde</div>
            <table className="scheduleTable">
              <tbody>
                <tr>
                  <td><input type="checkbox" disabled={dobleDiaLunes} checked={tardeLunes} value={tardeLunes} onChange={onChangeTardeLunes}/></td>
                  <td>Lunes</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeLunes || dobleDiaLunes} defaultValue={horarioIndividual[1].inicioTarde} onChange={(e) => {horarioIndividual[1].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeLunes || dobleDiaLunes} defaultValue={horarioIndividual[1].finTarde} onChange={(e) => {horarioIndividual[1].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                </tr>
                <tr>
                  <td><input type="checkbox" disabled={dobleDiaMartes} checked={tardeMartes} value={tardeMartes} onChange={onChangeTardeMartes}/></td>
                  <td>Martes</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeMartes || dobleDiaMartes} defaultValue={horarioIndividual[2].inicioTarde} onChange={(e) => {horarioIndividual[2].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeMartes || dobleDiaMartes} defaultValue={horarioIndividual[2].finTarde} onChange={(e) => {horarioIndividual[2].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                </tr>
                <tr>
                  <td><input type="checkbox" disabled={dobleDiaMiercoles} checked={tardeMiercoles} value={tardeMiercoles} onChange={onChangeTardeMiercoles}/></td>
                  <td>Miercoles</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeMiercoles || dobleDiaMiercoles} defaultValue={horarioIndividual[3].inicioTarde} onChange={(e) => {horarioIndividual[3].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeMiercoles || dobleDiaMiercoles} defaultValue={horarioIndividual[3].finTarde} onChange={(e) => {horarioIndividual[3].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                </tr>
                <tr>
                  <td><input type="checkbox" disabled={dobleDiaJueves} checked={tardeJueves} value={tardeJueves} onChange={onChangeTardeJueves}/></td>
                  <td>Jueves</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeJueves || dobleDiaJueves} defaultValue={horarioIndividual[4].inicioTarde} onChange={(e) => {horarioIndividual[4].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeJueves || dobleDiaJueves} defaultValue={horarioIndividual[4].finTarde} onChange={(e) => {horarioIndividual[4].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                </tr>
                <tr>
                  <td><input type="checkbox" disabled={dobleDiaViernes} checked={tardeViernes} value={tardeViernes} onChange={onChangeTardeViernes}/></td>
                  <td>Viernes</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeViernes || dobleDiaViernes} defaultValue={horarioIndividual[5].inicioTarde} onChange={(e) => {horarioIndividual[5].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeViernes || dobleDiaViernes} defaultValue={horarioIndividual[5].finTarde} onChange={(e) => {horarioIndividual[5].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                </tr>
                <tr>
                  <td><input type="checkbox" disabled={dobleDiaSabado} checked={tardeSabado} value={tardeSabado} onChange={onChangeTardeSabado}/></td>
                  <td>Sabado</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeSabado || dobleDiaSabado} defaultValue={horarioIndividual[6].inicioTarde} onChange={(e) => {horarioIndividual[6].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeSabado || dobleDiaSabado} defaultValue={horarioIndividual[6].finTarde} onChange={(e) => {horarioIndividual[6].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                </tr>
                <tr>
                  <td><input type="checkbox" disabled={dobleDiaDomingo} checked={tardeDomingo} value={tardeDomingo} onChange={onChangeTardeDomingo}/></td>
                  <td>Domingo</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeDomingo || dobleDiaDomingo} defaultValue={horarioIndividual[0].inicioTarde} onChange={(e) => {horarioIndividual[0].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                  <td><input type="text" maxLength="5" disabled={!tardeDomingo || dobleDiaDomingo} defaultValue={horarioIndividual[0].finTarde} onChange={(e) => {horarioIndividual[0].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                </tr>
              </tbody>
            </table>
            <div className="text-bold">Total Horas: {totalHorasSemanales}</div>
            <div className="textright">
              <span className="colorred">{mensajeErrorHorarioIndividual}</span>
              <button className="cancelButton" onClick={closeModal}>Cancelar</button>
              <button className="acceptButton" onClick={onClickAceptar}>Aceptar</button>
            </div>
          </article>
        </ModalAjustes>
      </nav>
    </>
  );
}
