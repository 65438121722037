import "./HorasDiaSemanaLoading.css"

export default function HorasDiaSemanaLoading() {
  return (
    <section className="horasDiaSemanaContainerLoading">
      <article />
      <article />
    </section>
  )
}
