import React from "react"
import { useState, useEffect } from "react";
import { getRegistro } from "../../firebase/firebase";
import { ColocarPausas } from "./ColocarPausas";
import { ModalsCambiarRegistros } from "./modalCambiarRegistros/ModalsCambiarRegistros";
import { MONTHS, WEEK_DAYS } from "../../js/consts";
import { formatHour, formatSeconds } from "../../js/functions";

export function Registro({fechaDia, registroAux, estado, trabajador, admin, horario}) {
  const [registro, setRegistro] = useState(registroAux)

  const [nombreDia, setNombreDia] = useState(null)
  const [inicioDia, setInicioDia] = useState(null)
  const [finDia, setFinDia] = useState(null)
  const [tiempoTotal, setTiempoTotal] = useState(null)
  const [diaFestivo, setDiaFestivo] = useState(false)
  const [diaTrabaja, setDiaTrabaja] = useState(true)
  const [comentarioDiaCompleto, setComentarioDiaCompleto] = useState("")
  
  useEffect(() => {
    nombreDiaF()
    inicioDiaF(registro)
    finDiaF(registro)
    setTiempoTotalF(registro)
    setComentarioDiaCompletoF(registro)
    if (estado === "FESTIVO") setDiaFestivo(true)

    if (registro !== null && registro.trabajado === false) setDiaTrabaja(false)
    else setDiaTrabaja(true) 
  }, []);

  function setComentarioDiaCompletoF(registro) {
    if (registro === undefined) return
    if (registro.comentarioDiaCompleto === undefined) return
    if (registro.comentarioDiaCompleto !== undefined) setComentarioDiaCompleto(registro.comentarioDiaCompleto)
  }

  function nombreDiaF() {
    const arrFecha = (fechaDia).split("-")
    setNombreDia(`${WEEK_DAYS[new Date(fechaDia).getDay()]} ${arrFecha[2]} ${MONTHS[parseInt(arrFecha[1]) - 1].abbr}`)
  }

  function inicioDiaF (registro) {
    if (registro.trabajado === false) setInicioDia("")
    else if (registro !== "" && registro.inicio !== undefined) setInicioDia(formatHour(registro.inicio.toDate().getHours(), registro.inicio.toDate().getMinutes()))
    else setInicioDia("")
  }

  function finDiaF (registro) {
    if (registro.trabajado === false) { setFinDia(""); return }
    if (registro.fin !== undefined) {
      if (registro !== "") setFinDia(formatHour(registro.fin.toDate().getHours(), registro.fin.toDate().getMinutes()))
      else setFinDia("")
    } else setFinDia("")
  }

  function setTiempoTotalF(registro) {
    if(registro.trabajado === false) { setTiempoTotal(registro.motivoLibre); return }
    if(registro === "" && estado === "FESTIVO") { setTiempoTotal("FESTIVO"); return }
    if(registro === "" && horario[new Date(fechaDia).getDay()].inicio === "") { setTiempoTotal(""); return }
    if(registro.inicio === undefined) { setTiempoTotal(""); return }

    let pausas = "", totalSegundos = 0, total = "DIA SIN COMPLETAR";
    if (registro.pausas !== undefined && registro.pausas !== null) {
      if (registro !== "") {
        pausas = registro.pausas.filter(pausa => pausa.tipo !== 2)
      }
      else {
        pausas = ""
      }
    } 

    if (pausas !== "" && pausas.length > 0 && registro.fin !== undefined && pausas !== null) {
      for (let i = 0; i<pausas.length; i++) {
        if (pausas[i] !== undefined) {
          if (pausas[i].fin !== undefined){
            if (pausas.length === 1) { //solo si hay 1 pausa
              const segundosAux = pausas[0].inicio.seconds - registro.inicio.seconds
              const segundosAux2 = registro.fin.seconds - pausas[0].fin.seconds
              totalSegundos = segundosAux + segundosAux2
              total = formatSeconds(totalSegundos)
              //console.log("una pausa")
            } else if (pausas.length === 2) { //hay 2 pausas
              const segundosAux = pausas[0].inicio.seconds - registro.inicio.seconds
              const segundosAux2 = pausas[1].inicio.seconds - pausas[0].fin.seconds
              const segundosAux3 = registro.fin.seconds - pausas[1].fin.seconds
              totalSegundos = segundosAux + segundosAux2 + segundosAux3
              total = formatSeconds(totalSegundos)
              //console.log("2 pausas")
            } else { // hay mas de 2 pausas
              if (i === 0) { //primera pausa
              const segundosAux = pausas[i].inicio.seconds - registro.inicio.seconds
              totalSegundos = totalSegundos + segundosAux
              //console.log("tres pausas 1, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundos)
              } else if (i < pausas.length) { //pausas intermedias
              const segundosAux = pausas[i].inicio.seconds - pausas[i-1].fin.seconds
              totalSegundos = totalSegundos + segundosAux
              //console.log("tres pausas 2, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundos)
              } 
              if(i === pausas.length-1) { //ultima pausa
              const segundosAux = registro.fin.seconds - pausas[i].fin.seconds
              totalSegundos = totalSegundos + segundosAux
              //console.log("tres pausas 3, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundos)
              total = formatSeconds(totalSegundos)
              }
            }
          }
        }
      }
    }
    else if (registro.fin !== undefined) {
      const totalSegundos = registro.fin.seconds - registro.inicio.seconds
      total = formatSeconds(totalSegundos)
    }
    if (registro === "" && total === "DIA SIN COMPLETAR") return ""
    setTiempoTotal(total)
  }

  async function onChangeRegistro() {
    const registroAux = await getRegistro(trabajador, fechaDia)
    setRegistro(registroAux)
    inicioDiaF(registroAux)
    finDiaF(registroAux)
    setTiempoTotalF(registroAux)

    if (registroAux.trabajado === false) setDiaTrabaja(false)
    else setDiaTrabaja(true)
  }

  if (admin === true) {
    if (diaTrabaja === false) {
      return (
        <tr key={fechaDia} className="bgdianotrabajado">
          <td>{nombreDia}</td>
          <td>{inicioDia}</td>
          <td><ColocarPausas registro={registro}/></td>
          <td>{finDia}</td>
          <td>{tiempoTotal}</td>
          <td><ModalsCambiarRegistros registro={registro} trabajador={trabajador} fechaDia={fechaDia} onChangeRegistro={onChangeRegistro} horario={horario} /></td>
        </tr>
      );
    }
    else if (horario[new Date(fechaDia).getDay()].inicio === "") {
      return (
        <tr key={fechaDia} className="bgdiadomingo">
          <td>{nombreDia}</td>
          <td>{inicioDia}</td>
          <td><ColocarPausas registro={registro}/></td>
          <td>{finDia}</td>
          <td>{tiempoTotal}</td>
          <td><ModalsCambiarRegistros registro={registro} trabajador={trabajador} fechaDia={fechaDia} onChangeRegistro={onChangeRegistro} horario={horario} /></td>
        </tr>
      );
    }
    else if (diaFestivo === true && diaTrabaja === true) {
      return (
        <tr key={fechaDia} className="bgdiafestivo">
          <td>{nombreDia}</td>
          <td>{inicioDia}</td>
          <td><ColocarPausas registro={registro}/></td>
          <td>{finDia}</td>
          <td>{tiempoTotal}</td>
          <td><ModalsCambiarRegistros registro={registro} trabajador={trabajador} fechaDia={fechaDia} onChangeRegistro={onChangeRegistro} horario={horario} /></td>
        </tr>
      );
    }
    else if (diaFestivo === false && diaTrabaja === true) {
      return (
        <tr key={fechaDia} className="bgdialaboral">
          <td>{nombreDia}</td>
          <td>{inicioDia}</td>
          <td><ColocarPausas registro={registro}/></td>
          <td>{finDia}</td>
          <td>{tiempoTotal}</td>
          <td><ModalsCambiarRegistros registro={registro} trabajador={trabajador} fechaDia={fechaDia} onChangeRegistro={onChangeRegistro} horario={horario} /></td>
        </tr>
      );
    }
  } 
  else if (admin === false) {
    if (diaTrabaja === false) {
      return (
        <tr key={fechaDia} className="bgdianotrabajado">
          <td>{nombreDia}</td>
          <td>{inicioDia}</td>
          <td><ColocarPausas registro={registro}/></td>
          <td>{finDia}</td>
          <td>{tiempoTotal}</td>
        </tr>
      );
    }
    else if (horario[new Date(fechaDia).getDay()].inicio === "") {
      return (
        <tr key={fechaDia} className="bgdiadomingo">
          <td>{nombreDia}</td>
          <td>{inicioDia}</td>
          <td><ColocarPausas registro={registro}/></td>
          <td>{finDia}</td>
          <td>{tiempoTotal}</td>
        </tr>
      );
    }
    else if (diaFestivo === true && diaTrabaja === true) {
      return (
        <tr key={fechaDia} className="bgdiafestivo">
          <td>{nombreDia}</td>
          <td>{inicioDia}</td>
          <td><ColocarPausas registro={registro}/></td>
          <td>{finDia}</td>
          <td>{tiempoTotal}</td>
        </tr>
      );
    }
    else if (diaFestivo === false && diaTrabaja === true) {
      return (
        <tr key={fechaDia} className="bgdialaboral">
          <td>{nombreDia}</td>
          <td>{inicioDia}</td>
          <td><ColocarPausas registro={registro}/></td>
          <td>{finDia}</td>
          <td>{tiempoTotal}</td>
        </tr>
      );
    }
  } else return <></>
}
