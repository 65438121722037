import React from "react"
import { LaboralesFestivos } from "./LaboralesFestivos";

export function RegistroDiasFestivos({ diasMes, bRegistros }) {

  if (bRegistros === true) {
    return (
      <table id="holidayDayTable">
        <thead>
          <tr>
            <th>Dia</th>
            <th>#</th>
            <th>Cambiar</th>
          </tr>
        </thead>
        <tbody>
          {diasMes.map((diaMes) => (
            <LaboralesFestivos key={diaMes.fechaDia} diaMes={diaMes} registro={null} />
          ))}
        </tbody>
      </table>
    )
  } else return(<></>);
}
