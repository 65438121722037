import { formatHour } from "../../js/functions"

export function ColocarPausas({ registro }) {

  let pausas = "", pausasFinal = []
  if(registro !== "" && registro.pausas !== undefined) pausas = registro.pausas
  else pausas = ""
  
  if (pausas !== "" && pausas !== null){
    for (let i = 0; i<pausas.length; i++) {
      if (pausas[i] !== undefined) {
        if (pausas[i].inicio.seconds !== undefined) {
          if (pausas[i].fin !== undefined){
            if (pausas[i].fin.seconds !== undefined) {
              pausasFinal.push(formatHour(pausas[i].inicio.toDate().getHours(), pausas[i].inicio.toDate().getMinutes()) + " - " + formatHour(pausas[i].fin.toDate().getHours(), pausas[i].fin.toDate().getMinutes()))
            }
            else {
              pausasFinal.push(formatHour(pausas[i].inicio.toDate().getHours(), pausas[i].inicio.toDate().getMinutes()) + " - " + formatHour(pausas[i].fin.getHours(), pausas[i].fin.getMinutes()))
            }
          }
          else {
            pausasFinal.push(formatHour(pausas[i].inicio.toDate().getHours(), pausas[i].inicio.toDate().getMinutes()))
          }
        } 
        else {
          if (pausas[i].fin !== undefined){
            if (pausas[i].fin.seconds !== undefined) {
              pausasFinal.push(formatHour(pausas[i].inicio.getHours(), pausas[i].inicio.getMinutes()) + " - " + formatHour(pausas[i].fin.toDate().getHours(), pausas[i].fin.toDate().getMinutes()))
            }
            else {
              pausasFinal.push(formatHour(pausas[i].inicio.getHours(), pausas[i].inicio.getMinutes()) + " - " + formatHour(pausas[i].fin.getHours(), pausas[i].fin.getMinutes()))
            }
          }
          else {
            pausasFinal.push(formatHour(pausas[i].inicio.getHours(), pausas[i].inicio.getMinutes()))
          }
        }
      }
    }
  }

  if (registro.trabajado === false) return "" 

  return (
      <>
        {pausasFinal.map((pausa, index) => (
            <div key={index}>{pausa}</div>
        ))}
      </>
  );
  }
  