import {
  onAuthStateChanged,
  signOut,
  updatePassword,
} from "firebase/auth";
import { useState, useEffect } from "react";
import { auth, getUser, updateTrabajador, userExists } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import logo from "../img/logo.png"
import logo_caritas from "../img/logo_caritas.webp"
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO, contraseñaPorDefecto, colorProject } from "./../js/project"

export function ChangePassword() {

  const [user, setUser] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mensajeDeAviso, setMensajeDeAviso] = useState("");
  const [stateCambiarContraseña, setStateCambiarContraseña] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const isRegistered = await userExists(user.uid);
        if (isRegistered) {
          //TODO: redirigir a Dashboard
          const userDB = await getUser(user.uid)
          setUser(userDB);

          if (userDB.estado === false) { 
            logout()
          }
          else if (userDB.primerAcceso !== true) { 
            if (userDB.admin === false) {
              navigate("/controlHorario")
            } 
            if (userDB.admin === true) {
              if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
                navigate("/admin")
              }
              if (PROJECT_APP === CARITASTOLEDO) {
                navigate("/controlHorario")
              }
            } 
          }
          // setCurrentState(2);
          // setCurrentUser(user);
          // console.log(user.email);
        } else {
          logout()
        }
      } else {
        navigate("/login")
        // console.log("No hay nadie autenticado ... ");
      }
    });
  }, [navigate]);

  function logout() {
    signOut(auth)
      .then(() => {
        navigate("/login")
      })
      .catch((error) => {
        
      });

    //console.log("OUT");
    //auth().logout();
    //window.location.href = "login.html";
  }

  function resetPassword() {
    setStateCambiarContraseña(true)

    if (password !== confirmPassword) {
      setMensajeDeAviso("Las contraseñas no coinciden")
      setStateCambiarContraseña(false)
      return;
    }

    if (password === "") {
      setMensajeDeAviso("La contraseña está en blanco")
      setStateCambiarContraseña(false)
      return;
    }

    if (password.length < 6) {
      setMensajeDeAviso(`La contraseña tiene que tener mínimo 6 caracteres`)
      setStateCambiarContraseña(false)
      return;
    }

    if (password === contraseñaPorDefecto) {
      setMensajeDeAviso(`La contraseña no puede ser la misma que la anterior`)
      setStateCambiarContraseña(false)
      return;
    }

    updatePassword(auth.currentUser, password)
    .then(() => {
      updateUser()
    })
    .catch((error) => {
      // An error occurred
      // ...
      // console.log(error);
      if (error.code === "auth/requires-recent-login") { 
        setMensajeDeAviso("Cierra sesion e intentalo de nuevo"); 
        setStateCambiarContraseña(false)
      }
      else {
        setMensajeDeAviso("Error al cambiar la contraseña")
        setStateCambiarContraseña(false)
      }

    })
  }

  async function updateUser() {
    user.primerAcceso = false
    await updateTrabajador(user)
    
    if (user.admin === false) navigate("/controlHorario")
    else if (user.admin === true) {
      if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
        navigate("/admin")
      }
      if (PROJECT_APP === CARITASTOLEDO) {
        navigate("/controlHorario")
      }
    }
    
  }

  // Comprobación de acceso a resetear la contraseña abajo en los botones (botones desactivados si no se cumplen las condiciones)
  return (
    <div style={{backgroundColor: colorProject}} className="modal-login">
      <div className="modal-container-login">
        <div className="textcenter">
          { PROJECT_APP === ECINNOVA && 
            <img style={{aspectRatio: "191/140"}} src={logo} width="280px" alt="logo_empresa" />
          }
          { PROJECT_APP === INSERTATOLEDO && 
            <div className="colorred titulo2">INSERTA TOLEDO</div>
          }
          { PROJECT_APP === CARITASTOLEDO && 
            <img style={{aspectRatio: "261/193"}} src={logo_caritas} width="280px" alt="logo_empresa" />
          }
        </div>
        <p className="textcenter textbalance">Al ser tu primer inicio de sesión tienes que cambiar la contraseña</p>
        
        <form className="formChangePassword">
          <label htmlFor="changePassword">Nueva contraseña</label>
            <input
              placeholder="Password"
              id="changePassword"
              onChange={(e) => {
                setPassword(e.target.value);
                setMensajeDeAviso("")
              }}
              value={password}
              type="password"
              autoFocus
            />
          <label htmlFor="repeatChangePassword">Repite la contraseña</label>
            <input
              placeholder="Password"
              id="repeatChangePassword"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setMensajeDeAviso("")
              }}
              type="password"
              value={confirmPassword}
            />
          <p className="colorred">{mensajeDeAviso}</p>

          <div className="buttonsBetween">
            {user !== null && user.primerAcceso === true && user.estado === true &&
              <>
                <button disabled={stateCambiarContraseña} onClick={logout} className="cancelButton">Cerrar sesion</button>
                <button disabled={stateCambiarContraseña} onClick={resetPassword} className="successButton">Confirmar</button>
              </>
            }
            {(user === null || user.primerAcceso !== true || user.estado !== true) &&
              <>
                <button disabled={true} onClick={logout} className="cancelButton disablebutton">Cerrar sesion</button>
                <button disabled={true} onClick={resetPassword} className="successButton">Confirmar</button>
              </>
            }
          </div>
        </form>
      </div>
    </div>
  );
}
