export const BLANK = "BLANK";
export const WEEK_DAYS = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];
export const YEARS = ["2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"]
export const REASONS_NOT_WORKING = ["VACACIONES", "BAJA MEDICA", "BAJA PATERNAL/MATERNAL", "ASUNTOS PROPIOS", "OTROS"]
export const REASONS_JUSTIFIED_PAUSE = ["VISITA MEDICA", "TRATAMIENTO", "GESTIONES ADMINISTRATIVAS", "TUTORIA", "OTROS"]
export const TRABAJADOR_SOCIAL = "TRABAJADOR SOCIAL"
export const MONTHS = [
  {
    abbr: "ENE",
    name: "Enero",
    number: "01",
  },
  {
    abbr: "FEB",
    name: "Febrero",
    number: "02",
  },
  {
    abbr: "MAR",
    name: "Marzo",
    number: "03",
  },
  {
    abbr: "ABR",
    name: "Abril",
    number: "04",
  },
  {
    abbr: "MAY",
    name: "Mayo",
    number: "05",
  },
  {
    abbr: "JUN",
    name: "Junio",
    number: "06",
  },
  {
    abbr: "JUL",
    name: "Julio",
    number: "07",
  },
  {
    abbr: "AGO",
    name: "Agosto",
    number: "08",
  },
  {
    abbr: "SEP",
    name: "Septiembre",
    number: "09",
  },
  {
    abbr: "OCT",
    name: "Octubre",
    number: "10",
  },
  {
    abbr: "NOV",
    name: "Noviembre",
    number: "11",
  },
  {
    abbr: "DEC",
    name: "Diciembre",
    number: "12",
  },
];
