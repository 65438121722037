import { useState, useEffect } from "react";
import {
  getRegistrosPorAñoyMes,
  getRegistrosPorAño,
  getDiasFestivosPorAñoyMes,
  getDiasFestivosPorAño
} from "../../firebase/firebase";
import { RegistroDias } from "./RegistroDias";
import { RegistroDiasExcel } from "./RegistroDiasExcel";
import { ModalsRegistrarTrabajador } from "./modalRegistrarTrabajador/ModalsRegistrarTrabajador";
import { Wrapper } from "./Wrapper";
import { RegistroDiasExcelAño } from "./RegistroDiasExcelAño";
import { RegistroDiasAño } from "./RegistroDiasAño";
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO } from "./../../js/project"
import { formatNumber, getFormatActualMonth, getFormatActualYear } from "../../js/functions";
import { BLANK, MONTHS, YEARS } from "../../js/consts";

export default function ListaTrabajadores({ centros, trabajadoresAux, horariosPrestablecidos }) {
  const [trabajadores, setTrabajadores] = useState([]);
  const [tablaTrabajadores, setTablaTrabajadores] = useState([]);
  const [trabajadoresPorPaginas, setTrabajadoresPorPaginas] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [paginasTotales, setPaginasTotales] = useState(0)
  const [entries, setEntries] = useState(10)
  const [bRegistros, setBRegistros] = useState(false);
  const [bRegistrosAño, setBRegistrosAño] = useState(false);
  const [registros, setRegistros] = useState([]);
  const [registrosAño, setRegistrosAño] = useState([]);
  const [añoSelect, setAñoSelect] = useState(getFormatActualYear())
  const [mesSelect, setMesSelect] = useState(getFormatActualMonth())
  const [busqueda, setBusqueda] = useState("")
  const [trabajador, setTrabajador] = useState(null)
  // const [horario, setHorario] = useState(false)
  const [stateRegistroMensual, setStateRegistroMensual] = useState(true)
  const [stateRegistroAnual, setStateRegistroAnual] = useState(false)
  const [stateOcultarTrabajadoresInactivos, setStateOcultarTrabajadoresInactivos] = useState(true)
  const [trabajadoresActivosEInactivos, setTrabajadoresActivosEInactivos] = useState([])
  const [centro, setCentro] = useState(BLANK)

  useEffect(() => {
    todosLosTrabajadores();
  }, []);

  async function todosLosTrabajadores() {
    setTrabajadoresActivosEInactivos([...trabajadoresAux])

    const trabajadoresActivos = trabajadoresAux.filter(trabajador => trabajador.estado === true);

    setTrabajadores([...trabajadoresActivos]);
    setTablaTrabajadores([...trabajadoresActivos]);
    setTrabajadoresPorPaginas([...trabajadoresActivos].splice(0, entries));
    if ([...trabajadoresActivos].length%entries === 0) setPaginasTotales([...trabajadoresActivos].length/entries);
    else setPaginasTotales(parseInt([...trabajadoresActivos].length/entries)+1);
  }

  async function verRegistros(trabajador, año, mes) {
    
    // Creo los dias del mes actual pasandole el parametro año y mes
    const diasMes = new Date(año, mes, 0).getDate();

    // Traigo todos los dias festivos por mes y año de la base de datos
    const diasMesFestivos = await getDiasFestivosPorAñoyMes(`${año}-${mes}`)

    // Traigo todos los registros por mes y año de la base de datos
    const registrosDB = await getRegistrosPorAñoyMes(trabajador,`${año}-${mes}`)

    // Hago un for para que recorra cada dia del mes seleccionado
    for (let dia = 1; dia <= diasMes; dia++) {

      // Creo la fecha del dia actual con el formato YYYY-MM-DD
      const fechaDia = `${año}-${mes}-${formatNumber(dia)}`

      // Creo todos los registros en el array de registros con fechaDia, registro "", y el estado en laboral
      registros.push({fechaDia: fechaDia, registro: "", estado: "LABORAL"})

      // Si los registros obtenidos de la base de datos de este mes no es un array vacio se ejecuta el if
      if (registrosDB.length !== 0) {

        // Creo un for que recorre todos los registros de la base de datos que con el if de antes ya se que no es 0
        for (let i = 0; i<registrosDB.length; i++) {

          // Si la fechaDia (primer for) es igual a la fecha de cada registro (anterior for) entonces se ejecuta
          if (fechaDia === registrosDB[i].fecha) {

            // Se asigna en el registro[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
            // en 0) la fecha (que es la misma que ya estaba asignada), el registro, y el estado laboral ya que
            // estoy dentro del for de los registros => luego le asigno festivo si así toca
            registros[dia-1] = {fechaDia: fechaDia, registro: registrosDB[i], estado: "LABORAL"}
          }
        }
      }

      // Si los registros obtenidos de la base de datos de este mes no es un array vacio y los dias
      // festivos obtenidos de la base de datos tampoco es un string vacio entonces se ejecuta el if
      if (registrosDB.length !== 0 && diasMesFestivos.length !== 0) {

        // Creo un for que recorre todos los registros de la base de datos que con el if de antes ya se que no es 0
        for (let i = 0; i<registrosDB.length; i++) {

          // Creo otro for anidado al anterior que recorre todos los dias festivos de la base de datos
          // que como antes he dicho no es 0 por el if
          for (let d = 0; d < diasMesFestivos.length; d++) {

            // Si la fechaDia (primer for) = a la fecha de los registros (segundo for) entonces se ejecuta el if
            if (fechaDia === diasMesFestivos[d].dia) {

              // Se asigna en el estado del registros[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
              // en 0) el string "FESTIVO" ya que todos los dias que entren en este if son festivos
              registros[dia-1].estado = "FESTIVO"
            }

            // Si la fechaDia (primer for) = a la fecha de los registros (segundo for) && la fecha dia (primer for)
            // es igual a la fecha de los dias festivos (tercer for) entonces se ejecuta el if
            if (fechaDia === registrosDB[i].fecha && fechaDia === diasMesFestivos[d].dia) {

              // Se asigna en el registro[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
              // en 0) la fecha (que es la misma que ya estaba asignada), el registro, y el estado festivo ya que
              // estoy dentro del for de los dias festivos (tercer for)
              registros[dia-1] = {fechaDia: fechaDia, registro: registrosDB[i], estado: "FESTIVO"}
            }
          }
        } 
      } 

      // Else => Si los registros obtenidos de la base de datos es un array vacio entonces se ejecuta este if
      else if (registrosDB.length === 0) {

        // Creo un for que recorre todos los dias festivos ya que hemos visto que 
        // no hay registros este mes y minimo hay un dia festivo
        for (let d = 0; d < diasMesFestivos.length; d++) {

          // Si la fechaDia (primer for) es igual a la fecha de cada dia festivo (anterior for) entonces se ejecuta
          if (fechaDia === diasMesFestivos[d].dia) {

            // Se asigna en el registro[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
            // en 0) la fecha (que es la misma que ya estaba asignada), el registro se deja en blanco ya que el array
            // de registros es 0 y el estado en festivo ya que en este for estamos recorriendo los dias festivos sin registros
            registros[dia-1] = {fechaDia: fechaDia, registro: "", estado: "FESTIVO"}
          } 
        } 
      } 

      // Else => Si los dias festivos obtenidos de la base de datos es un array vacio entonces se ejecuta este if
      else if (diasMesFestivos.length === 0) {

        // Creo un for que recorre todos los registros ya que hemos visto que 
        // no hay dias festivos este mes y minimo hay un registro
        for (let i = 0; i < registrosDB.length; i++) {

          // Si la fechaDia (primer for) es igual a la fecha de cada registro (anterior for) entonces se ejecuta
          if (fechaDia === registrosDB[i].fecha) {

            // Se asigna en el registro[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
            // en 0) la fecha (que es la misma que ya estaba asignada), en el registro se asigna el registro de este for
            // y el estado en laboral ya que en el if de antes estamos comprobando que no hay ningun dia festivo
            registros[dia-1] = {fechaDia: fechaDia, registro: registrosDB[i], estado: "LABORAL"}
          } 
        }
      }
    }
    setBRegistros(true)
  }

  async function verRegistrosPorAño(trabajador, año) {

    // Creo la variable para saber en que dia del año estoy dentro del for
    let diaActualAñoFor = 0

    // Traigo todos los dias festivos por año de la base de datos
    const diasAñoFestivos = await getDiasFestivosPorAño(`${año}`)

    // Traigo todos los registros por año de la base de datos
    const registrosDB = await getRegistrosPorAño(trabajador, `${año}`)

    // [for nº 0] Creo un for para recorrer 12 veces los registros ya que son 12 meses en un año
    for (let mes = 1; mes <= 12; mes++) {

      // Establezco los dias del mes dependiendo del mes que sea (for anterior)
      const diasMes = new Date(año, formatNumber(mes), 0).getDate();

      // [primer for] Hago un for para que recorra cada dia del mes seleccionado
      for (let dia = 1; dia <= diasMes; dia++) {

        // Creo la fecha del dia actual con el formato YYYY-MM-DD
        const fechaDia = `${año}-${formatNumber(mes)}-${formatNumber(dia)}`

        // Creo todos los registros en el array de registros con fechaDia, registro "", y el estado en laboral
        registrosAño.push({fechaDia: fechaDia, registro: "", estado: "LABORAL"})

        // Si los registros obtenidos de la base de datos de este mes no es un array vacio se ejecuta el if
        if (registrosDB.length !== 0) {

          // Creo un for que recorre todos los registros de la base de datos que con el if de antes ya se que no es 0
          for (let i = 0; i<registrosDB.length; i++) {

            // Si la fechaDia (primer for) es igual a la fecha de cada registro (anterior for) entonces se ejecuta
            if (fechaDia === registrosDB[i].fecha) {

              // Se asigna en el registro[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
              // en 0) la fecha (que es la misma que ya estaba asignada), el registro, y el estado laboral ya que
              // estoy dentro del for de los registros => luego le asigno festivo si así toca
              registrosAño[diaActualAñoFor] = {fechaDia: fechaDia, registro: registrosDB[i], estado: "LABORAL"}
            }
          }
        }

        // Si los registros obtenidos de la base de datos de este mes no es un array vacio y los dias
        // festivos obtenidos de la base de datos tampoco es un string vacio entonces se ejecuta el if
        if (registrosDB.length !== 0 && diasAñoFestivos.length !== 0) {

          // Creo un for que recorre todos los registros de la base de datos que con el if de antes ya se que no es 0
          for (let i = 0; i<registrosDB.length; i++) {

            // Creo otro for anidado al anterior que recorre todos los dias festivos de la base de datos
            // que como antes he dicho no es 0 por el if
            for (let d = 0; d < diasAñoFestivos.length; d++) {

              // Si la fechaDia (primer for) = a la fecha de los registros (segundo for) entonces se ejecuta el if
              if (fechaDia === diasAñoFestivos[d].dia) {

                // Se asigna en el estado del registros[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
                // en 0) el string "FESTIVO" ya que todos los dias que entren en este if son festivos
                registrosAño[diaActualAñoFor].estado = "FESTIVO"
              }

              // Si la fechaDia (primer for) = a la fecha de los registros (segundo for) && la fecha dia (primer for)
              // es igual a la fecha de los dias festivos (tercer for) entonces se ejecuta el if
              if (fechaDia === registrosDB[i].fecha && fechaDia === diasAñoFestivos[d].dia) {

                // Se asigna en el registro[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
                // en 0) la fecha (que es la misma que ya estaba asignada), el registro, y el estado festivo ya que
                // estoy dentro del for de los dias festivos (tercer for)
                registrosAño[diaActualAñoFor] = {fechaDia: fechaDia, registro: registrosDB[i], estado: "FESTIVO"}
              }
            }
          } 
        } 

        // Else => Si los registros obtenidos de la base de datos es un array vacio entonces se ejecuta este if
        else if (registrosDB.length === 0) {

          // Creo un for que recorre todos los dias festivos ya que hemos visto que 
          // no hay registros este mes y minimo hay un dia festivo
          for (let d = 0; d < diasAñoFestivos.length; d++) {

            // Si la fechaDia (primer for) es igual a la fecha de cada dia festivo (anterior for) entonces se ejecuta
            if (fechaDia === diasAñoFestivos[d].dia) {

              // Se asigna en el registro[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
              // en 0) la fecha (que es la misma que ya estaba asignada), el registro se deja en blanco ya que el array
              // de registros es 0 y el estado en festivo ya que en este for estamos recorriendo los dias festivos sin registros
              registrosAño[diaActualAñoFor] = {fechaDia: fechaDia, registro: "", estado: "FESTIVO"}
            } 
          } 
        } 

        // Else => Si los dias festivos obtenidos de la base de datos es un array vacio entonces se ejecuta este if
        else if (diasAñoFestivos.length === 0) {

          // Creo un for que recorre todos los registros ya que hemos visto que 
          // no hay dias festivos este mes y minimo hay un registro
          for (let i = 0; i < registrosDB.length; i++) {

            // Si la fechaDia (primer for) es igual a la fecha de cada registro (anterior for) entonces se ejecuta
            if (fechaDia === registrosDB[i].fecha) {

              // Se asigna en el registro[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
              // en 0) la fecha (que es la misma que ya estaba asignada), en el registro se asigna el registro de este for
              // y el estado en laboral ya que en el if de antes estamos comprobando que no hay ningun dia festivo
              registrosAño[diaActualAñoFor] = {fechaDia: fechaDia, registro: registrosDB[i], estado: "LABORAL"}
            } 
          }
        }
        // Le sumo 1 a dia actual del año cuando esté dentro del for de los meses que está dentro del for del 
        // año => mas o menos 365 dias (depende de bisiestos)
        diaActualAñoFor++
      }
    }
    setBRegistrosAño(true)
  }

  function setRegistrosNull() {
    setBRegistros(false)
    setBRegistrosAño(false)
    setRegistros([])
    setRegistrosAño([])
  }

  function mostrarTrabajador(trabajadorAux) {
    // if (trabajadorAux.horarioPre !== undefined && trabajadorAux.horarioPre === true) {
    //   setHorario(horariosPrestablecidos[trabajadorAux.horarioPre] - 1)
    // }
    // else if (trabajadorAux.dias !== undefined && trabajadorAux.horarioPre === false) {
    //   setHorario(trabajadorAux.dias)
    // }
    setTrabajador(trabajadorAux)
    setBRegistros(false)
    setBRegistrosAño(false)
    setRegistros([])
    setRegistrosAño([])
  }

  function onChangeAño (e) {
    // console.log(e.target.value)
    setAñoSelect(e.target.value)
    setRegistrosNull()
  }

  function onChangeMes (e) {
    // console.log(e.target.value)
    setMesSelect(e.target.value)
    setRegistrosNull()
  }

  function onClickRegistros () {
    verRegistros(trabajador, añoSelect, mesSelect)
  }

  function onClickRegistrosAño () {
    verRegistrosPorAño(trabajador, añoSelect)
  }

  function handleBusqueda(e) {
    // console.log(e.target.value)
    setBusqueda(e.target.value)
    filtrar(e.target.value)
  }

  function filtrar (terminoBusqueda) {
    let resultadosBusqueda = tablaTrabajadores.filter((trabajador)=>{
      const nombreyapellidos = `${trabajador.name} ${trabajador.apellidos}`
      if (trabajador.name.toLowerCase().includes(terminoBusqueda.toLowerCase()) || 
      trabajador.email.toLowerCase().includes(terminoBusqueda.toLowerCase()) || 
      trabajador.apellidos.toLowerCase().includes(terminoBusqueda.toLowerCase()) || 
      nombreyapellidos.toLowerCase().includes(terminoBusqueda.toLowerCase())) {
        return trabajador
      }
    });
    setCurrentPage(1)
    if (resultadosBusqueda.length%entries === 0) setPaginasTotales(resultadosBusqueda.length/entries);
    else setPaginasTotales(parseInt(resultadosBusqueda.length/entries)+1);
    
    setTrabajadores([...resultadosBusqueda])
    setTrabajadoresPorPaginas(resultadosBusqueda.splice(0, entries));
    
    // console.log(currentPage);
  }

  function prevHandler() {
    const prevPage = currentPage - 1
    
    if (prevPage < 1) return;

    const firstIndex = (prevPage - 1) * entries;

    setTrabajadoresPorPaginas([...trabajadores].splice(firstIndex, entries))
    setCurrentPage(prevPage)
    // console.log(prevPage);
  }

  function nextHandler() {
    const totalElementos = trabajadores.length;
    const nextPage = currentPage + 1;
    const firstIndex = (nextPage - 1) * entries;

    if (firstIndex >= totalElementos) return;

    setTrabajadoresPorPaginas([...trabajadores].splice(firstIndex, entries))
    setCurrentPage(nextPage)
    // console.log(nextPage);
  }

  function onChangeEntries(e) {
    setEntries(e.target.value)
    setCurrentPage(1)
    if ([...trabajadores].length%e.target.value === 0) setPaginasTotales([...trabajadores].length/e.target.value);
    else setPaginasTotales(parseInt([...trabajadores].length/e.target.value)+1);
    
    setTrabajadoresPorPaginas([...trabajadores].splice(0, e.target.value));
  }

  function onChangeCheckboxRegistroMensual(e) {
    if (e.target.value === "false") {
      setStateRegistroMensual(true)
      setStateRegistroAnual(false)
      setBRegistros(null)
      setBRegistrosAño(null)
      setRegistros([])
      setRegistrosAño([])
    }
  }

  function onChangeCheckboxRegistroAnual(e) {
    if (e.target.value === "false") {
      setStateRegistroAnual(true)
      setStateRegistroMensual(false)
      setBRegistros(null)
      setBRegistrosAño(null)
      setRegistros([])
      setRegistrosAño([])
    }
  }

  function onChangeTrabajadoresInactivos(e) {
    if (e.target.value === "false") {
      setCentro(BLANK)
      setBusqueda("")
      setStateOcultarTrabajadoresInactivos(true)

      const trabajadoresActivos = trabajadoresActivosEInactivos.filter(trabajador => trabajador.estado === true);

      setTrabajadores([...trabajadoresActivos]);
      setTablaTrabajadores([...trabajadoresActivos]);
      setTrabajadoresPorPaginas([...trabajadoresActivos].splice(0, entries));
      if ([...trabajadoresActivos].length%entries === 0) setPaginasTotales([...trabajadoresActivos].length/entries);
      else setPaginasTotales(parseInt([...trabajadoresActivos].length/entries)+1);

      setBusqueda("")
    }
    else {
      setCentro(BLANK)
      setBusqueda("")
      setStateOcultarTrabajadoresInactivos(false)
      const todosLosTrabajadores = trabajadoresActivosEInactivos

      setTrabajadores([...todosLosTrabajadores]);
      setTablaTrabajadores([...todosLosTrabajadores]);
      setTrabajadoresPorPaginas([...todosLosTrabajadores].splice(0, entries));
      if ([...todosLosTrabajadores].length%entries === 0) setPaginasTotales([...todosLosTrabajadores].length/entries);
      else setPaginasTotales(parseInt([...todosLosTrabajadores].length/entries)+1);

      setBusqueda("")
    }
  }

  function onChangeSelectCentros(e) {
    if (e.target.value === BLANK) {
      setCentro(e.target.value)
      setBusqueda("")

      let trabajadoresAux = trabajadoresActivosEInactivos
      if (stateOcultarTrabajadoresInactivos === true) {
        trabajadoresAux = trabajadoresActivosEInactivos.filter(trabajador => trabajador.estado === true);
      }
      setTrabajadores([...trabajadoresAux]);
      setTablaTrabajadores([...trabajadoresAux]);
      setTrabajadoresPorPaginas([...trabajadoresAux].splice(0, entries));
      if ([...trabajadoresAux].length%entries === 0) setPaginasTotales([...trabajadoresAux].length/entries);
      else setPaginasTotales(parseInt([...trabajadoresAux].length/entries)+1);

      setBusqueda("")
    }
    else {
      setCentro(e.target.value)
      setBusqueda("")

      let trabajadoresAux = trabajadoresActivosEInactivos
      if (stateOcultarTrabajadoresInactivos === true) {
        trabajadoresAux = trabajadoresActivosEInactivos.filter(trabajador => trabajador.estado === true);
      }
      const trabajadoresAux2 = trabajadoresAux.filter(trabajador => trabajador.centro === e.target.value)
      setTrabajadores([...trabajadoresAux2]);
      setTablaTrabajadores([...trabajadoresAux2]);
      setTrabajadoresPorPaginas([...trabajadoresAux2].splice(0, entries));
      if ([...trabajadoresAux2].length%entries === 0) setPaginasTotales([...trabajadoresAux2].length/entries);
      else setPaginasTotales(parseInt([...trabajadoresAux2].length/entries)+1);
    }
  }

  return (
    <>
      <section className="navListWorkers">
        <article id="registerWorker">
          <ModalsRegistrarTrabajador centros={centros} horariosPrestablecidos={horariosPrestablecidos} />
        </article>
        <article>
          <label>
            <input className="marginright-8" type="checkbox" checked={stateOcultarTrabajadoresInactivos} value={stateOcultarTrabajadoresInactivos} onChange={onChangeTrabajadoresInactivos} /> 
            Ocultar inactivos
          </label>
        </article>
        <article>
          { PROJECT_APP === ECINNOVA && 
            <></>
          }
          { PROJECT_APP === INSERTATOLEDO && 
            <></>
          }
          { PROJECT_APP === CARITASTOLEDO && 
            <select className="zindex10" value={centro} onChange={onChangeSelectCentros}>
              <option key={BLANK} value={BLANK}></option>
              {centros.map((centro) => (
                <option key={centro.name} value={centro.name}>{centro.name}</option>
              ))}
            </select>
          }
        </article>
        <article>
          <label>
            Mostrar&nbsp;
            <select className="textcenter" onChange={onChangeEntries}>
              <option key="10" value="10">10</option>
              <option key="25" value="25">25</option>
              <option key="50" value="50">50</option>
              <option key="100" value="100">100</option>
            </select>
          </label>
        </article>
        <article>
          <input type="text" value={busqueda} onChange={handleBusqueda} placeholder="Buscar trabajador..."/>
        </article>
      </section>
      <section id="tableListWorkers">
        <Wrapper trabajadores={trabajadoresPorPaginas} currentPage={currentPage} paginasTotales={paginasTotales} prevHandler={prevHandler} nextHandler={nextHandler} mostrarTrabajador={mostrarTrabajador} centros={centros} horariosPrestablecidos={horariosPrestablecidos} />
      </section>

      {trabajador !== null &&
        <section id="workersRecords">
          <div className="titulo3">Registros de: {trabajador.name} {trabajador.apellidos}</div>
          <div>
            <label>
              <input type="checkbox" checked={stateRegistroMensual} value={stateRegistroMensual} onChange={onChangeCheckboxRegistroMensual} />
              &nbsp; Registro Mensual
            </label>
            <label>
              <input type="checkbox" checked={stateRegistroAnual} value={stateRegistroAnual} onChange={onChangeCheckboxRegistroAnual} />
              &nbsp; Registro Anual
            </label>
          </div>
          {stateRegistroMensual === true &&
            <div>
              Año:&nbsp;
              <select onChange={onChangeAño} defaultValue={añoSelect}>
                {YEARS.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
              &nbsp;

              Mes:&nbsp;
              <select onChange={onChangeMes} defaultValue={mesSelect}>
                {MONTHS.map((month) => (
                  <option key={month.number} value={month.number}>{month.name}</option>
                ))}
              </select>
              &nbsp;&nbsp;
              <button className="acceptButton" onClick={onClickRegistros}>Ver Registros</button>
              <RegistroDiasExcel registros={registros} trabajador={trabajador} bRegistros={bRegistros}></RegistroDiasExcel>
              
            </div>
          }
          {stateRegistroAnual === true && 
            <div>
              Año:&nbsp;
              <select onChange={onChangeAño} defaultValue={añoSelect}>
                {YEARS.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
              &nbsp;&nbsp;

              <button className="acceptButton" onClick={onClickRegistrosAño}>Ver Registros</button>
              <RegistroDiasExcelAño registros={registrosAño} trabajador={trabajador} bRegistros={bRegistrosAño} bRegistrosAño={bRegistrosAño} verRegistros={onClickRegistrosAño}></RegistroDiasExcelAño>
            </div>
          }
        
          <RegistroDias registros={registros} trabajador={trabajador} bRegistros={bRegistros} admin={true} bajaVacaciones={false}></RegistroDias>
          <RegistroDiasAño registros={registrosAño} trabajador={trabajador} bRegistros={bRegistrosAño} admin={true} bajaVacaciones={false}></RegistroDiasAño>

          <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

        </section>
      }
      {trabajador === null &&
        <>
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </>
      }
    </>
  );
}
