import { useState, useEffect } from "react";
import { ColocarPausas } from "../controlhorarioadmin/ColocarPausas";
import TablaHorasLoading from "./TablaHorasLoading";
import { diaSemana, formatHour, formatSeconds, getDiaByFecha, getNombreMesByFecha } from "../../js/functions";
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO } from "./../../js/project"
import { WEEK_DAYS } from "../../js/consts";

export default function TablaHoras({ semana }) {
  const [horaFin, setHoraFin] = useState([]);
  const [horaInicio, setHoraInicio] = useState([]);
  const [horaInicioRegistro, setHoraInicioRegistro] = useState([]);
  const [horaFinRegistro, setHoraFinRegistro] = useState([]);
  const [nombreDia, setNombreDia] = useState([]);
  const [tiempoTotalDia, setTiempoTotalDia] = useState([]);
  const [tiempoTotalDiaHorario, setTiempoTotalDiaHorario] = useState([]);

  const [tablaHoras, setTablaHoras] = useState(null);

  useEffect(() => {
    setNombreDiaSemana()
    setInicioDia()
    setInicioDiaHorario()
    setFinDia()
    setFinDiaHorario()
    setTiempoTotal()
    setTiempoTotalHorario()
  }, []);

  useEffect(() => {
    if (
      horaFin !== null && horaInicio !== null && horaInicioRegistro !== null &&
      horaFinRegistro !== null && nombreDia !== null && tiempoTotalDia !== null && tiempoTotalDiaHorario !== null
      ) {
        setListTablaHoras()
      }
  }, [
    horaFin, horaInicio, horaInicioRegistro,
    horaFinRegistro, nombreDia, tiempoTotalDia, tiempoTotalDiaHorario
  ]);

  function setListTablaHoras() {
    const tablaHorasAux = []
    // Creo una variable para ver si la tablaHoras empieza la semana en lunes o en el domingo anterior
    let domingoAnteriorOLunes = 1

    // Si el lunes de esta semana es dobleDia y hoy es lunes entonces se muestra el domingo anterior, si no, no
    if (semana[0].horario.inicio !== "" && semana[0].horario.dobleDia === true && diaSemana() === 1) {
      domingoAnteriorOLunes = 0
    }

    for (let i = domingoAnteriorOLunes; i <= 7; i++) {
      tablaHorasAux[i] = {
        nombreDia: nombreDia[i], 
        horaInicio: horaInicio[i], 
        horaInicioRegistro: horaInicioRegistro[i], 
        horaFin: horaFin[i], 
        horaFinRegistro: horaFinRegistro[i], 
        tiempoTotalDia: tiempoTotalDia[i],
        tiempoTotalDiaHorario: tiempoTotalDiaHorario[i],
        registro: semana[i].registro,
        festivo: semana[i].festivo
      }
    }
    setTablaHoras(tablaHorasAux)
  }

  function setFinDiaHorario() {
    const horaFinAux = []
    for (let i = 0; i<= 7; i++) {
      if (semana[i].horario.fin === "") {
        horaFinAux[i] = ""
      } 
      else if (semana[i].horario.finTarde === "") {
        horaFinAux[i] = `${semana[i].horario.fin} - `; 
      } 
      else {
        horaFinAux[i] = `${semana[i].horario.finTarde} - `;
      } 
    }
    setHoraFin(horaFinAux)
  }

  function setNombreDiaSemana() {
    const nombreDiaAux = []
    for (let i = 0; i<= 7; i++) {
      nombreDiaAux[i] = `${WEEK_DAYS[i]} ${getDiaByFecha(semana[i].nombreDia)} ${getNombreMesByFecha(semana[i].nombreDia)}`
    }
    setNombreDia(nombreDiaAux)
  }

  function setInicioDiaHorario() {
    const horaInicioAux = []
    for (let i = 0; i<= 7; i++) {
      if (semana[i].horario.inicio === "") {
        horaInicioAux[i] = ""; 
      } 
      else {
        horaInicioAux[i] = `${semana[i].horario.inicio} - `
      } 
    }
    setHoraInicio(horaInicioAux)
  }

  function setInicioDia () {
    const horaInicioRegistroAux = []
    for (let i = 0; i<= 7; i++) {
      if (semana[i].registro === "" || semana[i].registro.trabajado === false || semana[i].registro.trabajado === null) {
        horaInicioRegistroAux[i] = ""
      } 
      else {
        horaInicioRegistroAux[i] = formatHour(semana[i].registro.inicio.toDate().getHours(), semana[i].registro.inicio.toDate().getMinutes())
      } 
    }
    setHoraInicioRegistro(horaInicioRegistroAux)
  }

  // Establecer el fin del dia por registro
  function setFinDia () {
    const horaFinRegistroAux = []
    for (let i = 0; i<= 7; i++) {
      if (semana[i].registro === "" || semana[i].registro.trabajado === false || semana[i].registro.trabajado === null || semana[i].registro.fin === undefined) {
        horaFinRegistroAux[i] = ""
      } 
      else {
        horaFinRegistroAux[i] = formatHour(semana[i].registro.fin.toDate().getHours(), semana[i].registro.fin.toDate().getMinutes())
      } 
    }
    setHoraFinRegistro(horaFinRegistroAux)
  }

  // Calcular en funcion de las pausas o no, el tiempo total del dia
  function setTiempoTotal() {
    const tiempoTotalDiaAux = []
    for (let i = 0; i<= 7; i++) {
      // if(semana[i].festivo === true) {
      //   tiempoTotalDiaAux[i] = "DIA FESTIVO"; 
      //   continue;
      // } 
      if(semana[i].registro === "" || semana[i].registro.trabajado === null) {
        tiempoTotalDiaAux[i] = ""
        continue; 
      } 
      if(semana[i].registro.trabajado === false) {
        tiempoTotalDiaAux[i] = semana[i].registro.motivoLibre; 
        continue;
      } 

      let pausas = "", totalSegundos = 0;
      if (semana[i].registro.pausas !== undefined && semana[i].registro.pausas !== null) {
        if (semana[i].registro !== "") {
          pausas = semana[i].registro.pausas.filter(pausa => pausa.tipo !== 2)
        }
        else {
          pausas = ""
        }
      } 

      if (pausas !== "" && pausas.length > 0 && semana[i].registro.fin !== undefined){
        for (let j = 0; j<pausas.length; j++) {
          if (pausas[j] !== undefined) {
            if (pausas[j].fin !== undefined){
              if (pausas.length === 1) { //solo si hay 1 pausa
                const segundosAux = pausas[0].inicio.seconds - semana[i].registro.inicio.seconds
                const segundosAux2 = semana[i].registro.fin.seconds - pausas[0].fin.seconds
                totalSegundos = segundosAux + segundosAux2

                tiempoTotalDiaAux[i] = formatSeconds(totalSegundos)
                continue;

                //console.log("una pausa")
              } else if (pausas.length === 2) { //hay 2 pausas
                const segundosAux = pausas[0].inicio.seconds - semana[i].registro.inicio.seconds
                const segundosAux2 = pausas[1].inicio.seconds - pausas[0].fin.seconds
                const segundosAux3 = semana[i].registro.fin.seconds - pausas[1].fin.seconds
                totalSegundos = segundosAux + segundosAux2 + segundosAux3

                tiempoTotalDiaAux[i] = formatSeconds(totalSegundos)
                continue;

                //console.log("2 pausas")
              } else { // hay mas de 2 pausas
                if (j === 0) { //primera pausa
                  const segundosAux = pausas[j].inicio.seconds - semana[i].registro.inicio.seconds
                  totalSegundos = totalSegundos + segundosAux
                  //console.log("tres pausas 1, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundos)
                } else if (j < pausas.length) { //pausas intermedias
                  const segundosAux = pausas[j].inicio.seconds - pausas[j-1].fin.seconds
                  totalSegundos = totalSegundos + segundosAux
                  //console.log("tres pausas 2, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundos)
                } 
                if(j === pausas.length-1) { //ultima pausa
                  const segundosAux = semana[i].registro.fin.seconds - pausas[j].fin.seconds
                  totalSegundos = totalSegundos + segundosAux
                  //console.log("tres pausas 3, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundos)
                  tiempoTotalDiaAux[i] = formatSeconds(totalSegundos)
                  continue;
                }
              }
            }
          }
        }
      }
      else if(semana[i].registro.fin !== undefined) {
        const totalSegundos = semana[i].registro.fin.seconds - semana[i].registro.inicio.seconds
        tiempoTotalDiaAux[i] = formatSeconds(totalSegundos)
        continue;
      }
      else {
        tiempoTotalDiaAux[i] = "DIA SIN COMPLETAR"
        continue;
      }
    }
    setTiempoTotalDia(tiempoTotalDiaAux)
  }

  function setTiempoTotalHorario() {
    const tiempoTotalDiaHorarioAux = []

    for (let i = 0; i<= 7; i++) {
      if (semana[i].horario.inicio === "") {
        tiempoTotalDiaHorarioAux[i] = "00:00"
        continue;
      }
      let minutosTotalesDiarios = 0
      if (semana[i].horario.dobleDia === true) {
        const arrIni = (semana[i].horario.inicio).split(":")
        const arrFin = (semana[i].horario.fin).split(":")
        const minutosInicio = parseInt(arrIni[0]) * 60 + parseInt(arrIni[1])
        const minutosFin = parseInt(arrFin[0]) * 60 + parseInt(arrFin[1])
        minutosTotalesDiarios = minutosFin + 1440 - minutosInicio
      }
      else if (semana[i].horario.inicioTarde !== "") {
        const arrIni = (semana[i].horario.inicio).split(":")
        const arrFin = (semana[i].horario.fin).split(":")
        const minutosInicio = parseInt(arrIni[0]) * 60 + parseInt(arrIni[1])
        const minutosFin = parseInt(arrFin[0]) * 60 + parseInt(arrFin[1])

        const arrIniTarde = (semana[i].horario.inicioTarde).split(":")
        const arrFinTarde = (semana[i].horario.finTarde).split(":")
        const minutosInicioTarde = parseInt(arrIniTarde[0]) * 60 + parseInt(arrIniTarde[1])
        const minutosFinTarde = parseInt(arrFinTarde[0]) * 60 + parseInt(arrFinTarde[1])

        minutosTotalesDiarios = (minutosFin - minutosInicio) + (minutosFinTarde - minutosInicioTarde)
      }
      else {
        const arrIni = (semana[i].horario.inicio).split(":")
        const arrFin = (semana[i].horario.fin).split(":")
        const minutosInicio = parseInt(arrIni[0]) * 60 + parseInt(arrIni[1])
        const minutosFin = parseInt(arrFin[0]) * 60 + parseInt(arrFin[1])
        minutosTotalesDiarios = minutosFin - minutosInicio
      }
      
      let horasDiarias = ""
      let minutosDiarios = ""
      
      if (minutosTotalesDiarios/60 < 10) {
        horasDiarias = `0${parseInt(minutosTotalesDiarios/60)}`
      }
      else {
        horasDiarias = `${parseInt(minutosTotalesDiarios/60)}`
      }
      if (minutosTotalesDiarios%60 < 10) {
        minutosDiarios = `0${parseInt(minutosTotalesDiarios%60)}`
      }
      else {
        minutosDiarios = `${parseInt(minutosTotalesDiarios%60)}`
      }
      tiempoTotalDiaHorarioAux[i] = `${horasDiarias}:${minutosDiarios}`
    }
    setTiempoTotalDiaHorario(tiempoTotalDiaHorarioAux)
  }

  function formatTiempoTotal(tiempoTotal, tiempoTotalHorario, registro, festivo) {
    // if (festivo === true) return "DIA FESTIVO"
    if (registro === "") return ""
    else if (registro.trabajado === null) return ""
    else if (registro.trabajado === false) return registro.motivoLibre
    else if (tiempoTotal === "DIA SIN COMPLETAR") return "DIA SIN COMPLETAR"
    else if (tiempoTotal === "") return ""
    else return `${tiempoTotal}/${tiempoTotalHorario}`
  }

  if (tablaHoras !== null) {
    return (
      <section className="hoursTableSection">
        <table>
          <thead>
            <tr>
              <th>*</th>
              <th>Inicio</th>
              <th>Pausa</th>
              <th>Fin</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {tablaHoras.map((diaSemana, i) => (
              <tr key={i}>
                <td>{diaSemana.nombreDia}</td>

                { PROJECT_APP === ECINNOVA && 
                  <td>{diaSemana.horaInicio}{diaSemana.horaInicioRegistro}</td>
                }
                { PROJECT_APP === INSERTATOLEDO && 
                  <td>{diaSemana.horaInicioRegistro}</td>
                }
                { PROJECT_APP === CARITASTOLEDO && 
                  <td>{diaSemana.horaInicio}{diaSemana.horaInicioRegistro}</td>
                }

                <td><ColocarPausas registro={diaSemana.registro}/></td>

                { PROJECT_APP === ECINNOVA && 
                  <td>{diaSemana.horaFin}{diaSemana.horaFinRegistro}</td>
                }
                { PROJECT_APP === INSERTATOLEDO && 
                  <td>{diaSemana.horaFinRegistro}</td> 
                }
                { PROJECT_APP === CARITASTOLEDO && 
                  <td>{diaSemana.horaFin}{diaSemana.horaFinRegistro}</td>
                }
                
                <td><div title={diaSemana.registro.comentarioDiaCompleto}>{formatTiempoTotal(diaSemana.tiempoTotalDia, diaSemana.tiempoTotalDiaHorario, diaSemana.registro, diaSemana.festivo)}</div></td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    );
  } else {
    return <TablaHorasLoading></TablaHorasLoading>
  }
  
}
