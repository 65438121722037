import { comprobarAlias } from "../../js/functions";

export default function DatosUsuarioActual({ user }) {

  const alias = comprobarAlias(user)
  
  return (
    <section>
      <div className="titulo2">Control horario</div>
      <div className="paddingy-8 text-bold">{user.name} {user.apellidos}{alias}</div>
      <div className="paddingy-8 pbottom-16">{user.email}</div>
    </section>
  );
}