import { useState, useEffect } from "react";
import { ModalsPausa } from "./modalPausa/ModalsPausa";
import { diaAnteriorSemana, diaSemana } from "../../js/functions";
import { ModalsCompletarDia } from "./modalCompletarDia/ModalsCompletarDia";
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO } from "./../../js/project"

/*
Estados de los botones
1: Iniciar
2: Pausar, Terminar
3: Reiniciar pausa
4: No hay botones
5: Botones terminar y pausa desactivados
*/

/**
 * Aqui en los botones se puede inicar la jornada siempre incluso aunque no esté en tu horario 
 * o aunque sea dia festivo, menos cuando se ha especificado en concreto que ese trabajador no
 * trabaja, ya sea por baja, vacaciones, lesion etc...
 */

export default function Botones({ user, semana, botonIniciar, botonFin, botonPausa, botonReiniciarPausa, botonDiaCompleto }) {
  const [state, setState] = useState(4);
  const [disableButton, setDisableButton] = useState(false)
  const [botonAccionDiaAnterior, setBotonAccionDiaAnterior] = useState(false)
  const diaActual = diaSemana()
  const diaAnterior = diaAnteriorSemana()

  const trabajado = setTrabajado()
  const existeRegistro = setExisteRegistro()
  // const diaFestivo = semana[diaActual].festivo

  useEffect(() => {
    manageButton();
  }, []);

  function setTrabajado() {
    if (semana[diaActual].registro !== "") {
      return semana[diaActual].registro.trabajado; 
    }
    return true
  }

  function setExisteRegistro() {
    if (semana[diaActual].registro !== "") {
      return true;
    } 
    else {
      return false;
    }
  }

  async function manageButton() {
    if (semana[diaAnterior].registro !== undefined && 
      semana[diaAnterior].registro.inicio !== undefined &&
      semana[diaAnterior].registro.fin === undefined &&
      semana[diaAnterior].registro.trabajado === true && 
      semana[diaAnterior].horario.dobleDia === true) { // comprueba los registros del dia anterior para seguir con el dia

      setBotonAccionDiaAnterior(true)

      if (semana[diaAnterior].registro.pausas === undefined || semana[diaAnterior].registro.pausas === null) {
        setState(2); // Pausar, terminar
        return;
      }
      if (semana[diaAnterior].registro.pausas[semana[diaAnterior].registro.pausas.length-1].fin === undefined) {
        setState(3); // Reiniciar Pausa
        return;
      }
      if (semana[diaAnterior].registro.pausas[semana[diaAnterior].registro.pausas.length-1].fin !== undefined) {
        setState(2); // Pausar, terminar
        return;
      }
    }
    else { // No se puede continuar con los registros del dia anterior

      setBotonAccionDiaAnterior(false)

      if (trabajado === false) {
        setState(4); // No hay botones
        return;
      }
      if (!existeRegistro) {
        setState(1); // Iniciar
        return;
      }
      if (semana[diaActual].registro.inicio === undefined) {
        setState(1); // Iniciar
        return;
      } 
      if (semana[diaActual].registro.fin !== undefined) {
        setState(4); // No hay botones
        return;
      } 
      if (semana[diaActual].registro.pausas === undefined || semana[diaActual].registro.pausas === null) {
        setState(2) // Pausar, terminar
        return;
      }
      if (semana[diaActual].registro.pausas[semana[diaActual].registro.pausas.length-1].fin === undefined) {
        setState(3) // Reiniciar Pausa
        return;
      } 
      if (semana[diaActual].registro.pausas[semana[diaActual].registro.pausas.length-1].fin !== undefined) {
        setState(2) // Pausar, terminar
        return;
      }
    } 
  }

  async function onClickIniciar() {
    setDisableButton(true)
    botonIniciar()
  }

  async function onClickFin() {
    setDisableButton(true)
    setState(5)
    botonFin(botonAccionDiaAnterior)
  }

  function disableButtonf() {
    setDisableButton(true)
  }

  async function onClickReiniciarPausa() {
    setDisableButton(true)
    botonReiniciarPausa(botonAccionDiaAnterior)
  }

  if (state === 1) { // && diaFestivo === false 
    return (
      <section className="buttonSection">
        <button disabled={disableButton} className="startButton" onClick={onClickIniciar}>Iniciar</button>
        
        { PROJECT_APP === ECINNOVA && 
          <ModalsCompletarDia trabajador={user} disableButtonf={disableButtonf} botonDiaCompleto={botonDiaCompleto} />
        }
        { PROJECT_APP === INSERTATOLEDO &&
          <>
            {/* Nada porque en insertatoledo no fichan dia completo */}
          </>
        }
        { PROJECT_APP === CARITASTOLEDO &&
          <>
            { semana[diaActual].horario.dobleDia !== true && 
              <ModalsCompletarDia trabajador={user} disableButtonf={disableButtonf} botonDiaCompleto={botonDiaCompleto} />
            }
          </>
        }
        
      </section>
    );
  }

  else if (state === 2) { // && diaFestivo === false 
    return (
      <section className="buttonSection">
        <ModalsPausa trabajador={user} disableButtonf={disableButtonf} botonPausa={botonPausa} botonAccionDiaAnterior={botonAccionDiaAnterior} />
        <button disabled={disableButton} className="finishButton" onClick={onClickFin}>Terminar</button>
      </section>
    );
  }

  else if (state === 3) { // && diaFestivo === false 
    return (
      <section className="buttonSection">
        <button disabled={disableButton} className="restartPausaButton" onClick={onClickReiniciarPausa}>Reiniciar Pausa</button>
      </section>
    );
  }

  else if (state === 4) { // || diaFestivo === true
    return <section className="buttonSection"> &nbsp; </section>
  }

  else if (state === 5) { // && diaFestivo === false
    return (
      <section className="buttonSection">
        <button disabled={true} className="pausaButton" onClick={onClickFin}>Pausa</button>
        <button disabled={true} className="finishButton" onClick={onClickFin}>Terminar</button>
      </section>
    );
  }
}
