import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserLocalPersistence,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useState, useEffect } from "react";
import { auth, getEmailByAlias, getUser, userExists } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import logo from "../img/logo.png"
import logo_caritas from "../img/logo_caritas.webp"
import { colorProject, PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO } from "./../js/project"

export function Login() {
  /*
  Stages:
  0: loading
  1: not logged
  2: reset password
  */

  const [state, setCurrentState] = useState(0);
  const [aliasOEmail, setAliasOEmail] = useState("");
  const [emailAux, setEmailAux] = useState("");
  const [password, setPassword] = useState("");
  const [mensajeDeAviso, setMensajeDeAviso] = useState("");
  const [recuerdame, setRecuerdame] = useState(true);
  const [errorLogin, setErrorLogin] = useState("");
  const [stateLogin, setStateLogin] = useState(false)
  const [stateCorreoEnviado, setStateCorreoEnviado] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentState(0);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const isRegistered = await userExists(user.uid);
        if (isRegistered) {
          const userDB = await getUser(user.uid)
          if (userDB.estado === false) { 
            setErrorLogin("Este usuario no está activo"); 
            logout() 
          }
          else if (userDB.primerAcceso === true) { 
            navigate("/changePassword")
          }
          else if (userDB.admin === false) {
            navigate("/controlHorario")
          } 
          else if (userDB.admin === true) {
            if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
              navigate("/admin")
            }
            if (PROJECT_APP === CARITASTOLEDO) {
              navigate("/controlHorario")
            }
          }
        }
        else {
          setCurrentState(1);
        }
      } else {
        setCurrentState(1);
        // console.log("No hay nadie autenticado ... ");
      }
    });
  }, [navigate]);

  async function singIn(e) {
    e.preventDefault();
    setStateLogin(true)

    let email = ""

    const respuestaFirebase = await getEmailByAlias(aliasOEmail)
    
    if (respuestaFirebase !== null) {
      email = respuestaFirebase.email
    }
    else {
      email = aliasOEmail
    }

    if (recuerdame === true) {
      setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            usuarioExiste(userCredential.user.uid)
            
          })
          .catch((error) => {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // console.log("error al entrar");
            setErrorLogin("Usuario o contraseña incorrectos")
            setStateLogin(false)
          });
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        setStateLogin(false)
      });
    } else {
      signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in
            usuarioExiste(userCredential.user.uid)
          })
          .catch((error) => {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // console.log("error al entrar");
            setErrorLogin("Usuario o contraseña incorrectos")
            setStateLogin(false)
          });
    }
  }

  function logout() {
    signOut(auth)
      .then(() => {
        
      })
      .catch((error) => {
        
      });

    //console.log("OUT");
    //auth().logout();
    //window.location.href = "login.html";
  }

  async function usuarioExiste(useruid) {
    const userDB = await getUser(useruid)
    if (userDB.estado === false) { 
      setErrorLogin("Este usuario no está activo"); 
      setStateLogin(false)
      logout() 
    }
    else if (userDB.primerAcceso === true) {
      navigate("/changePassword")
    } 
    else if (userDB.admin === false) {
      navigate("/controlHorario")
    } 
    else if (userDB.admin === true) {
      if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
        navigate("/admin")
      }
      if (PROJECT_APP === CARITASTOLEDO) {
        navigate("/controlHorario")
      }
    }
  }

  function resetPassword(e) {
    e.preventDefault();
    setStateCorreoEnviado(true)

    sendPasswordResetEmail(auth, emailAux)
    .then(() => {
        // console.log("EMAIL TO "+email)
        setMensajeDeAviso("Correo enviado")
        setEmailAux("")
    })
    .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // console.log(errorCode)
        // console.log(errorMessage)
        setMensajeDeAviso("Correo enviado")
        setEmailAux("")
    });
  }

  function onClickHeOlvidadoContraseña() {
    setCurrentState(2)
    setEmailAux("")
    setMensajeDeAviso("")
  }

  function onClickCancelar() {
    setAliasOEmail("")
    setPassword("")
    setErrorLogin("")
    setCurrentState(1)
  }

  function onChangeRecuerdame(e) {
    if (e.target.value === "true") setRecuerdame(false)
    else if (e.target.value === "false") setRecuerdame(true)
  }

  if (state === 2) {
    return (
      <div style={{backgroundColor: colorProject}} className="modal-login">
        <div className="modal-container-login">
          <div className="textcenter">
            { PROJECT_APP === ECINNOVA && 
              <img style={{aspectRatio: "191/140"}} src={logo} width="280px" alt="logo_empresa" />
            }
            { PROJECT_APP === INSERTATOLEDO && 
              <div className="colorred titulo2">INSERTA TOLEDO</div>
            }
            { PROJECT_APP === CARITASTOLEDO && 
              <img style={{aspectRatio: "261/193"}} src={logo_caritas} width="280px" alt="logo_empresa" />
            }
          </div>
          <form className="formReset" onSubmit={resetPassword}>
            <label htmlFor="resetEmail">Correo electronico</label>
            <input
              type="email"
              placeholder="Email"
              id="resetEmail"
              onChange={(e) => {
                setEmailAux(e.target.value);
                setErrorLogin("")
              }}
              value={emailAux}
              autoFocus
            />
            <p>{mensajeDeAviso}</p>

            <div className="buttonsBetween">
              <button onClick={onClickCancelar} className="acceptButton">Login</button>
              <button disabled={stateCorreoEnviado} type="submit" className="successButton">Confirmar</button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div style={{backgroundColor: colorProject}} className="modal-login">
      <div className="modal-container-login">
        <form className="formLogin" onSubmit={singIn}>
          <div className="textcenter">
            { PROJECT_APP === ECINNOVA && 
              <img style={{aspectRatio: "191/140"}} src={logo} width="280px" alt="logo_empresa" />
            }
            { PROJECT_APP === INSERTATOLEDO && 
              <div className="colorred titulo2">INSERTA TOLEDO</div>
            }
            { PROJECT_APP === CARITASTOLEDO && 
              <img style={{aspectRatio: "261/193"}} src={logo_caritas} width="280px" alt="logo_empresa" />
            }
          </div>
          <label htmlFor="email">Alias o correo electrónico</label>
          <input
            type="text"
            placeholder="Usuario"
            id="email"
            className="inputFormLogin"
            onChange={(e) => {
              setAliasOEmail(e.target.value);
              setErrorLogin("")
              //console.log(e.target.value);
            }}
            value={aliasOEmail}
            autoFocus
            required
          />

          <label htmlFor="password">Contraseña</label>
          <input
            type="password"
            id="password"
            className="inputFormLogin"
            placeholder="Contraseña"
            onChange={(e) => {
              setPassword(e.target.value);
              setErrorLogin("")
              //console.log(e.target.value);
            }}
            value={password}
            required
          />
          <p className="colorred">{errorLogin}</p>
          <label className="labelNotSignOut">
            <input type="checkbox" checked={recuerdame} value={recuerdame} onChange={onChangeRecuerdame}/>
            <span>No cerrar sesion</span>
          </label> 
          <div className="botonesLogin">
            { state === 0 &&
              <button disabled={true} className="successButton" type="submit">Login</button>
            }
            { state === 1 &&
              <button disabled={stateLogin} className="successButton" type="submit">Login</button>
            }  
            <button disabled={stateLogin} variant="transparent" className="transparentButton" type="button" onClick={onClickHeOlvidadoContraseña}>He olvidado la contraseña</button>
          </div>
        </form>
      </div>
    </div>
  );
}
