import React from "react"
import {
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail
} from "firebase/auth";
import { useState } from "react"
import { auth, auth2, registerNewUser, addAlias, aliasExists } from "../../../firebase/firebase"
import { ModalRegistrarTrabajador } from "./ModalRegistrarTrabajador"
import { useModalRegistrarTrabajador } from "./UseModalRegistrarTrabajador"
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO, empresa } from "./../../../js/project"
import { calcHorasSemanales } from "../../../js/functions";

export function ModalsRegistrarTrabajador({ centros, horariosPrestablecidos }) {
  const [isOpenModal, openModal, closeModal] = useModalRegistrarTrabajador(false)
  const [nombre, setNombre] = useState("")
  const [apellidos, setApellidos] = useState("")
  const [email, setEmail] = useState("")
  const [dni, setDNI] = useState("")
  const [stateHorarioPrestablecido, setStateHorarioPrestablecido] = useState(true)
  const [stateHorarioIndividual, setStateHorarioIndividual] = useState(false)
  const [horarioPre, setHorarioPre] = useState(null)
  const horarioVacio = [
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //DOMINGO     0
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //LUNES       1
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //MARTES      2
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //MIERCOLES   3
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //JUEVES      4
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //VIERNES     5
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //SABADO      6
  ]
  const [horarioIndividual, setHorarioIndividual] = useState(horarioVacio)
  const [tardeLunes, setTardeLunes] = useState(false)
  const [mañanaLunes, setMañanaLunes] = useState(true)
  const [dobleDiaLunes, setDobleDiaLunes] = useState(false)
  const [tardeMartes, setTardeMartes] = useState(false)
  const [mañanaMartes, setMañanaMartes] = useState(true)
  const [dobleDiaMartes, setDobleDiaMartes] = useState(false)
  const [tardeMiercoles, setTardeMiercoles] = useState(false)
  const [mañanaMiercoles, setMañanaMiercoles] = useState(true)
  const [dobleDiaMiercoles, setDobleDiaMiercoles] = useState(false)
  const [tardeJueves, setTardeJueves] = useState(false)
  const [mañanaJueves, setMañanaJueves] = useState(true)
  const [dobleDiaJueves, setDobleDiaJueves] = useState(false)
  const [tardeViernes, setTardeViernes] = useState(false)
  const [mañanaViernes, setMañanaViernes] = useState(true)
  const [dobleDiaViernes, setDobleDiaViernes] = useState(false)
  const [tardeSabado, setTardeSabado] = useState(false)
  const [mañanaSabado, setMañanaSabado] = useState(false)
  const [dobleDiaSabado, setDobleDiaSabado] = useState(false)
  const [tardeDomingo, setTardeDomingo] = useState(false)
  const [mañanaDomingo, setMañanaDomingo] = useState(false)
  const [dobleDiaDomingo, setDobleDiaDomingo] = useState(false)
  const [mensajeErrorHorarioIndividual, setMensajeErrorHorarioIndividual] = useState("")
  const [totalHorasSemanales, setTotalHorasSemanales] = useState("00:00")
  const [trabajadorExterno, setTrabajadorExterno] = useState(false)
  const [trabajadorActivo, setTrabajadorActivo] = useState(true)
  const [admin, setAdmin] = useState(false)
  const [correoFicticio, setCorreoFicticio] = useState(false)
  const [contraseña, setContraseña] = useState("")
  const [verContraseña, setVerContraseña] = useState(false)
  const [alias, setAlias] = useState("")
  const [centro, setCentro] = useState("")

  async function singUp() {
    //si el alias no está en blanco y existe en alias se lanza error y retorna la funcion para que no se ejecute
    if (alias !== "") {
      const existe = await aliasExists(alias)
      if (existe === true) {
        setMensajeErrorHorarioIndividual("El alias proporcionado ya está en uso, por favor usa otro")
        return;
      }
    }

    // correo ficticio es false por defecto (comentado el checkbox en el return para caritas)
    if (correoFicticio === true) {
      createUserWithEmailAndPassword(auth2, email, contraseña)
      .then((userCredential) => {
        addUserBBDD(userCredential.user);
      })
      .catch((error) => {
        // console.log(error.code)
        // console.log(error.message)
        // console.log("error al registrar");
        if (error.code === "auth/email-already-in-use") { 
          setMensajeErrorHorarioIndividual("El correo electrónico proporcionado ya está en uso, por favor usa otro"); 
          return;
        }
        setMensajeErrorHorarioIndividual("Error al registrar usuario")
      });
    }
    else {
      createUserWithEmailAndPassword(auth2, email, "1234567890")
      .then((userCredential) => {
        addUserBBDD(userCredential.user);
      })
      .catch((error) => {
        // console.log(error.code)
        // console.log(error.message)
        // console.log("error al registrar");
        // console.log(error.code);
        if (error.code === "auth/email-already-in-use") { 
            setMensajeErrorHorarioIndividual("El correo electrónico proporcionado ya está en uso, por favor usa otro"); 
            return; 
        }
        setMensajeErrorHorarioIndividual("Error al registrar usuario")
      });
    }
  }
    
  async function addUserBBDD(user) {
    //register user in bbdd
    if (stateHorarioIndividual === true) {
      const trabajador = {
        horarioPre: false,
        dias: horarioIndividual,
        externo: trabajadorExterno,
        estado: trabajadorActivo,
        name: nombre,
        apellidos: apellidos,
        dni: dni,
        email: email,
        admin: admin,
        empresa: empresa,
        centro: centro,
        alias: alias,
        correoFicticio: correoFicticio,
        uid: user.uid
      }
      await registerNewUser(trabajador);
    } 
    else {
      let horarioAux = ""
      if (horarioPre !== null) {
        horarioAux = horarioPre
      } 
      else {
        horarioAux = horariosPrestablecidos[0].id
      } 

      const trabajador = {
        horarioPre: true,
        horario: horarioAux,
        externo: trabajadorExterno,
        estado: trabajadorActivo,
        name: nombre,
        apellidos: apellidos,
        dni: dni,
        email: email,
        admin: admin,
        empresa: empresa,
        centro: centro,
        alias: alias,
        correoFicticio: correoFicticio,
        uid: user.uid
      }
      await registerNewUser(trabajador);
    }

    if (alias !== "") {
      const emailAlias = {
        email: email,
        alias: alias
      }
      await addAlias(emailAlias)
    }

    // reset password email
    // correo ficticio es false por defecto (comentado el checkbox en el return para caritas)
    if (correoFicticio === false) {
      sendPasswordResetEmail(auth, email)
      .then(() => {
      //   console.log("EMAIL TO "+email)
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // console.log(errorCode)
        // console.log(errorMessage)
      setMensajeErrorHorarioIndividual("Error al registrar usuario")
      });
    }
    
    //logout
    signOut(auth2)
    .then(() => {
      setMensajeErrorHorarioIndividual("")
      window.location.reload()
    })
    .catch((error) => {
      // console.error(error);
      // console.log("error logout");
      setMensajeErrorHorarioIndividual("Error al registrar usuario")
    });
  }

  function onChangeCorreoFicticio(e) {
    if (e.target.value === "true") {
      setCorreoFicticio(false)
    }
    if (e.target.value === "false") {
      setCorreoFicticio(true)
    }
  }

  function onChangeHorarioPrestablecido(e) {
    if (e.target.value === "false") {
      setStateHorarioPrestablecido(true)
      setStateHorarioIndividual(false)
    }
  }

  function onChangeHorarioIndividual(e) {
    if (e.target.value === "false") {
      setStateHorarioIndividual(true)
      setStateHorarioPrestablecido(false)
    }
  }

  async function onClickAceptar() {
    if (correoFicticio === true) {
      if (contraseña === "") {
        setMensajeErrorHorarioIndividual("La contraseña está en blanco")
        return;
      }
      if (contraseña.length < 6) {
        setMensajeErrorHorarioIndividual("La contraseña tiene que tener mínimo 6 caracteres")
        return;
      }
    }

    comprobarCheckboxes()

    if (stateHorarioIndividual === true) {
      for (let i = 0; i < horarioIndividual.length; i++) {
        const arrInicio = horarioIndividual[i].inicio.split(":")
        const arrFin = horarioIndividual[i].fin.split(":")
        if (parseInt(arrInicio[0]) < 24 && parseInt(arrInicio[0]) >= 0) {
        } else {
          if (i === 0 && mañanaDomingo === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Domingo (turno mañana)"); return "" }
          if (i === 1 && mañanaLunes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Lunes (turno mañana)"); return "" }
          if (i === 2 && mañanaMartes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Martes (turno mañana)"); return "" }
          if (i === 3 && mañanaMiercoles === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Miercoles (turno mañana)"); return "" }
          if (i === 4 && mañanaJueves === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Jueves (turno mañana)"); return "" }
          if (i === 5 && mañanaViernes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Viernes (turno mañana)"); return "" }
          if (i === 6 && mañanaSabado === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Sabado (turno mañana)"); return "" }
        }

        if (parseInt(arrInicio[1]) < 60 && parseInt(arrInicio[1]) >= 0) {
        } else {
          if (i === 0 && mañanaDomingo === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Domingo (turno mañana)"); return "" }
          if (i === 1 && mañanaLunes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Lunes (turno mañana)"); return "" }
          if (i === 2 && mañanaMartes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Martes (turno mañana)"); return "" }
          if (i === 3 && mañanaMiercoles === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Miercoles (turno mañana)"); return "" }
          if (i === 4 && mañanaJueves === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Jueves (turno mañana)"); return "" }
          if (i === 5 && mañanaViernes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Viernes (turno mañana)"); return "" }
          if (i === 6 && mañanaSabado === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Sabado (turno mañana)"); return "" }
        } 

        if (parseInt(arrFin[0]) < 24 && parseInt(arrFin[0]) >= 0) {
        } else {
          if (i === 0 && mañanaDomingo === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Domingo (turno mañana)"); return "" }
          if (i === 1 && mañanaLunes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Lunes (turno mañana)"); return "" }
          if (i === 2 && mañanaMartes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Martes (turno mañana)"); return "" }
          if (i === 3 && mañanaMiercoles === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Miercoles (turno mañana)"); return "" }
          if (i === 4 && mañanaJueves === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Jueves (turno mañana)"); return "" }
          if (i === 5 && mañanaViernes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Viernes (turno mañana)"); return "" }
          if (i === 6 && mañanaSabado === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Sabado (turno mañana)"); return "" }
        } 

        if (parseInt(arrFin[1]) < 60 && parseInt(arrInicio[1]) >= 0) {
        } else {
          if (i === 0 && mañanaDomingo === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Domingo (turno mañana)"); return "" }
          if (i === 1 && mañanaLunes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Lunes (turno mañana)"); return "" }
          if (i === 2 && mañanaMartes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Martes (turno mañana)"); return "" }
          if (i === 3 && mañanaMiercoles === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Miercoles (turno mañana)"); return "" }
          if (i === 4 && mañanaJueves === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Jueves (turno mañana)"); return "" }
          if (i === 5 && mañanaViernes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Viernes (turno mañana)"); return "" }
          if (i === 6 && mañanaSabado === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Sabado (turno mañana)"); return "" }
        }
      }
      for (let i = 0; i < horarioIndividual.length; i++) {
        const arrInicio = horarioIndividual[i].inicioTarde.split(":")
        const arrFin = horarioIndividual[i].finTarde.split(":")
        if (parseInt(arrInicio[0]) < 24 && parseInt(arrInicio[0]) >= 0) {
        } else {
          if (i === 0 && tardeDomingo === true && !dobleDiaDomingo) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Domingo (turno tarde)"); return "" }
          if (i === 1 && tardeLunes === true && !dobleDiaLunes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Lunes (turno tarde)"); return "" }
          if (i === 2 && tardeMartes === true && !dobleDiaMartes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Martes (turno tarde)"); return "" }
          if (i === 3 && tardeMiercoles === true && !dobleDiaMiercoles) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Miercoles (turno tarde)"); return "" }
          if (i === 4 && tardeJueves === true && !dobleDiaJueves) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Jueves (turno tarde)"); return "" }
          if (i === 5 && tardeViernes === true && !dobleDiaViernes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Viernes (turno tarde)"); return "" }
          if (i === 6 && tardeSabado === true && !dobleDiaSabado) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Sabado (turno tarde)"); return "" }
        }

        if (parseInt(arrInicio[1]) < 60 && parseInt(arrInicio[1]) >= 0) {
        } else {
          if (i === 0 && tardeDomingo === true && !dobleDiaDomingo) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Domingo (turno tarde)"); return "" }
          if (i === 1 && tardeLunes === true && !dobleDiaLunes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Lunes (turno tarde)"); return "" }
          if (i === 2 && tardeMartes === true && !dobleDiaMartes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Martes (turno tarde)"); return "" }
          if (i === 3 && tardeMiercoles === true && !dobleDiaMiercoles) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Miercoles (turno tarde)"); return "" }
          if (i === 4 && tardeJueves === true && !dobleDiaJueves) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Jueves (turno tarde)"); return "" }
          if (i === 5 && tardeViernes === true && !dobleDiaViernes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Viernes (turno tarde)"); return "" }
          if (i === 6 && tardeSabado === true && !dobleDiaSabado) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Sabado (turno tarde)"); return "" }
        } 

        if (parseInt(arrFin[0]) < 24 && parseInt(arrFin[0]) >= 0) {
        } else {
          if (i === 0 && tardeDomingo === true && !dobleDiaDomingo) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Domingo (turno tarde)"); return "" }
          if (i === 1 && tardeLunes === true && !dobleDiaLunes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Lunes (turno tarde)"); return "" }
          if (i === 2 && tardeMartes === true && !dobleDiaMartes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Martes (turno tarde)"); return "" }
          if (i === 3 && tardeMiercoles === true && !dobleDiaMiercoles) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Miercoles (turno tarde)"); return "" }
          if (i === 4 && tardeJueves === true && !dobleDiaJueves) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Jueves (turno tarde)"); return "" }
          if (i === 5 && tardeViernes === true && !dobleDiaViernes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Viernes (turno tarde)"); return "" }
          if (i === 6 && tardeSabado === true && !dobleDiaSabado) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Sabado (turno tarde)"); return "" }
        } 

        if (parseInt(arrFin[1]) < 60 && parseInt(arrInicio[1]) >= 0) {
        } else {
          if (i === 0 && tardeDomingo === true && !dobleDiaDomingo) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Domingo (turno tarde)"); return "" }
          if (i === 1 && tardeLunes === true && !dobleDiaLunes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Lunes (turno tarde)"); return "" }
          if (i === 2 && tardeMartes === true && !dobleDiaMartes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Martes (turno tarde)"); return "" }
          if (i === 3 && tardeMiercoles === true && !dobleDiaMiercoles) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Miercoles (turno tarde)"); return "" }
          if (i === 4 && tardeJueves === true && !dobleDiaJueves) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Jueves (turno tarde)"); return "" }
          if (i === 5 && tardeViernes === true && !dobleDiaViernes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Viernes (turno tarde)"); return "" }
          if (i === 6 && tardeSabado === true && !dobleDiaSabado) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Sabado (turno tarde)"); return "" }
        }
      }
      setMensajeErrorHorarioIndividual("")
      singUp()

    } else {
      singUp()
    }
  }

  function comprobarCheckboxes() {
    if (mañanaLunes === false) { horarioIndividual[1].inicio = ""; horarioIndividual[1].fin = ""; horarioIndividual[1].inicioTarde = ""; horarioIndividual[1].finTarde = "" }
    if (mañanaMartes === false) { horarioIndividual[2].inicio = ""; horarioIndividual[2].fin = ""; horarioIndividual[2].inicioTarde = ""; horarioIndividual[2].finTarde = "" }
    if (mañanaMiercoles === false) { horarioIndividual[3].inicio = ""; horarioIndividual[3].fin = ""; horarioIndividual[3].inicioTarde = ""; horarioIndividual[3].finTarde = "" }
    if (mañanaJueves === false) { horarioIndividual[4].inicio = ""; horarioIndividual[4].fin = ""; horarioIndividual[4].inicioTarde = ""; horarioIndividual[4].finTarde = "" }
    if (mañanaViernes === false) { horarioIndividual[5].inicio = ""; horarioIndividual[5].fin = ""; horarioIndividual[5].inicioTarde = ""; horarioIndividual[5].finTarde = "" }
    if (mañanaSabado === false) { horarioIndividual[6].inicio = ""; horarioIndividual[6].fin = ""; horarioIndividual[6].inicioTarde = ""; horarioIndividual[6].finTarde = "" }
    if (mañanaDomingo === false) { horarioIndividual[0].inicio = ""; horarioIndividual[0].fin = ""; horarioIndividual[0].inicioTarde = ""; horarioIndividual[0].finTarde = "" }

    if (tardeLunes === false || dobleDiaLunes === true) { horarioIndividual[1].inicioTarde = ""; horarioIndividual[1].finTarde = "" }
    if (tardeMartes === false || dobleDiaMartes === true) { horarioIndividual[2].inicioTarde = ""; horarioIndividual[2].finTarde = "" }
    if (tardeMiercoles === false || dobleDiaMiercoles === true) { horarioIndividual[3].inicioTarde = ""; horarioIndividual[3].finTarde = "" }
    if (tardeJueves === false || dobleDiaJueves === true) { horarioIndividual[4].inicioTarde = ""; horarioIndividual[4].finTarde = "" }
    if (tardeViernes === false || dobleDiaViernes === true) { horarioIndividual[5].inicioTarde = ""; horarioIndividual[5].finTarde = "" }
    if (tardeSabado === false || dobleDiaSabado === true) { horarioIndividual[6].inicioTarde = ""; horarioIndividual[6].finTarde = "" }
    if (tardeDomingo === false || dobleDiaDomingo === true) { horarioIndividual[0].inicioTarde = ""; horarioIndividual[0].finTarde = "" }
  }

  function onChangeMañanaLunes(e) {
    if (e.target.value === "true") { setMañanaLunes(false); setTardeLunes(false); setDobleDiaLunes(false); horarioIndividual[1].dobleDia = false }
    if (e.target.value === "false") { setMañanaLunes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaMartes(e) {
    if (e.target.value === "true") { setMañanaMartes(false); setTardeMartes(false); setDobleDiaMartes(false); horarioIndividual[2].dobleDia = false }
    if (e.target.value === "false") { setMañanaMartes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaMiercoles(e) {
    if (e.target.value === "true") { setMañanaMiercoles(false); setTardeMiercoles(false); setDobleDiaMiercoles(false); horarioIndividual[3].dobleDia = false }
    if (e.target.value === "false") { setMañanaMiercoles(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaJueves(e) {
    if (e.target.value === "true") { setMañanaJueves(false); setTardeJueves(false); setDobleDiaJueves(false); horarioIndividual[4].dobleDia = false }
    if (e.target.value === "false") { setMañanaJueves(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaViernes(e) {
    if (e.target.value === "true") {  setMañanaViernes(false); setTardeViernes(false); setDobleDiaViernes(false); horarioIndividual[5].dobleDia = false }
    if (e.target.value === "false") { setMañanaViernes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaSabado(e) {
    if (e.target.value === "true") { setMañanaSabado(false); setTardeSabado(false); setDobleDiaSabado(false); horarioIndividual[6].dobleDia = false }
    if (e.target.value === "false") { setMañanaSabado(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaDomingo(e) {
    if (e.target.value === "true") { setMañanaDomingo(false); setTardeDomingo(false); setDobleDiaDomingo(false); horarioIndividual[0].dobleDia = false }
    if (e.target.value === "false") { setMañanaDomingo(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeLunes(e) {
    if (e.target.value === "true") { setTardeLunes(false) }
    if (e.target.value === "false") { setTardeLunes(true); setMañanaLunes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeMartes(e) {
    if (e.target.value === "true") { setTardeMartes(false) }
    if (e.target.value === "false") { setTardeMartes(true); setMañanaMartes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeMiercoles(e) {
    if (e.target.value === "true") { setTardeMiercoles(false) }
    if (e.target.value === "false") { setTardeMiercoles(true); setMañanaMiercoles(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeJueves(e) {
    if (e.target.value === "true") { setTardeJueves(false) }
    if (e.target.value === "false") { setTardeJueves(true); setMañanaJueves(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeViernes(e) {
    if (e.target.value === "true") { setTardeViernes(false) }
    if (e.target.value === "false") { setTardeViernes(true); setMañanaViernes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeSabado(e) {
    if (e.target.value === "true") { setTardeSabado(false) }
    if (e.target.value === "false") { setTardeSabado(true); setMañanaSabado(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeDomingo(e) {
    if (e.target.value === "true") { setTardeDomingo(false) }
    if (e.target.value === "false") { setTardeDomingo(true); setMañanaDomingo(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeDobleDiaLunes(e) {
    if (e.target.value === "true") {
      setDobleDiaLunes(false)
      horarioIndividual[1].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaLunes(true); 
      horarioIndividual[1].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }
  
  function onChangeDobleDiaMartes(e) {
    if (e.target.value === "true") {
      setDobleDiaMartes(false); 
      horarioIndividual[2].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaMartes(true); 
      horarioIndividual[2].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }
  
  function onChangeDobleDiaMiercoles(e) {
    if (e.target.value === "true") {
      setDobleDiaMiercoles(false); 
      horarioIndividual[3].dobleDia = false  
    } 
    else if (e.target.value === "false") {
      setDobleDiaMiercoles(true); 
      horarioIndividual[3].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }
  
  function onChangeDobleDiaJueves(e) {
    if (e.target.value === "true") {
      setDobleDiaJueves(false); 
      horarioIndividual[4].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaJueves(true); 
      horarioIndividual[4].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }
  
  function onChangeDobleDiaViernes(e) {
    if (e.target.value === "true") {
      setDobleDiaViernes(false); 
      horarioIndividual[5].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaViernes(true); 
      horarioIndividual[5].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }
  
  function onChangeDobleDiaSabado(e) {
    if (e.target.value === "true") {
      setDobleDiaSabado(false); 
      horarioIndividual[6].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaSabado(true); 
      horarioIndividual[6].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }
  
  function onChangeDobleDiaDomingo(e) {
    if (e.target.value === "true") {
      setDobleDiaDomingo(false); 
      horarioIndividual[0].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaDomingo(true); 
      horarioIndividual[0].dobleDia = true
    }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTrabajadorActivo(e) {
    if (e.target.value === "true") setTrabajadorActivo(false)
    if (e.target.value === "false") setTrabajadorActivo(true)
  }

  function onChangeTrabajadorExterno(e) {
    if (e.target.value === "true") setTrabajadorExterno(false)
    if (e.target.value === "false") setTrabajadorExterno(true)
  }

  function onChangeAdmin(e) {
    if (e.target.value === "true") setAdmin(false)
    if (e.target.value === "false") setAdmin(true)
  }


  return (
    <>
      <button className="acceptButton" onClick={openModal}>Registrar Trabajador</button>
      <ModalRegistrarTrabajador isOpen={isOpenModal} closeModal={closeModal}>
        <div className="titulo2">Registrar nuevo trabajador</div>
        <hr />
        <span className="colorred">* Campos obligatorios</span>
        <div className="titulo3">Datos del trabajador</div>
        <table className="dataTable">
          <tbody>
            <tr>
              <td><label><span className="colorred">* </span>Correo electronico</label></td>
              <td><input type="text" defaultValue={email} onChange={(e) => {setEmail(e.target.value)}} /></td>
            </tr>
            <tr>
              <td><label>Nombre</label></td>
              <td><input type="text" defaultValue={nombre} onChange={(e) => {setNombre(e.target.value)}} /></td>
            </tr>
            <tr>
              <td><label>Apellidos</label></td>
              <td><input type="text" defaultValue={apellidos} onChange={(e) => {setApellidos(e.target.value)}} /></td>
            </tr>
            <tr>
              <td><label>Alias (iniciar sesion)</label></td>
              <td><input type="text" defaultValue={alias} onChange={(e) => {setAlias(e.target.value)}} /></td>
            </tr>
            <tr>
              <td><label>DNI</label></td>
              <td><input type="text" defaultValue={dni} onChange={(e) => {setDNI(e.target.value)}} /></td>
            </tr>
            { PROJECT_APP === ECINNOVA && 
              <></>
            }
            { PROJECT_APP === INSERTATOLEDO && 
              <></>
            }
            { PROJECT_APP === CARITASTOLEDO && 
              <tr>
                <td><label>Centro de trabajo</label></td>
                <td>
                  <select onChange={(e) => {setCentro(e.target.value)}}>
                    <option key="" value=""></option>
                    {centros.map((centro) => (
                      <option key={centro.name} value={centro.name}>{centro.name}</option>
                    ))}
                  </select>
                </td>
              </tr>
            }
          </tbody>
        </table>
        { (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) &&
          <div className="mtop-8">
            <label>
              <input type="checkbox" checked={correoFicticio} value={correoFicticio} onChange={onChangeCorreoFicticio}/> 
              &nbsp;Correo Ficticio
            </label>
            {correoFicticio === true && 
              <>
                <br />
                <div className="textbalance text-bold">
                  Al marcar esta casilla estás creando una cuenta con un correo ficticio, tienes que establecer una
                  contraseña de inicio de sesión ya que no se pueden enviar correos al correo ficticio porque no existe
                </div>
                
                <div>
                  <label className="passwordLabel">Contraseña:&nbsp;
                    {verContraseña === false && <input type="password" defaultValue={contraseña} onChange={(e) => {setContraseña(e.target.value)}} /> }
                    {verContraseña === true && <input type="text" defaultValue={contraseña} onChange={(e) => {setContraseña(e.target.value)}} /> }
                    <input type="checkbox" checked={verContraseña} value={verContraseña} onChange={(e) => {setVerContraseña(!verContraseña)}} />
                  </label>
                </div>
              </>
            }
            {correoFicticio === false &&
              <>
                <br />
                <div className="textbalance text-bold">
                  Al dejar esta casilla desmarcada se envía un correo al trabajador para que establezca una contraseña
                </div>
              </>
            }
          </div>
        }
        { PROJECT_APP === CARITASTOLEDO && 
            <>{/* Nada porque están desactivados los correos ficticios para caritas */}</> 
        }
        
        <hr />
        <div className="titulo3">Jornada del trabajador</div>
        <div className="paddingy-8">Selecciona horario individual o prestablecido</div>
        <label>
          <input type="checkbox" checked={stateHorarioPrestablecido} value={stateHorarioPrestablecido} onChange={onChangeHorarioPrestablecido}/>
          <span className="text-bold"> Horario Prestablecido</span>
        </label>
        <div>
          <select disabled={!stateHorarioPrestablecido} onChange={(e) => {setHorarioPre(e.target.value)}}>
          {horariosPrestablecidos.map((horario) => (
            <option key={horario.name} value={horario.id}>{horario.name}</option>
          ))}
          </select>
        </div>
        <br />
        <label>
          <input type="checkbox" checked={stateHorarioIndividual} value={stateHorarioIndividual} onChange={onChangeHorarioIndividual}/>
          <span className="text-bold"> Horario Individual</span>  
        </label>
        <div>Turno mañana/jornada continua</div>
        <table className="scheduleTable">
          <tbody>
            <tr>
              <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaLunes} value={mañanaLunes} onChange={onChangeMañanaLunes}/></td>
              <td>Lunes</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaLunes || !stateHorarioIndividual} defaultValue={horarioIndividual[1].inicio} onChange={(e) => {horarioIndividual[1].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaLunes || !stateHorarioIndividual} defaultValue={horarioIndividual[1].fin} onChange={(e) => {horarioIndividual[1].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
              <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaLunes} checked={dobleDiaLunes} value={dobleDiaLunes} onChange={onChangeDobleDiaLunes}/></td>
            </tr>
            <tr>
              <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaMartes} value={mañanaMartes} onChange={onChangeMañanaMartes}/></td>
              <td>Martes</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaMartes || !stateHorarioIndividual} defaultValue={horarioIndividual[2].inicio} onChange={(e) => {horarioIndividual[2].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaMartes || !stateHorarioIndividual} defaultValue={horarioIndividual[2].fin} onChange={(e) => {horarioIndividual[2].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
              <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaMartes} checked={dobleDiaMartes} value={dobleDiaMartes} onChange={onChangeDobleDiaMartes}/></td>
            </tr>
            <tr>
              <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaMiercoles} value={mañanaMiercoles} onChange={onChangeMañanaMiercoles}/></td>
              <td>Miercoles</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaMiercoles || !stateHorarioIndividual} defaultValue={horarioIndividual[3].inicio} onChange={(e) => {horarioIndividual[3].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaMiercoles || !stateHorarioIndividual} defaultValue={horarioIndividual[3].fin} onChange={(e) => {horarioIndividual[3].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
              <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaMiercoles} checked={dobleDiaMiercoles} value={dobleDiaMiercoles} onChange={onChangeDobleDiaMiercoles}/></td>
            </tr>
            <tr>
              <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaJueves} value={mañanaJueves} onChange={onChangeMañanaJueves}/></td>
              <td>Jueves</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaJueves || !stateHorarioIndividual} defaultValue={horarioIndividual[4].inicio} onChange={(e) => {horarioIndividual[4].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaJueves || !stateHorarioIndividual} defaultValue={horarioIndividual[4].fin} onChange={(e) => {horarioIndividual[4].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
              <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaJueves} checked={dobleDiaJueves} value={dobleDiaJueves} onChange={onChangeDobleDiaJueves}/></td>
            </tr>
            <tr>
              <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaViernes} value={mañanaViernes} onChange={onChangeMañanaViernes}/></td>
              <td>Viernes</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaViernes || !stateHorarioIndividual} defaultValue={horarioIndividual[5].inicio} onChange={(e) => {horarioIndividual[5].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaViernes || !stateHorarioIndividual} defaultValue={horarioIndividual[5].fin} onChange={(e) => {horarioIndividual[5].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
              <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaViernes} checked={dobleDiaViernes} value={dobleDiaViernes} onChange={onChangeDobleDiaViernes}/></td>
            </tr>
            <tr>
              <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaSabado} value={mañanaSabado} onChange={onChangeMañanaSabado}/></td>
              <td>Sabado</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaSabado || !stateHorarioIndividual} defaultValue={horarioIndividual[6].inicio} onChange={(e) => {horarioIndividual[6].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaSabado || !stateHorarioIndividual} defaultValue={horarioIndividual[6].fin} onChange={(e) => {horarioIndividual[6].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
              <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaSabado} checked={dobleDiaSabado} value={dobleDiaSabado} onChange={onChangeDobleDiaSabado}/></td>
            </tr>
            <tr>
              <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaDomingo} value={mañanaDomingo} onChange={onChangeMañanaDomingo}/></td>
              <td>Domingo</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaDomingo || !stateHorarioIndividual} defaultValue={horarioIndividual[0].inicio} onChange={(e) => {horarioIndividual[0].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!mañanaDomingo || !stateHorarioIndividual} defaultValue={horarioIndividual[0].fin} onChange={(e) => {horarioIndividual[0].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
              <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaDomingo} checked={dobleDiaDomingo} value={dobleDiaDomingo} onChange={onChangeDobleDiaDomingo}/></td>
            </tr>
          </tbody>
        </table>

        <div>Turno tarde</div>
        <table className="scheduleTable">
          <tbody>
            <tr>
              <td><input type="checkbox" disabled={dobleDiaLunes || !stateHorarioIndividual} checked={tardeLunes} value={tardeLunes} onChange={onChangeTardeLunes}/></td>
              <td>Lunes</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!tardeLunes || dobleDiaLunes || !stateHorarioIndividual} defaultValue={horarioIndividual[1].inicioTarde} onChange={(e) => {horarioIndividual[1].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!tardeLunes || dobleDiaLunes || !stateHorarioIndividual} defaultValue={horarioIndividual[1].finTarde} onChange={(e) => {horarioIndividual[1].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
            </tr>
            <tr>
              <td><input type="checkbox" disabled={dobleDiaMartes || !stateHorarioIndividual} checked={tardeMartes} value={tardeMartes} onChange={onChangeTardeMartes}/></td>
              <td>Martes</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!tardeMartes || dobleDiaMartes || !stateHorarioIndividual} defaultValue={horarioIndividual[2].inicioTarde} onChange={(e) => {horarioIndividual[2].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!tardeMartes || dobleDiaMartes || !stateHorarioIndividual} defaultValue={horarioIndividual[2].finTarde} onChange={(e) => {horarioIndividual[2].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
            </tr>
            <tr>
              <td><input type="checkbox" disabled={dobleDiaMiercoles || !stateHorarioIndividual} checked={tardeMiercoles} value={tardeMiercoles} onChange={onChangeTardeMiercoles}/></td>
              <td>Miercoles</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!tardeMiercoles || dobleDiaMiercoles || !stateHorarioIndividual} defaultValue={horarioIndividual[3].inicioTarde} onChange={(e) => {horarioIndividual[3].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!tardeMiercoles || dobleDiaMiercoles || !stateHorarioIndividual} defaultValue={horarioIndividual[3].finTarde} onChange={(e) => {horarioIndividual[3].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
            </tr>
            <tr>
              <td><input type="checkbox" disabled={dobleDiaJueves || !stateHorarioIndividual} checked={tardeJueves} value={tardeJueves} onChange={onChangeTardeJueves}/></td>
              <td>Jueves</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!tardeJueves || dobleDiaJueves || !stateHorarioIndividual} defaultValue={horarioIndividual[4].inicioTarde} onChange={(e) => {horarioIndividual[4].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!tardeJueves || dobleDiaJueves || !stateHorarioIndividual} defaultValue={horarioIndividual[4].finTarde} onChange={(e) => {horarioIndividual[4].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
            </tr>
            <tr>
              <td><input type="checkbox" disabled={dobleDiaViernes || !stateHorarioIndividual} checked={tardeViernes} value={tardeViernes} onChange={onChangeTardeViernes}/></td>
              <td>Viernes</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!tardeViernes || dobleDiaViernes || !stateHorarioIndividual} defaultValue={horarioIndividual[5].inicioTarde} onChange={(e) => {horarioIndividual[5].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!tardeViernes || dobleDiaViernes || !stateHorarioIndividual} defaultValue={horarioIndividual[5].finTarde} onChange={(e) => {horarioIndividual[5].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
            </tr>
            <tr>
              <td><input type="checkbox" disabled={dobleDiaSabado || !stateHorarioIndividual} checked={tardeSabado} value={tardeSabado} onChange={onChangeTardeSabado}/></td>
              <td>Sabado</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!tardeSabado || dobleDiaSabado || !stateHorarioIndividual} defaultValue={horarioIndividual[6].inicioTarde} onChange={(e) => {horarioIndividual[6].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!tardeSabado || dobleDiaSabado || !stateHorarioIndividual} defaultValue={horarioIndividual[6].finTarde} onChange={(e) => {horarioIndividual[6].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
            </tr>
            <tr>
              <td><input type="checkbox" disabled={dobleDiaDomingo || !stateHorarioIndividual} checked={tardeDomingo} value={tardeDomingo} onChange={onChangeTardeDomingo}/></td>
              <td>Domingo</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
              <td><input type="text" maxLength="5" disabled={!tardeDomingo || dobleDiaDomingo || !stateHorarioIndividual} defaultValue={horarioIndividual[0].inicioTarde} onChange={(e) => {horarioIndividual[0].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
              <td><input type="text" maxLength="5" disabled={!tardeDomingo || dobleDiaDomingo || !stateHorarioIndividual} defaultValue={horarioIndividual[0].finTarde} onChange={(e) => {horarioIndividual[0].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <div className="text-bold">Total Horas: {totalHorasSemanales}</div>
        <hr />
        <div className="titulo3">Otras opciones del trabajador</div>
        <div>
          <div><label><input type="checkbox" checked={trabajadorActivo} value={trabajadorActivo} onChange={onChangeTrabajadorActivo}/>&nbsp; Trabajador Activo</label></div>
          <div><label><input type="checkbox" checked={trabajadorExterno} value={trabajadorExterno} onChange={onChangeTrabajadorExterno}/>&nbsp; Trabajador Externo</label></div>
          <div><label><input type="checkbox" checked={admin} value={admin} onChange={onChangeAdmin}/>&nbsp; Usuario Administrador</label></div>
        </div>
        <br />
        <div className="textright">
          <span className="colorred">{mensajeErrorHorarioIndividual}</span>
          <button className="cancelButton" onClick={closeModal}>Cancelar</button>
          <button className="acceptButton" onClick={onClickAceptar}>Aceptar</button>
        </div>
      </ModalRegistrarTrabajador>
    </>
  )
}