import React from "react"
import { useState, useEffect } from "react"
import { updateRegistro, iniciarDia } from "../../../firebase/firebase"
import { ModalCambiarRegistros } from "./ModalCambiarRegistros"
import { useModalCambiarRegistros } from "./UseModalCambiarRegistros"
import { formatAñoMes, formatDate, formatFecha, formatHour, formatMayus, sumarDias } from "../../../js/functions"
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO } from "./../../../js/project"
import { BLANK, NO_WORK, REASONS_JUSTIFIED_PAUSE, REASONS_NOT_WORKING, TRABAJADOR_SOCIAL } from "../../../js/consts"

export const ModalsCambiarRegistros = ({registro, fechaDia, trabajador, onChangeRegistro, horario}) => {
  const [isOpenModal, openModal, closeModal] = useModalCambiarRegistros(false)
  const [arrPausas, setArrPausas] = useState([])
  const [stateCheckbox, setStateCheckbox] = useState(true)
  const [comentario, setComentario] = useState("")
  const [editarPausas, setEditarPausas] = useState(false)
  const [horasInicio, setHorasInicio] = useState("")
  const [minutosInicio, setMinutosInicio] = useState("")
  const [horasFin, setHorasFin] = useState("")
  const [minutosFin, setMinutosFin] = useState("")
  const [horasInicioPausa, setHorasInicioPausa] = useState("")
  const [minutosInicioPausa, setMinutosInicioPausa] = useState("")
  const [horasFinPausa, setHorasFinPausa] = useState("")
  const [minutosFinPausa, setMinutosFinPausa] = useState("")
  const [numeroPausa, setNumeroPausa] = useState(null)
  const [motivoLibre, setMotivoLibre] = useState(BLANK)
  const [pausaJornadaPartida, setPausaJornadaPartida] = useState(true)
  const [pausaPersonal, setPausaPersonal] = useState(false)
  const [pausaJustificada, setPausaJustificada] = useState(false)
  const [comentarioPausa, setComentarioPausa] = useState("")
  const [comentarioDiaCompleto, setComentarioDiaCompleto] = useState("")
  const [mensajeError, setMensajeError] = useState("")
  const [mensajeErrorPausa, setMensajeErrorPausa] = useState("")
  const [botonBorrarPausa, setBotonBorrarPausa] = useState(false)
  const [selectPausaJustificada, setSelectPausaJustificada] = useState(BLANK)
  const [dispositivoFichajeInicioPausa, setDispositivoFichajeInicioPausa] = useState(null)
  const [dispositivoFichajeFinPausa, setDispositivoFichajeFinPausa] = useState(null)

  const fechaDiaSiguiente = formatDate(sumarDias(new Date(fechaDia), +1))

  const [fechaSelectInicio, setFechaSelectInicio] = useState(fechaDia)
  const [fechaSelectFin, setFechaSelectFin] = useState(fechaDia)
  const [fechaSelectInicioPausa, setFechaSelectInicioPausa] = useState(fechaDia)
  const [fechaSelectFinPausa, setFechaSelectFinPausa] = useState(fechaDia)

  useEffect(() => {
    setPausas(registro)
    if(registro.trabajado !== undefined) {
      if (registro.trabajado === true) setStateCheckbox(registro.trabajado)
      else setStateCheckbox(false)
    } 
    else setStateCheckbox(true)

    if(registro.inicio !== undefined) setHorasInicio(registro.inicio.toDate().getHours())
    if(registro.inicio !== undefined) setMinutosInicio(registro.inicio.toDate().getMinutes())
    if(registro.fin !== undefined) setHorasFin(registro.fin.toDate().getHours())
    if(registro.fin !== undefined) setMinutosFin(registro.fin.toDate().getMinutes())
    if(registro.motivoLibre !== undefined) setMotivoLibre(registro.motivoLibre)
    if(registro.comentarioLibre !== undefined) setComentario(registro.comentarioLibre)
    if(registro.comentarioDiaCompleto !== undefined) setComentarioDiaCompleto(registro.comentarioDiaCompleto)

    if(registro.inicio !== undefined) setFechaSelectInicio(formatDate(registro.inicio.toDate()))
    if(registro.fin !== undefined) setFechaSelectFin(formatDate(registro.fin.toDate()))
  }, [])

  function setPausas(registro) {
    let pausas = "", pausasFinal = []
    if(registro.pausas !== undefined) pausas = registro !== "" ? registro.pausas : ""

    if (pausas !== "" && pausas !== null) {
      for (let i = 0; i<pausas.length; i++) {
        if (pausas[i] !== undefined) {
          if(pausas[i].inicio.seconds !== undefined) {
            if (pausas[i].fin !== undefined) {
              pausasFinal.push(formatHour(pausas[i].inicio.toDate().getHours(), pausas[i].inicio.toDate().getMinutes()) + " - " + formatHour(pausas[i].fin.toDate().getHours(), pausas[i].fin.toDate().getMinutes()))
            }
            else {
              pausasFinal.push(formatHour(pausas[i].inicio.toDate().getHours(), pausas[i].inicio.toDate().getMinutes()))
            }
          }
          else {
            if (pausas[i].fin !== undefined){
              pausasFinal.push(formatHour(pausas[i].inicio.getHours(), pausas[i].inicio.getMinutes()) + " - " + formatHour(pausas[i].fin.getHours(), pausas[i].fin.getMinutes()))
            }
            else {
              pausasFinal.push(formatHour(pausas[i].inicio.getHours(), pausas[i].inicio.getMinutes()))
            }
          }
        }
      }
    }
    setArrPausas(pausasFinal)
  }

  function onChangeCheckbox(e) {
    if (e.target.value === "true") {
      setStateCheckbox(false)
      if (registro !== "") {
        registro.trabajado = false
      }
    }
    if (e.target.value === "false") {
      setStateCheckbox(true)
      if (registro !== "") {
        registro.trabajado = true
      }
    }
    setMensajeError("")
  }

  function modificarPausa (e) {
    setBotonBorrarPausa(true)
    setEditarPausas(true)
    setMensajeErrorPausa("")
    // console.log(e.target.title)
    setNumeroPausa(e.target.title)
    if (registro.pausas[e.target.title].dispositivoInicio !== undefined) {
      setDispositivoFichajeInicioPausa(registro.pausas[e.target.title].dispositivoInicio)
    }
    else {
      setDispositivoFichajeInicioPausa(null)
    }
    if (registro.pausas[e.target.title].dispositivoFin !== undefined) {
      setDispositivoFichajeFinPausa(registro.pausas[e.target.title].dispositivoFin)
    }
    else {
      setDispositivoFichajeFinPausa(null)
    }
    if (registro.pausas[e.target.title].tipo === 2) {
      setSelectPausaJustificada(registro.pausas[e.target.title].tipoPausaJustificada)
    }
    else {
      setSelectPausaJustificada(BLANK)
    }
    if(registro.pausas[e.target.title].inicio.seconds !== undefined) {
      if (registro.pausas[e.target.title].inicio !== undefined) {
        setHorasInicioPausa(registro.pausas[e.target.title].inicio.toDate().getHours())
        setMinutosInicioPausa(registro.pausas[e.target.title].inicio.toDate().getMinutes())
        setFechaSelectInicioPausa(formatDate(registro.pausas[e.target.title].inicio.toDate()))
      }
      if (registro.pausas[e.target.title].fin !== undefined) {
        setHorasFinPausa(registro.pausas[e.target.title].fin.toDate().getHours())
        setMinutosFinPausa(registro.pausas[e.target.title].fin.toDate().getMinutes())
        setFechaSelectFinPausa(formatDate(registro.pausas[e.target.title].fin.toDate()))
      }
    }
    else {
      if (registro.pausas[e.target.title].inicio !== undefined) {
        setHorasInicioPausa(registro.pausas[e.target.title].inicio.getHours())
        setMinutosInicioPausa(registro.pausas[e.target.title].inicio.getMinutes())
        setFechaSelectInicioPausa(formatDate(registro.pausas[e.target.title].inicio))
      }
      if (registro.pausas[e.target.title].fin !== undefined) {
        setHorasFinPausa(registro.pausas[e.target.title].fin.getHours())
        setMinutosFinPausa(registro.pausas[e.target.title].fin.getMinutes())
        setFechaSelectFinPausa(formatDate(registro.pausas[e.target.title].fin))
      }
    }
    
    if (registro.pausas[e.target.title].tipo !== undefined && registro.pausas[e.target.title].tipo === 0) { 
      setPausaJornadaPartida(true); 
      setPausaPersonal(false);
      setPausaJustificada(false);
      setComentarioPausa("")
    }
    else if (registro.pausas[e.target.title].tipo !== undefined && registro.pausas[e.target.title].tipo === 1) { 
      setPausaJornadaPartida(false); 
      setPausaPersonal(true);
      setPausaJustificada(false);
      if (registro.pausas[e.target.title].comentarioPausa !== undefined) setComentarioPausa(registro.pausas[e.target.title].comentarioPausa)
      else if (registro.pausas[e.target.title].comentarioPausa !== undefined) setComentarioPausa("")
    }
    else if (registro.pausas[e.target.title].tipo !== undefined && registro.pausas[e.target.title].tipo === 2) { 
      setPausaJornadaPartida(false); 
      setPausaPersonal(false);
      setPausaJustificada(true);
      if (registro.pausas[e.target.title].comentarioPausa !== undefined) setComentarioPausa(registro.pausas[e.target.title].comentarioPausa)
      else if (registro.pausas[e.target.title].comentarioPausa !== undefined) setComentarioPausa("")
    }
  }

  function añadirPausa() {
    setBotonBorrarPausa(false)
    setEditarPausas(true)
    setNumeroPausa(null)
    setHorasInicioPausa("")
    setMinutosInicioPausa("")
    setHorasFinPausa("")
    setMinutosFinPausa("")
    setComentarioPausa("")
    setPausaJornadaPartida(true)
    setPausaPersonal(false)
    setPausaJustificada(false)
    setSelectPausaJustificada(BLANK)
    setMensajeErrorPausa("")
    setDispositivoFichajeInicioPausa(null)
    setDispositivoFichajeFinPausa(null)
    setFechaSelectInicioPausa(fechaDia)
    setFechaSelectFinPausa(fechaDia)
  }
    
  function confirmarPausa() {
    if (horasInicioPausa > -1 && horasInicioPausa < 24) {} else { setMensajeErrorPausa("Hora inicio incorrecta"); return "" }
    if (horasFinPausa > -1 && horasFinPausa < 24) {} else { setMensajeErrorPausa("Hora fin incorrecta"); return "" }
    if (minutosInicioPausa > -1 && minutosInicioPausa < 60) {} else { setMensajeErrorPausa("Minutos inicio incorrectos"); return "" }
    if (minutosFinPausa > -1 && minutosFinPausa < 60) {} else { setMensajeErrorPausa("Minutos fin incorrectos"); return "" }
    if (horasInicioPausa === "") { setMensajeErrorPausa("Escribe la hora de inicio"); return "" }
    if (minutosInicioPausa === "") { setMensajeErrorPausa("Escribe los minutos de inicio"); return "" }

    if (pausaJustificada === true) if (selectPausaJustificada === BLANK) { setMensajeErrorPausa("Selecciona motivo pausa justificada"); return "" }
    setMensajeErrorPausa("")

    if (horasFinPausa !== "" || minutosFinPausa !== "") {
      if (numeroPausa !== null) {
        registro.pausas[numeroPausa].inicio = new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00")

        if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) { /** No hace nada */ }
        else if (PROJECT_APP === CARITASTOLEDO) if (registro.pausas[numeroPausa].fin === undefined) registro.pausas[numeroPausa].dispositivoFin = "ORDENADOR"

        registro.pausas[numeroPausa].fin = new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00")
        if (pausaJornadaPartida === true) registro.pausas[numeroPausa].tipo = 0
        else if (pausaPersonal === true) { registro.pausas[numeroPausa].tipo = 1; registro.pausas[numeroPausa].comentarioPausa = comentarioPausa }
        else if (pausaJustificada === true) { registro.pausas[numeroPausa].tipo = 2; registro.pausas[numeroPausa].comentarioPausa = comentarioPausa; registro.pausas[numeroPausa].tipoPausaJustificada = selectPausaJustificada }
      }
      else {
        if(registro.pausas !== undefined) {
          if (registro.pausas === null) registro.pausas = []

          if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) { 
            if (pausaJornadaPartida === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), fin: new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00"), tipo: 0})
            else if (pausaPersonal === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), fin: new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00"), tipo: 1, comentarioPausa: comentarioPausa })
            else if (pausaJustificada === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), fin: new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00"), tipo: 2, comentarioPausa: comentarioPausa, tipoPausaJustificada: selectPausaJustificada })
          }
          else if (PROJECT_APP === CARITASTOLEDO) {
            if (pausaJornadaPartida === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), dispositivoInicio: "ORDENADOR", fin: new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00"), dispositivoFin: "ORDENADOR", tipo: 0})
            else if (pausaPersonal === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), dispositivoInicio: "ORDENADOR", fin: new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00"), dispositivoFin: "ORDENADOR", tipo: 1, comentarioPausa: comentarioPausa })
            else if (pausaJustificada === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), dispositivoInicio: "ORDENADOR", fin: new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00"), dispositivoFin: "ORDENADOR", tipo: 2, comentarioPausa: comentarioPausa, tipoPausaJustificada: selectPausaJustificada })
          }
        }
        else {
          registro.pausas = []

          if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
            if (pausaJornadaPartida === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), fin: new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00"), tipo: 0})
            else if (pausaPersonal === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), fin: new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00"), tipo: 1, comentarioPausa: comentarioPausa })
            else if (pausaJustificada === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), fin: new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00"), tipo: 2, comentarioPausa: comentarioPausa, tipoPausaJustificada: selectPausaJustificada })
          }
          else if (PROJECT_APP === CARITASTOLEDO) {
            if (pausaJornadaPartida === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), dispositivoInicio: "ORDENADOR", fin: new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00"), dispositivoFin: "ORDENADOR", tipo: 0})
            else if (pausaPersonal === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), dispositivoInicio: "ORDENADOR", fin: new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00"), dispositivoFin: "ORDENADOR", tipo: 1, comentarioPausa: comentarioPausa })
            else if (pausaJustificada === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), dispositivoInicio: "ORDENADOR", fin: new Date(formatFecha(fechaSelectFinPausa)+" "+horasFinPausa+":"+minutosFinPausa+":00"), dispositivoFin: "ORDENADOR", tipo: 2, comentarioPausa: comentarioPausa, tipoPausaJustificada: selectPausaJustificada })
          }
        }
      }
    }
    else {
      if (numeroPausa !== null) {
          registro.pausas[numeroPausa].inicio = new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00")
          if (pausaJornadaPartida === true) registro.pausas[numeroPausa].tipo = 0
          else if (pausaPersonal === true) { registro.pausas[numeroPausa].tipo = 1; registro.pausas[numeroPausa].comentarioPausa = comentarioPausa }
          else if (pausaJustificada === true) { registro.pausas[numeroPausa].tipo = 2; registro.pausas[numeroPausa].comentarioPausa = comentarioPausa; registro.pausas[numeroPausa].tipoPausaJustificada = selectPausaJustificada }
      }
      else {
        if(registro.pausas !== undefined) {
          if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
            if (pausaJornadaPartida === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), tipo: 0})
            else if (pausaPersonal === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), tipo: 1, comentarioPausa: comentarioPausa })
            else if (pausaJustificada === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), tipo: 2, comentarioPausa: comentarioPausa, tipoPausaJustificada: selectPausaJustificada })
          }
          else if (PROJECT_APP === CARITASTOLEDO) {
            if (pausaJornadaPartida === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), dispositivoInicio: "ORDENADOR", tipo: 0})
            else if (pausaPersonal === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), dispositivoInicio: "ORDENADOR", tipo: 1, comentarioPausa: comentarioPausa })
            else if (pausaJustificada === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), dispositivoInicio: "ORDENADOR", tipo: 2, comentarioPausa: comentarioPausa, tipoPausaJustificada: selectPausaJustificada })
          }
        }
        else {
          registro.pausas = []

          if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
            if (pausaJornadaPartida === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), tipo: 0})
            else if (pausaPersonal === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), tipo: 1, comentarioPausa: comentarioPausa })
            else if (pausaJustificada === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), tipo: 2, comentarioPausa: comentarioPausa, tipoPausaJustificada: selectPausaJustificada })
          }
          else if (PROJECT_APP === CARITASTOLEDO) {
            if (pausaJornadaPartida === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), dispositivoInicio: "ORDENADOR", tipo: 0})
            else if (pausaPersonal === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), dispositivoInicio: "ORDENADOR", tipo: 1, comentarioPausa: comentarioPausa })
            else if (pausaJustificada === true) registro.pausas.push({inicio: new Date(formatFecha(fechaSelectInicioPausa)+" "+horasInicioPausa+":"+minutosInicioPausa+":00"), dispositivoInicio: "ORDENADOR", tipo: 2, comentarioPausa: comentarioPausa, tipoPausaJustificada: selectPausaJustificada })
          }
        }
      }
    }

    setHorasInicioPausa("")
    setMinutosInicioPausa("")
    setHorasFinPausa("")
    setMinutosFinPausa("")
    setComentarioPausa("")
    setMensajeErrorPausa("")
    setPausaJornadaPartida(true)
    setPausaPersonal(false)
    setPausaJustificada(false)
    setDispositivoFichajeInicioPausa(null)
    setDispositivoFichajeFinPausa(null)

    setPausas(registro)

    setEditarPausas(false)
  }

  async function confirmarModal() {
    if (horasInicio > -1 && horasInicio < 24) {} else { setMensajeError("Hora inicio incorrecta"); return "" }
    if (horasFin > -1 && horasFin < 24) {} else { setMensajeError("Hora fin incorrecta"); return "" }
    if (minutosInicio > -1 && minutosInicio < 60) {} else { setMensajeError("Minutos inicio incorrectos"); return "" }
    if (minutosFin > -1 && minutosFin < 60) {} else { setMensajeError("Minutos fin incorrectos"); return "" }
    setMensajeError("")

    if (registro.inicio !== undefined) registro.inicio = new Date(formatFecha(fechaSelectInicio)+" "+horasInicio+":"+minutosInicio+":00")
    if (registro.fin !== undefined) registro.fin = new Date(formatFecha(fechaSelectFin)+" "+horasFin+":"+minutosFin+":00")

    let inicio = ""

    if (horasInicio !== "" && minutosInicio !== "") { inicio = new Date(formatFecha(fechaSelectInicio)+" "+horasInicio+":"+minutosInicio+":00"); }

    if (inicio === "") { setMensajeError("Escribe hora de inicio"); return "" }
    else {
      registro.trabajado = true
      registro.inicio = inicio
      if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
        // Nada porque no añado dispositivo
      }
      else if (PROJECT_APP === CARITASTOLEDO) {
        registro.dispositivoInicio = "ORDENADOR"
      }
    } 
    
    if (horasFin !== "" && minutosFin !== "") { 
      registro.fin = new Date(formatFecha(fechaSelectFin)+" "+horasFin+":"+minutosFin+":00")
      if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
        // Nada porque no añado dispositivo
      }
      else if (PROJECT_APP === CARITASTOLEDO) {
        registro.dispositivoFin = "ORDENADOR" 
      }
    }

    if (registro.motivoLibre !== undefined) registro.motivoLibre = ""
    if (registro.comentarioLibre !== undefined) registro.comentarioLibre = ""
    if (comentarioDiaCompleto !== "") registro.comentarioDiaCompleto = comentarioDiaCompleto
    
    await updateRegistro(trabajador, fechaDia, registro)
    // console.log(registro)

    onChangeRegistro()
    closeModal()
  }

  async function confirmarNoTrabajado() {
    if (motivoLibre === BLANK) {setMensajeError("Selecciona motivo"); return ""}
    else {
      registro.motivoLibre = motivoLibre
      registro.comentarioLibre = comentario
      registro.trabajado = false
      
      await updateRegistro(trabajador, fechaDia, registro)
      onChangeRegistro()
      closeModal() 
    }
  }

  async function crearRegistroNoTrabajado() {
    if (motivoLibre === BLANK) {setMensajeError("Selecciona motivo"); return ""}
    else {
      const añoMes = formatAñoMes(new Date(fechaDia));
      const año = new Date(fechaDia).getFullYear();

      const registroDB = {
        fecha: fechaDia,
        añomes: añoMes,
        año: `${año}`,
        comentarioLibre: comentario,
        motivoLibre: motivoLibre,
        trabajado: false
      }
  
      await iniciarDia(trabajador, registroDB, fechaDia)
      onChangeRegistro()
      closeModal()
    }
  }

  async function crearRegistroTrabajado() {
    const añoMes = formatAñoMes(new Date(fechaDia));
    const año = new Date(fechaDia).getFullYear();

    if (horasInicio > -1 && horasInicio < 24) {} else { setMensajeError("Hora inicio incorrecta"); return "" }
    if (horasFin > -1 && horasFin < 24) {} else { setMensajeError("Hora fin incorrecta"); return "" }
    if (minutosInicio > -1 && minutosInicio < 60) {} else { setMensajeError("Minutos inicio incorrectos"); return "" }
    if (minutosFin > -1 && minutosFin < 60) {} else { setMensajeError("Minutos fin incorrectos"); return "" }
    setMensajeError("")

    let inicio = "", fin = ""

    if (horasInicio !== "" && minutosInicio !== "") inicio = new Date(formatFecha(fechaSelectInicio)+" "+horasInicio+":"+minutosInicio+":00")
    if (horasFin !== "" && minutosFin !== "") fin = new Date(formatFecha(fechaSelectFin)+" "+horasFin+":"+minutosFin+":00")

    if (inicio === "") { setMensajeError("Escribe hora de inicio"); return "" }
    else if (fin !== "") {
      const registroDB = {
        fecha: fechaDia,
        añomes: añoMes,
        año: `${año}`,
        inicio: inicio,
        fin: fin,
        trabajado: true
      }
      if (PROJECT_APP === ECINNOVA && PROJECT_APP === INSERTATOLEDO) {
        // Nada porque no añado dispositivos
      }
      else if (PROJECT_APP === CARITASTOLEDO) {
        registroDB.dispositivoInicio = "ORDENADOR"
        registroDB.dispositivoFin = "ORDENADOR"
      }
      
      await iniciarDia(trabajador, registroDB, fechaDia);
      onChangeRegistro()
      closeModal()
    }
    else {
      const registroDB = {
        fecha: fechaDia,
        añomes: añoMes,
        año: `${año}`,
        inicio: inicio,
        dispositivoInicio: "ORDENADOR",
        trabajado: true
      }

      if (PROJECT_APP === ECINNOVA && PROJECT_APP === INSERTATOLEDO) {
        // Nada porque no añado dispositivos
      }
      else if (PROJECT_APP === CARITASTOLEDO) {
        registroDB.dispositivoInicio = "ORDENADOR"
      }
  
      await iniciarDia(trabajador, registroDB, fechaDia);
      onChangeRegistro()
      closeModal()
    }
  }

  function borrarPausa() {
    registro.pausas.splice(numeroPausa, 1)

    if (registro.pausas.length < 1) {
      registro.pausas = null
    }

    setBotonBorrarPausa(false)

    setHorasInicioPausa("")
    setMinutosInicioPausa("")
    setHorasFinPausa("")
    setMinutosFinPausa("")
    setComentarioPausa("")
    setPausaJornadaPartida(true)
    setDispositivoFichajeInicioPausa(null)
    setDispositivoFichajeFinPausa(null)
    
    setPausas(registro)

    setEditarPausas(false)
  }

  function onChangePausaJornadaPartida(e) {
    if (e.target.value === "false") {
    setPausaJornadaPartida(true);
    setPausaPersonal(false);
    setPausaJustificada(false);
    }
  }
    
  function onChangePausaPersonal(e) {
    if (e.target.value === "false") {
      setPausaJornadaPartida(false);
      setPausaPersonal(true);
      setPausaJustificada(false);
    }
  }
    
  function onChangePausaJustificada(e) {
    if (e.target.value === "false") {
      setPausaJornadaPartida(false);
      setPausaPersonal(false);
      setPausaJustificada(true);
    }
  }

  async function completarDia() {
    if (comentarioDiaCompleto === "") { setMensajeError("Comentario dia completo en blanco"); return "" }
    setMensajeError("")

    const diaRegistro = new Date(fechaDia).getDay()
    const añoMes = formatAñoMes(new Date(fechaDia));
    const año = new Date(fechaDia).getFullYear();

    const [horasInicioAux, minutosInicioAux] = horario[diaRegistro].inicio.split(":")
    setHorasInicio(horasInicioAux)
    setMinutosInicio(minutosInicioAux)

    if (horario[diaRegistro].finTarde === "") {
      const [horasFinAux, minutosFinAux] = horario[diaRegistro].fin.split(":")
      setHorasFin(horasFinAux)
      setMinutosFin(minutosFinAux)

      if (registro === "") {
        const registroDB = {
          fecha: fechaDia,
          añomes: añoMes,
          año: `${año}`,
          inicio: new Date(formatFecha(fechaDia)+" "+horasInicioAux+":"+minutosInicioAux+":00"),
          fin: new Date(formatFecha(fechaDia)+" "+horasFinAux+":"+minutosFinAux+":00"),
          trabajado: true,
          comentarioDiaCompleto: comentarioDiaCompleto
        }

        await iniciarDia(trabajador, registroDB, fechaDia);
      }
      else {
        registro.trabajado = true
        registro.fecha = fechaDia
        registro.inicio = new Date(formatFecha(fechaDia)+" "+horasInicioAux+":"+minutosInicioAux+":00")
        registro.fin = new Date(formatFecha(fechaDia)+" "+horasFinAux+":"+minutosFinAux+":00")
        registro.año = `${año}`
        registro.añomes = `${añoMes}`

        if (registro.motivoLibre !== undefined) registro.motivoLibre = ""
        if (registro.comentarioLibre !== undefined) registro.comentarioLibre = ""
        if (comentarioDiaCompleto !== "") registro.comentarioDiaCompleto = comentarioDiaCompleto

        await updateRegistro(trabajador, fechaDia, registro)
      }
    }
    else {
      const [horasFinAux, minutosFinAux] = horario[diaRegistro].finTarde.split(":")
      setHorasFin(horasFinAux)
      setMinutosFin(minutosFinAux)

      const [horasInicioPausaAux, minutosInicioPausaAux] = horario[diaRegistro].fin.split(":")

      const [horasFinPausaAux, minutosFinPausaAux] = horario[diaRegistro].inicioTarde.split(":")

      if (registro === "") {
        const registroDB = {
          fecha: fechaDia,
          añomes: añoMes,
          año: `${año}`,
          inicio: new Date(formatFecha(fechaDia)+" "+horasInicioAux+":"+minutosInicioAux+":00"),
          fin: new Date(formatFecha(fechaDia)+" "+horasFinAux+":"+minutosFinAux+":00"),
          pausas: [{inicio: new Date(formatFecha(fechaDia)+" "+horasInicioPausaAux+":"+minutosInicioPausaAux+":00"), fin: new Date(formatFecha(fechaDia)+" "+horasFinPausaAux+":"+minutosFinPausaAux+":00"), tipo: 0}],
          trabajado: true,
          comentarioDiaCompleto: comentarioDiaCompleto
        }
        await iniciarDia(trabajador, registroDB, fechaDia);
        setPausas(registroDB)
      }
      else {
        registro.trabajado = true
        registro.fecha = fechaDia
        registro.inicio = new Date(formatFecha(fechaDia)+" "+horasInicioAux+":"+minutosInicioAux+":00")
        registro.fin = new Date(formatFecha(fechaDia)+" "+horasFinAux+":"+minutosFinAux+":00")
        registro.pausas = [{inicio: new Date(formatFecha(fechaDia)+" "+horasInicioPausaAux+":"+minutosInicioPausaAux+":00"), fin: new Date(formatFecha(fechaDia)+" "+horasFinPausaAux+":"+minutosFinPausaAux+":00"), tipo: 0}]
        registro.año = `${año}`
        registro.añomes = `${añoMes}`

        if (registro.motivoLibre !== undefined) registro.motivoLibre = ""
        if (registro.comentarioLibre !== undefined) registro.comentarioLibre = ""
        if (comentarioDiaCompleto !== "") registro.comentarioDiaCompleto = comentarioDiaCompleto

        await updateRegistro(trabajador, fechaDia, registro)
        setPausas(registro)
      }
    }
    onChangeRegistro()
    closeModal()
  }

  if (registro !== undefined && registro === "" && stateCheckbox === true) {
    return (
      <>
        <button className="acceptButton" onClick={openModal}>Editar</button>
        <ModalCambiarRegistros isOpen={isOpenModal} closeModal={closeModal}>
          <article id="editRecords">
            <div className="titulo2">Registro de {trabajador.name} del {fechaDia}</div>
            <hr />
            <label className="marginbottom-8 dblock">
              <input type="checkbox" checked={stateCheckbox} defaultValue={stateCheckbox} onChange={onChangeCheckbox} />
              &nbsp;Dia trabajado
            </label>
            <div className="dInlineTextArea">
              <article>
                <div className="marginbottom-8">
                  Inicio&nbsp;&nbsp;
                  <input type="text" defaultValue={horasInicio} onChange={(e) => {setHorasInicio(e.target.value)}} />
                  &nbsp;:&nbsp;
                  <input type="text" defaultValue={minutosInicio} onChange={(e) => {setMinutosInicio(e.target.value)}} />
                  <div className="dinline paddingleft-8">
                    <select value={fechaSelectInicio} onChange={(e) => {setFechaSelectInicio(e.target.value)}}>
                      <option key={fechaDia} value={fechaDia}>{fechaDia}</option>
                      <option key={fechaDiaSiguiente} value={fechaDiaSiguiente}>{fechaDiaSiguiente}</option>
                    </select>
                  </div>
                  {registro.dispositivoInicio !== undefined &&
                    <div className="mleft-50"><sup>Fichado desde {registro.dispositivoInicio}</sup></div>
                  }
                </div>
                <div className="marginbottom-8">
                  Fin &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input type="text" defaultValue={horasFin} onChange={(e) => {setHorasFin(e.target.value)}} />
                  &nbsp;:&nbsp;
                  <input type="text" defaultValue={minutosFin} onChange={(e) => {setMinutosFin(e.target.value)}} />
                  <div className="dinline paddingleft-8">
                    <select value={fechaSelectFin} onChange={(e) => {setFechaSelectFin(e.target.value)}}>
                      <option key={fechaDia} value={fechaDia}>{fechaDia}</option>
                      <option key={fechaDiaSiguiente} value={fechaDiaSiguiente}>{fechaDiaSiguiente}</option>
                    </select>
                  </div>
                  {registro.dispositivoFin !== undefined &&
                    <div className="mleft-50"><sup>Fichado desde {registro.dispositivoFin}</sup></div>
                  }
                </div>
              </article>
              <article>
                { PROJECT_APP === ECINNOVA &&
                  <textarea value={comentarioDiaCompleto} onChange={(e) => {setComentarioDiaCompleto(e.target.value)}} placeholder="Motivo día completo" cols="30" rows="3" />
                }
                { (PROJECT_APP === INSERTATOLEDO || PROJECT_APP === CARITASTOLEDO) &&
                  <> {/* No hay nada ya que no existe el boton de dia completo en estos proyectos */} </>
                }
              </article>
            </div>
            <hr />
            {PROJECT_APP === ECINNOVA &&
              <div className="buttonsRecords">
                <button className="secondaryButton" onClick={completarDia}>Completar dia</button>
                <div>
                  <span className="colorred">{mensajeError}&nbsp;</span>
                  <button className="cancelButton" onClick={closeModal}>Cancelar</button>
                  <button className="acceptButton" onClick={crearRegistroTrabajado}>Crear Registro</button>
                </div>
              </div>
            }
            { (PROJECT_APP === INSERTATOLEDO || PROJECT_APP === CARITASTOLEDO) &&
              <div className="textright">
                <span className="colorred">{mensajeError}&nbsp;</span>
                <button className="cancelButton" onClick={closeModal}>Cancelar</button>
                <button className="acceptButton" onClick={crearRegistroTrabajado}>Crear Registro</button>
              </div>
            }
          </article>
        </ModalCambiarRegistros>
      </>
    );
  }

  if (registro !== undefined && stateCheckbox === false) {
    return (
      <>
        <button className="acceptButton" onClick={openModal}>Editar</button>
        <ModalCambiarRegistros isOpen={isOpenModal} closeModal={closeModal}>
          <article id="editRecords">
            <div className="titulo2">Registro de {trabajador.name} del {fechaDia}</div>
            <hr />
            <label className="marginbottom-8 dblock">
              <input type="checkbox" checked={stateCheckbox} defaultValue={stateCheckbox} onChange={onChangeCheckbox} />
              &nbsp;Dia trabajado
            </label>
            <div> 
              <label>
                <div className="marginbottom-8">Selecciona el motivo por el que no se ha trabajado ese dia:</div>
                <select className="marginbottom-8" value={motivoLibre} onChange={(e) => {setMotivoLibre(e.target.value)}}>
                  <option key={BLANK} value={BLANK}></option>
                  {REASONS_NOT_WORKING.map((reason) => (
                    <option key={reason} value={reason}>{formatMayus(reason)}</option>
                  ))}
                </select>
              </label>
              <br />
              <textarea placeholder="Comentarios:" value={comentario} cols="30" rows="3" onChange={(e) => {setComentario(e.target.value)}}></textarea>
            </div>
            <hr/>
            <div className="textright">
              <span className="colorred">{mensajeError}&nbsp;</span>
              <button className="cancelButton" onClick={closeModal}>Cancelar</button>
              {registro === "" && <button className="acceptButton" onClick={crearRegistroNoTrabajado}>Crear registro</button>}
              {registro !== "" && <button className="acceptButton" onClick={confirmarNoTrabajado}>Aceptar</button>}
            </div>
          </article>
        </ModalCambiarRegistros>
      </>
    )
  }

  if (registro !== undefined && stateCheckbox === true) {
    return (
      <>
        <button className="acceptButton" onClick={openModal}>Editar</button>
        <ModalCambiarRegistros isOpen={isOpenModal} closeModal={closeModal}>
          <article id="editRecords">
            <div className="titulo2">Registro de {trabajador.name} del {fechaDia}</div>
            <hr />
            <label className="marginbottom-8 dblock">
              <input type="checkbox" checked={stateCheckbox} defaultValue={stateCheckbox} onChange={onChangeCheckbox} />
              &nbsp;Dia trabajado
            </label>
            <div className="dInlineTextArea">
              <article>
                <div className="marginbottom-8">
                  Inicio&nbsp;&nbsp;
                  <input type="text" defaultValue={horasInicio} onChange={(e) => {setHorasInicio(e.target.value)}} />
                  &nbsp;:&nbsp;
                  <input type="text" defaultValue={minutosInicio} onChange={(e) => {setMinutosInicio(e.target.value)}} />
                  <div className="dinline paddingleft-8">
                    <select value={fechaSelectInicio} onChange={(e) => {setFechaSelectInicio(e.target.value)}}>
                      <option key={fechaDia} value={fechaDia}>{fechaDia}</option>
                      <option key={fechaDiaSiguiente} value={fechaDiaSiguiente}>{fechaDiaSiguiente}</option>
                    </select>
                  </div>
                  {registro.dispositivoInicio !== undefined &&
                    <div className="mleft-50"><sup>Fichado desde {registro.dispositivoInicio}</sup></div>
                  }
                </div>
                <div className="marginbottom-8">
                  Fin &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input type="text" defaultValue={horasFin} onChange={(e) => {setHorasFin(e.target.value)}} />
                  &nbsp;:&nbsp;
                  <input type="text" defaultValue={minutosFin} onChange={(e) => {setMinutosFin(e.target.value)}} />
                  <div className="dinline paddingleft-8">
                    <select value={fechaSelectFin} onChange={(e) => {setFechaSelectFin(e.target.value)}}>
                      <option key={fechaDia} value={fechaDia}>{fechaDia}</option>
                      <option key={fechaDiaSiguiente} value={fechaDiaSiguiente}>{fechaDiaSiguiente}</option>
                    </select>
                  </div>
                  {registro.dispositivoFin !== undefined &&
                    <div className="mleft-50"><sup>Fichado desde {registro.dispositivoFin}</sup></div>
                  }
                </div>
              </article>
              <article>
                { PROJECT_APP === ECINNOVA &&
                  <textarea value={comentarioDiaCompleto} onChange={(e) => {setComentarioDiaCompleto(e.target.value)}} placeholder="Motivo día completo" cols="30" rows="3" />
                }
                { (PROJECT_APP === INSERTATOLEDO || PROJECT_APP === CARITASTOLEDO) &&
                  <> {/* No hay nada ya que no existe el boton de dia completo en estos proyectos */} </>
                }
              </article>
            </div>
            <br />
            <div>
              <div className="text-bold">Pausas:</div>
              <div className="paddingy-8">
                {arrPausas.map((pausa, index) => (
                  <button className="transparentButton" title={index} key={index} onClick={modificarPausa}>{pausa}</button>
                ))}
              </div>
            </div>
            <div>
              <button className="acceptButton" onClick={añadirPausa}>Añadir Pausa</button>
            </div>
            <section className="flexPausas">
              <article className="margintop-8">
                <div className="marginbottom-8">
                  Inicio Pausa&nbsp;&nbsp;
                  <input type="text" disabled={!editarPausas} value={horasInicioPausa} onChange={(e) => {setHorasInicioPausa(e.target.value)}} />
                  &nbsp;:&nbsp;
                  <input type="text" disabled={!editarPausas} value={minutosInicioPausa} onChange={(e) => {setMinutosInicioPausa(e.target.value)}} />
                  <div className="dinline paddingleft-8">
                    <select value={fechaSelectInicioPausa} disabled={!editarPausas} onChange={(e) => {setFechaSelectInicioPausa(e.target.value)}}>
                      <option key={fechaDia} value={fechaDia}>{fechaDia}</option>
                      <option key={fechaDiaSiguiente} value={fechaDiaSiguiente}>{fechaDiaSiguiente}</option>
                    </select>
                  </div>
                  {dispositivoFichajeInicioPausa !== null &&
                    <div className="mleft-95"><sup>Fichado desde {dispositivoFichajeInicioPausa}</sup></div>
                  }
                  {dispositivoFichajeInicioPausa === null &&
                    <div><sup>&nbsp;</sup></div>
                  }
                </div>
                <div className="marginbottom-8">
                  Fin pausa &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input type="text" disabled={!editarPausas} value={horasFinPausa} onChange={(e) => {setHorasFinPausa(e.target.value)}} />
                  &nbsp;:&nbsp;
                  <input type="text" disabled={!editarPausas} value={minutosFinPausa} onChange={(e) => {setMinutosFinPausa(e.target.value)}} />
                  <div className="dinline paddingleft-8">
                    <select value={fechaSelectFinPausa} disabled={!editarPausas} onChange={(e) => {setFechaSelectFinPausa(e.target.value)}}>
                      <option key={fechaDia} value={fechaDia}>{fechaDia}</option>
                      <option key={fechaDiaSiguiente} value={fechaDiaSiguiente}>{fechaDiaSiguiente}</option>
                    </select>
                  </div>
                  {dispositivoFichajeFinPausa !== null &&
                    <div className="mleft-95"><sup>Fichado desde {dispositivoFichajeFinPausa}</sup></div>
                  }
                  {dispositivoFichajeFinPausa === null &&
                    <div><sup>&nbsp;</sup></div>
                  }
                </div>
              </article>

              <article>
                <span className="text-bold">&nbsp;Tipo pausa &nbsp;</span>
                <select disabled={!pausaJustificada} value={selectPausaJustificada} onChange={(e) => {setSelectPausaJustificada(e.target.value)}}>
                  <option key={BLANK} value={BLANK}></option>

                  { PROJECT_APP === ECINNOVA && 
                    <></>
                  }
                  { PROJECT_APP === INSERTATOLEDO && 
                    <option key={TRABAJADOR_SOCIAL} value={TRABAJADOR_SOCIAL}>{formatMayus(TRABAJADOR_SOCIAL)}</option>
                  }
                  { PROJECT_APP === CARITASTOLEDO && 
                    <></>
                  }

                  {REASONS_JUSTIFIED_PAUSE.map((reason) => (
                    <option key={reason} value={reason}>{formatMayus(reason)}</option>
                  ))}
                </select>
              </article>
            
              <article>
                <label>
                  <input type="checkbox" disabled={!editarPausas} checked={pausaJornadaPartida} value={pausaJornadaPartida} onChange={onChangePausaJornadaPartida} /> 
                  Pausa jornada partida 
                </label>
                <label>
                  <input type="checkbox" disabled={!editarPausas} checked={pausaPersonal} value={pausaPersonal} onChange={onChangePausaPersonal} /> 
                  Pausa personal
                </label>
                <label>
                  <input type="checkbox" disabled={!editarPausas} checked={pausaJustificada} value={pausaJustificada} onChange={onChangePausaJustificada} /> 
                  Pausa justificada
                </label>
              </article>
              
              <article>
                <textarea disabled={pausaJornadaPartida} value={comentarioPausa} onChange={(e) => {setComentarioPausa(e.target.value)}} placeholder="Mas informacion..." cols="30" rows="3" />
              </article>
            </section>
            
            <div className="pauseButtons">
              <button disabled={!botonBorrarPausa} className="cancelButton" onClick={borrarPausa}>Borrar pausa</button>
              <button disabled={!editarPausas} className="acceptButton" onClick={confirmarPausa}>Confirmar pausa</button>
              <span className="colorred">&nbsp;{mensajeErrorPausa}</span>
            </div>

            <hr />

            {PROJECT_APP === ECINNOVA &&
              <div className="buttonsRecords">
                <button className="secondaryButton" onClick={completarDia}>Completar dia</button>
                <div>
                  <span className="colorred">{mensajeError}&nbsp;</span>
                  <button className="cancelButton" onClick={closeModal}>Cancelar</button>
                  <button className="acceptButton" onClick={confirmarModal}>Aceptar</button> 
                </div>
              </div>
            }
            { (PROJECT_APP === INSERTATOLEDO || PROJECT_APP === CARITASTOLEDO) && 
              <div className="textright">
                <span className="colorred">{mensajeError}&nbsp;</span>
                <button className="cancelButton" onClick={closeModal}>Cancelar</button>
                <button className="acceptButton" onClick={confirmarModal}>Aceptar</button>
              </div>
            }
          </article>
        </ModalCambiarRegistros>
      </>
    )
  }
}