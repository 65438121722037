export const ModalPausa = ({children, isOpen, closeModal}) => {

  const handleModalContainerClick = e => e.stopPropagation()
  
  return (
    <article className={`modal-pausa ${isOpen && "is-open"}`} onClick={closeModal}>
        <div className="modal-container-pausa" onClick={handleModalContainerClick}>
            <button className="modal-close-pausa cancelButton" onClick={closeModal}>X</button>
            {children}
        </div>
    </article>
  )
}