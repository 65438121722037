import React from "react"
import { useState, useEffect } from "react";
import { ColocarPausas } from "./ColocarPausas";
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import { ColocarMotivoPausa } from "./ColocarMotivoPausa";
import { formatHour, formatSeconds, getDiaByFecha, getNombreDiaSemanaByFecha, getNombreMesByFecha } from "../../js/functions";
import { getHorario } from "../../firebase/firebase";

export function RegistroDiasExcel({ trabajador, registros, bRegistros}) {
  const [horario, setHorario] = useState(null)
  let totalSegundosRegistro = 0
  let totalSegundosHorario = 0

  useEffect(() => {
    if (trabajador !== null) verHorario()
  }, []);

  async function verHorario() {
    if (trabajador.horarioPre === true) {
        const horarioDB = await getHorario(trabajador.horario);
        if (horarioDB !== undefined) setHorario(horarioDB.dias);
    }
    else setHorario(trabajador.dias)
  }

  function inicioDia (registro) {
    if(registro.trabajado === false) return ""
    else if(registro !== "" && registro.inicio !== undefined) {
      if (registro.inicio.seconds !== undefined) return formatHour(registro.inicio.toDate().getHours(), registro.inicio.toDate().getMinutes())
      else return formatHour(registro.inicio.getHours(), registro.inicio.getMinutes())
    } 
    else return ""
  }

  function finDia (registro) {
    if(registro.trabajado === false) return ""
    if (registro.fin !== undefined) {
      if (registro !== "") {
        if (registro.inicio.seconds !== undefined) return formatHour(registro.fin.toDate().getHours(), registro.fin.toDate().getMinutes())
        else return formatHour(registro.fin.getHours(), registro.fin.getMinutes())
      } else {
        return ""
      }
    } else return ""
  }

  // Calcula todos los segundos del mes teniendo el cuenta el horario
  function setTiempoTotalMesHorario(registro, fecha, estado) {

    // Si el estado del dia es festivo y el registro está en blanco (o no ha llegado el dia 
    // o el trabajador no ha fichado ese dia) devuelve la funcion y no suma segundos
    if (estado === "FESTIVO" && registro === "") return;

    // Si el registro de ese día es "no trabajado" devuelve la funcion y no suma segundos
    if (registro !== "" && registro.trabajado === false) return;

    // Si el horario no tiene fecha de inicio devuelve la funcion y no suma segundos
    if (horario[new Date(fecha).getDay()].inicio === "") return;
    
    // Si el horario de ese dia es doble dia se suma 1 dia entero a los segundos de fin (1440 * 3600) para simular el dia siguiente
    if (horario[new Date(fecha).getDay()].dobleDia === true) {
      const arrIni = (horario[new Date(fecha).getDay()].inicio).split(":")
      const arrFin = (horario[new Date(fecha).getDay()].fin).split(":")
      const segundosInicio = parseInt(arrIni[0]) * 3600 + parseInt(arrIni[1]) * 60
      const segundosFin = parseInt(arrFin[0]) * 3600 + parseInt(arrFin[1]) * 60
      totalSegundosHorario = totalSegundosHorario + ((segundosFin + (1440 * 3600)) - segundosInicio)
    }

    // Si el horario tiene turno de tarde se ejecuta este if para calcular los segundos del dia (sin doble dias)
    else if (horario[new Date(fecha).getDay()].inicioTarde !== "") {
      const arrIni = (horario[new Date(fecha).getDay()].inicio).split(":")
      const arrFin = (horario[new Date(fecha).getDay()].fin).split(":")
      const segundosInicio = parseInt(arrIni[0]) * 3600 + parseInt(arrIni[1]) * 60
      const segundosFin = parseInt(arrFin[0]) * 3600 + parseInt(arrFin[1]) * 60

      const arrIniTarde = (horario[new Date(fecha).getDay()].inicioTarde).split(":")
      const arrFinTarde = (horario[new Date(fecha).getDay()].finTarde).split(":")
      const segundosInicioTarde = parseInt(arrIniTarde[0]) * 3600 + parseInt(arrIniTarde[1]) * 60
      const segundosFinTarde = parseInt(arrFinTarde[0]) * 3600 + parseInt(arrFinTarde[1]) * 60

      totalSegundosHorario = totalSegundosHorario + ((segundosFin - segundosInicio) + (segundosFinTarde - segundosInicioTarde))
    }

    // Si no tiene turno de tarde pues se ejecuta el else de solo el turno de mañana (sin doble dias)
    else {
      const arrIni = (horario[new Date(fecha).getDay()].inicio).split(":")
      const arrFin = (horario[new Date(fecha).getDay()].fin).split(":")
      const segundosInicio = parseInt(arrIni[0]) * 3600 + parseInt(arrIni[1]) * 60
      const segundosFin = parseInt(arrFin[0]) * 3600 + parseInt(arrFin[1]) * 60
      totalSegundosHorario = totalSegundosHorario + (segundosFin - segundosInicio)
    }
  }

  function setTiempoTotal(registro, fecha, estado) {
    if(registro.trabajado === false) {
      let comentarioFinal = registro.motivoLibre
      if (registro.comentarioLibre !== "") {
        comentarioFinal = `${registro.motivoLibre}: ${registro.comentarioLibre}`
      } 
      return comentarioFinal
    }
    if(registro === "" && estado === "FESTIVO") return "FESTIVO"
    if(registro.inicio === undefined) return ""

    let pausas = "", totalSegundos = 0, total = "DIA SIN COMPLETAR";

    if (registro.pausas !== undefined && registro.pausas !== null) {
      if (registro !== "") {
        pausas = registro.pausas.filter(pausa => pausa.tipo !== 2)
      }
      else {
        pausas = ""
      }
    } 

    if (pausas !== "" && pausas.length > 0 && registro.fin !== undefined){
      for (let i = 0; i<pausas.length; i++) {
        if (pausas[i] !== undefined) {
          if (pausas[i].fin !== undefined){
            if (pausas.length === 1) { //solo si hay 1 pausa
              const segundosAux = pausas[0].inicio.seconds - registro.inicio.seconds
              const segundosAux2 = registro.fin.seconds - pausas[0].fin.seconds
              totalSegundos = segundosAux + segundosAux2
              total = formatSeconds(totalSegundos)
              totalSegundosRegistro = totalSegundosRegistro + totalSegundos
              //console.log("una pausa")
            } else if (pausas.length === 2) { //hay 2 pausas
              if (i === 0) { //Solo se ejecute 1 vez y no 2 veces como pasaba al haber 2 pausas
                const segundosAux = pausas[0].inicio.seconds - registro.inicio.seconds
                const segundosAux2 = pausas[1].inicio.seconds - pausas[0].fin.seconds
                const segundosAux3 = registro.fin.seconds - pausas[1].fin.seconds
                totalSegundos = segundosAux + segundosAux2 + segundosAux3
                total = formatSeconds(totalSegundos)
                totalSegundosRegistro = totalSegundosRegistro + totalSegundos
              }
              //console.log("2 pausas")
            } else { // hay mas de 2 pausas
              if (i === 0) { //primera pausa
                const segundosAux = pausas[i].inicio.seconds - registro.inicio.seconds
                totalSegundos = totalSegundos + segundosAux
                //console.log("tres pausas 1, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundos)
              } else if (i < pausas.length) { //pausas intermedias
                const segundosAux = pausas[i].inicio.seconds - pausas[i-1].fin.seconds
                totalSegundos = totalSegundos + segundosAux
                //console.log("tres pausas 2, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundos)
              } 
              if(i === pausas.length-1) { //ultima pausa
                const segundosAux = registro.fin.seconds - pausas[i].fin.seconds
                totalSegundos = totalSegundos + segundosAux
                //console.log("tres pausas 3, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundos)
                total = formatSeconds(totalSegundos)
                totalSegundosRegistro = totalSegundosRegistro + totalSegundos
              }
            }
          }
        }
      }
    }
    else if (registro.fin !== undefined) {
      const totalSegundos = registro.fin.seconds - registro.inicio.seconds
      total = formatSeconds(totalSegundos)
      totalSegundosRegistro = totalSegundosRegistro + totalSegundos
    }
    else if (new Date(fecha).getDay() === 0 || new Date(fecha).getDay() === 6) {
      total = ""
    }
    if (registro === "" && total === "DIA SIN COMPLETAR") return ""
    return total
  }

  if (bRegistros === true) {
    return (
      <>
        <ReactHTMLTableToExcel table="tablaRegistros" filename={`${new Date(registros[0].fechaDia).getFullYear()}_${getNombreMesByFecha(registros[0].fechaDia)}_${trabajador.name}_${trabajador.apellidos}`} className="successButton" sheet="Hoja 1" buttonText="Descargar mes" />
        <table id="tablaRegistros" className="dnone">
            <thead>
              <tr>
                <th>{trabajador.name} {trabajador.apellidos}</th>
                <th>Inicio</th>
                <th>Pausa(s)</th>
                <th>Motivo Pausa</th>
                <th>Fin</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {registros.map((registro) => (
                <tr key={registro.fechaDia}>
                  <td>{getNombreDiaSemanaByFecha(registro.fechaDia)} {getDiaByFecha(registro.fechaDia)} {getNombreMesByFecha(registro.fechaDia)}</td>
                  <td>{inicioDia(registro.registro)}</td>
                  <td><ColocarPausas registro={registro.registro}/></td>
                  <td><ColocarMotivoPausa registro={registro.registro}/></td>
                  <td>{finDia(registro.registro)}</td>
                  <td>{setTiempoTotal(registro.registro, registro.fechaDia, registro.estado)}</td>
                  {setTiempoTotalMesHorario(registro.registro, registro.fechaDia, registro.estado)}
                </tr>
              ))}
              <tr>
                <td><strong>TOTAL HORAS {getNombreMesByFecha(registros[0].fechaDia)}</strong></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><strong>{formatSeconds(totalSegundosRegistro)} / {formatSeconds(totalSegundosHorario)}</strong></td>
              </tr>
            </tbody>
        </table>
      </>
    );
    } else return <></>
}

