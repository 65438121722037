import { useEffect } from "react";
import { auth, getUser } from "../firebase/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO } from "../js/project";

// redirigir a admin, login o trabajadores, refrescar pagina

export function Raiz() {
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (!user) navigate("/login")
      else {
        const userDB = await getUser(user.uid);

        if (userDB.estado === false) {
          logout()
        } 
        else if (userDB.primerAcceso === true) {
          navigate("/changePassword")
        } 
        else if (userDB.admin === false) {
          navigate("/controlHorario")
        } 
        else if (userDB.admin === true) {
          if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
            navigate("/admin")
          }
          if (PROJECT_APP === CARITASTOLEDO) {
            navigate("/controlHorario")
          }
        }
      }
    });
  }, [navigate]);

  function logout() {
    signOut(auth)
      .then(() => {
        // console.log("OUT");
      })
      .catch((error) => {
        // console.error(error);
        // console.log("error logout");
      });

    //console.log("OUT");
    //auth().logout();
    //window.location.href = "login.html";
  }

  return <>Cargando...</>
}
