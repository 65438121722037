// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import {
  getFirestore,
  collection,
  // addDoc,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { firebaseConfig, empresa } from "../js/project";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const auth2 = getAuth(initializeApp(firebaseConfig, "Secondary"));
export const db = getFirestore(app);
export const storage = getStorage(app);

export async function userExists(uid) {
  const docRef = doc(db, "users", uid);
  const res = await getDoc(docRef);
  // console.log(res);
  return res.exists();
}

export async function getUser(uid) {
  const docRef = doc(db, "users", uid);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.data();
}

export async function getHorario(id) {
  const docRef = doc(db, "horarios", id);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.data();
}

export async function registerNewUser(user) {
  try {
    const usersRef = collection(db, "users");
    await setDoc(doc(usersRef, user.uid), user);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}

export async function iniciarDia(user, registro, fecha) {
  try {
    const registros_horariosRef = collection(
      db,
      "registros_horarios/trabajadores/" + user.uid
    );
    await setDoc(doc(registros_horariosRef, fecha), registro);
  } catch (e) {
    // console.log(fecha);
    // console.error("Error adding document: ", e);
  }
}

export async function existeRegistro(user, fecha) {
  const docRef = doc(db, "registros_horarios/trabajadores/" + user.uid, fecha);
  const res = await getDoc(docRef);
  // console.log(res.exists())
  return res.exists();
}

export async function getRegistro(user, fecha) {
  const docRef = doc(db, "registros_horarios/trabajadores/" + user.uid, fecha);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.data();
}

export async function finDia(user, fecha, fin) {
  try {
    const res = await updateDoc(
      doc(db, "registros_horarios/trabajadores/" + user.uid, fecha),
      fin
    );
    // console.log(res);
  } catch (e) {
    // console.log("error al updatear fin");
    // console.error(e);
  }
}

export async function addPausa(user, fecha, registro) {
  try {
    const res = await updateDoc(
      doc(db, "registros_horarios/trabajadores/" + user.uid, fecha),
      registro
    );
    // console.log(res);
  } catch (e) {
    // console.log("error al updatear pausas");
    // console.error(e);
  }
}

export async function updateRegistro(user, fecha, registro) {
  try {
    const res = await updateDoc(
      doc(db, "registros_horarios/trabajadores/" + user.uid, fecha),
      registro
    );
    // console.log(res);
  } catch (e) {
    // console.log("error al updatear registros");
    // console.error(e);
  }
}

export async function updateTrabajador(user) {
  try {
    // console.log(user)
    const res = await updateDoc(
      doc(db, "users", user.uid),
      user
    );
    // console.log(res);
  } catch (e) {
    // console.log("error al updatear trabajadores");
    // console.error(e);
  }
}

export async function getTrabajadores() {
  const trabajadores = [];
  try {
    const q = query(collection(db, "users"), where("empresa", "==", empresa));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      trabajadores.push(doc.data())
    })

    return trabajadores;

  } catch (error) {
    // console.error(error)
  }
}

// , where("empresa", "==", empresa) --------------------------- TODOS A PARTIR DE AHORA 27/02/2024

// Antes
// , where("admin", "==", "true") ------------------------------ CONTROL HORARIO ECINNOVA
// , where("empresa", "==", "insertatoledo") ------------------- CONTROL HORARIO INSERTA TOLEDO
// , where("empresa", "==", "caritastoledo") ------------------- CONTROL HORARIO CARITAS TOLEDO

export async function getHorarios() {
  const horarios = [];
  try {
    const q = query(collection(db, "horarios"));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      horarios.push(doc.data())
    })

    return horarios;

  } catch (error) {
    // console.error(error)
  }
}

export async function getRegistrosPorAñoyMes(user, añomes) {
  const registros = [];
  try {
    const q = query(collection(db, "registros_horarios/trabajadores/" + user.uid), where("añomes", "==", añomes));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      registros.push(doc.data())
    })

    return registros;

  } catch (error) {
    // console.error(error)
  }
}

export async function getRegistrosPorAño(user, año) {
  const registros = [];
  try {
    const q = query(collection(db, "registros_horarios/trabajadores/" + user.uid), where("año", "==", año));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      registros.push(doc.data())
    })

    return registros;

  } catch (error) {
    // console.error(error)
  }
}

export async function getDiaFestivo(fechaDia) {
  const docRef = doc(db, "dias_festivos", fechaDia);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.data();
}

export async function existDiaFestivo(fechaDia) {
  const docRef = doc(db, "dias_festivos", fechaDia);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.exists();
}

export async function getDiasFestivosPorAñoyMes(añomes) {
  const diasFestivos = [];
  try {
    const q = query(collection(db, "dias_festivos"), where("añomes", "==", añomes));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      diasFestivos.push(doc.data())
    })

    return diasFestivos;

  } catch (error) {
    // console.error(error)
  }
}

export async function getDiasFestivosPorAño(año) {
  const diasFestivos = [];
  try {
    const q = query(collection(db, "dias_festivos"), where("año", "==", año));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      diasFestivos.push(doc.data())
    })

    return diasFestivos;

  } catch (error) {
    // console.error(error)
  }
}

export async function addHorario(horario) {
  try {
    // console.log(horario)
    const horariosRef = collection(db, "horarios");
    await setDoc(doc(horariosRef, horario.id), horario);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}

export async function addDiaFestivo(diaFestivo) {
  try {
    const diaFestivoRef = collection(db, "dias_festivos");
    await setDoc(doc(diaFestivoRef, diaFestivo.dia), diaFestivo);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}

export async function updateHorario(horario) {
  try {
    const res = await updateDoc(
    doc(db, "horarios", horario.id), horario);
    // console.log(res);
  } catch (e) {
    // console.log("error al updatear horario");
    // console.error(e);
  }
}

export async function quitarDiaFestivo(idDiaFestivo) {
  try {
    await deleteDoc(doc(db, "dias_festivos", idDiaFestivo));
  } catch (e) {
    // console.log("error al borrar");
    // console.error(e);
  }
}

export async function getEmailByAlias(alias) {
  try {
    const docRef = doc(db, "alias", alias);
    const res = await getDoc(docRef);
    // console.log(res)
    if (res.data() === undefined) return null
    else return res.data()
  } 
  catch (error) {
    // console.log(error);
  }
}

export async function aliasExists(alias) {
  const docRef = doc(db, "alias", alias);
  const res = await getDoc(docRef);
  // console.log(res);
  return res.exists();
}

export async function getAlias(alias) {
  const docRef = doc(db, "alias", alias);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.data();
}

export async function addAlias(emailAlias) {
  try {
    const usersRef = collection(db, "alias");
    await setDoc(doc(usersRef, emailAlias.alias), emailAlias);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}

export async function deleteAlias(alias) {
  // console.log(alias);
  try {
    await deleteDoc(doc(db, "alias", alias));
  } catch (e) {
    // console.log("error al borrar");
    // console.error(e);
  }
}

export async function getCentros() {
  const centros = [];
  try {
    const q = query(collection(db, "centros"));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      centros.push(doc.data())
    })

    return centros;

  } catch (error) {
    // console.error(error)
  }
}