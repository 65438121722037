import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  auth,
  getUser,
  getHorario,
  existeRegistro,
  getRegistro,
  existDiaFestivo,
  iniciarDia,
  finDia,
  addPausa,
} from "../../firebase/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { detectarMovil, diaAnteriorSemana, diaSemana, formatAñoMes, formatDate, sumarDias } from "../../js/functions";
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO } from "./../../js/project"

const ControlHorarioContext = React.createContext();

function ControlHorarioProvider({ children }) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [horario, setHorario] = useState(null);
  const [semana, setSemana] = useState(null);
  const [reload, setReload] = useState(false);

  const [registroLunes, setRegistroLunes] = useState(null);
  const [registroMartes, setRegistroMartes] = useState(null);
  const [registroMiercoles, setRegistroMiercoles] = useState(null);
  const [registroJueves, setRegistroJueves] = useState(null);
  const [registroViernes, setRegistroViernes] = useState(null);
  const [registroSabado, setRegistroSabado] = useState(null);
  const [registroDomingo, setRegistroDomingo] = useState(null);
  const [diaLunes, setDiaLunes] = useState(null);
  const [diaMartes, setDiaMartes] = useState(null);
  const [diaMiercoles, setDiaMiercoles] = useState(null);
  const [diaJueves, setDiaJueves] = useState(null);
  const [diaViernes, setDiaViernes] = useState(null);
  const [diaSabado, setDiaSabado] = useState(null);
  const [diaDomingo, setDiaDomingo] = useState(null);
  const [festivoLunes, setFestivoLunes] = useState(false);
  const [festivoMartes, setFestivoMartes] = useState(false);
  const [festivoMiercoles, setFestivoMiercoles] = useState(false);
  const [festivoJueves, setFestivoJueves] = useState(false);
  const [festivoViernes, setFestivoViernes] = useState(false);
  const [festivoSabado, setFestivoSabado] = useState(false);
  const [festivoDomingo, setFestivoDomingo] = useState(false);

  const [registroDomingoAnterior, setRegistroDomingoAnterior] = useState(null);
  const [diaDomingoAnterior, setDiaDomingoAnterior] = useState(null);
  const [festivoDomingoAnterior, setFestivoDomingoAnterior] = useState(false);

  let loading = true;

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (!user) navigate("/login");
      else {
        const userDB = await getUser(user.uid);
        
        if (userDB.estado === false) {
          logout();
        }
        else if (userDB.primerAcceso === true) {
          navigate("/changePassword")
        } 

        if (userDB.horarioPre) {
          const horarioDB = await getHorario(userDB.horario);
          setHorario(horarioDB.dias);
        } else {
          setHorario(userDB.dias);
        }

        definirSemana(userDB);
        setUser(userDB);
      }
    });
  }, [navigate]);

  useEffect(() => {
    if (
      diaLunes !== null && festivoLunes !== null && registroLunes !== null &&
      diaMartes !== null && festivoMartes !== null && registroMartes !== null &&
      diaMiercoles !== null && festivoMiercoles !== null && registroMiercoles !== null &&
      diaJueves !== null && festivoJueves !== null && registroJueves !== null &&
      diaViernes !== null && festivoViernes !== null && registroViernes !== null &&
      diaSabado !== null && festivoSabado !== null && registroSabado !== null &&
      diaDomingo !== null && festivoDomingo !== null && registroDomingo !== null &&
      diaDomingoAnterior !== null && festivoDomingoAnterior !== null && registroDomingoAnterior !== null
    ) {
      setDatosSemana();
    }
  }, [
    diaLunes, festivoLunes, registroLunes,
    diaMartes, festivoMartes, registroMartes,
    diaMiercoles, festivoMiercoles, registroMiercoles,
    diaJueves, festivoJueves, registroJueves,
    diaViernes, festivoViernes, registroViernes,
    diaSabado, festivoSabado, registroSabado,
    diaDomingo, festivoDomingo, registroDomingo,
    diaDomingoAnterior, festivoDomingoAnterior, registroDomingoAnterior
  ]);

  useEffect(() => {
    if (reload !== false && user !== null) {
      setTimeout(() => {
        setReload(false)
        definirSemana(user);
      }, 100);
    }
  }, [reload]);

  function logout() {
    signOut(auth)
      .then(() => {
        // console.log("OUT");
      })
      .catch((error) => {
        // console.error(error);
        // console.log("error logout");
      });
  }

  async function botonIniciar() {
    const fechaInicio = new Date();
    const fecha = formatDate(fechaInicio);
    const añoMes = formatAñoMes(fechaInicio);
    const año = fechaInicio.getFullYear();
    const registro = {
      fecha: fecha,
      inicio: fechaInicio,
      añomes: añoMes,
      año: `${año}`,
      trabajado: true,
    }

    if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
      // Nada porque no añado dispositivo
    }
    else if (PROJECT_APP === CARITASTOLEDO) {
      registro.dispositivoInicio = detectarMovil()
    }

    await iniciarDia(user, registro, fecha);
    
    setRegistroLunes(null)
    setRegistroMartes(null)
    setRegistroMiercoles(null)
    setRegistroJueves(null)
    setRegistroViernes(null)
    setRegistroSabado(null)
    setRegistroDomingo(null)
    setSemana(null)
    setReload(true)
    window.location.reload() // Hago un reload porque si no el intervalo de HorasDiaSemana no se para, (hacer mas pruebas)
  }

  async function botonFin(botonAccionDiaAnterior) {
    if (botonAccionDiaAnterior === false) {
      const fechaFin = new Date();
      const fin = { fin: fechaFin }

      if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
        // Nada porque no añado dispositivo
      }
      else if (PROJECT_APP === CARITASTOLEDO) {
        fin.dispositivoFin = detectarMovil()
      }

      const fecha = formatDate(new Date());

      await finDia(user, fecha, fin);
    }
    else {
      const fechaFin = new Date();
      const fin = { fin: fechaFin }

      if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
        // Nada porque no añado dispositivo
      }
      else if (PROJECT_APP === CARITASTOLEDO) {
        fin.dispositivoFin = detectarMovil()
      }

      const fecha = formatDate(sumarDias(new Date(), -1))

      await finDia(user, fecha, fin);
    }
    
    setRegistroLunes(null)
    setRegistroMartes(null)
    setRegistroMiercoles(null)
    setRegistroJueves(null)
    setRegistroViernes(null)
    setRegistroSabado(null)
    setRegistroDomingo(null)
    setSemana(null)
    setReload(true)
    window.location.reload() // Hago un reload porque si no el intervalo de HorasDiaSemana no se para, (hacer mas pruebas)
  }

  async function botonPausa(tipoPausa, tipoPausaJustificada, comentarioPausa, botonAccionDiaAnterior) {
    const fechaPausa = new Date() 
    let fecha, registro
    if (botonAccionDiaAnterior === false) {
      fecha = formatDate(new Date());
      registro = semana[diaSemana()].registro
    }
    else {
      fecha = formatDate(sumarDias(new Date(), -1))
      registro = semana[diaAnteriorSemana()].registro
    }
    
    if (tipoPausa === 0) {
      if (registro.pausas === undefined || registro.pausas === null) {
        const registro = {
          pausas: [
            {
              inicio: fechaPausa,
              tipo: 0,
            },
          ],
        };
        if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
          // Nada porque no añado dispositivo
        }
        else if (PROJECT_APP === CARITASTOLEDO) {
          registro.pausas[0].dispositivoInicio = detectarMovil()
        }
        await addPausa(user, fecha, registro);
      } 
      else {
        if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
          registro.pausas.push({ inicio: fechaPausa, tipo: 0 });
        }
        else if (PROJECT_APP === CARITASTOLEDO) {
          registro.pausas.push({ inicio: fechaPausa, dispositivoInicio: detectarMovil(), tipo: 0 });
        }
        
        await addPausa(user, fecha, registro);
      }
    } 
    else if (tipoPausa === 1) {
      if (registro.pausas === undefined || registro.pausas === null) {
        const registro = {
          pausas: [
            {
              inicio: fechaPausa,
              comentarioPausa: comentarioPausa,
              tipo: 1,
            },
          ],
        };
        if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
          // Nada porque no añado dispositivo
        }
        else if (PROJECT_APP === CARITASTOLEDO) {
          registro.pausas[0].dispositivoInicio = detectarMovil()
        }
        await addPausa(user, fecha, registro);
      } 
      else {
        if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
          registro.pausas.push({
            inicio: fechaPausa,
            comentarioPausa: comentarioPausa,
            tipo: 1,
          });
        }
        else if (PROJECT_APP === CARITASTOLEDO) {
          registro.pausas.push({
            inicio: fechaPausa,
            dispositivoInicio: detectarMovil(),
            comentarioPausa: comentarioPausa,
            tipo: 1,
          });
        }
        
        await addPausa(user, fecha, registro);
      }
    }
    else if (tipoPausa === 2) {
      if (registro.pausas === undefined || registro.pausas === null) {
        const registro = {
          pausas: [
            {
              inicio: fechaPausa,
              comentarioPausa: comentarioPausa,
              tipoPausaJustificada: tipoPausaJustificada,
              tipo: 2,
            },
          ],
        };
        if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
          // Nada porque no añado dispositivo
        }
        else if (PROJECT_APP === CARITASTOLEDO) {
          registro.pausas[0].dispositivoInicio = detectarMovil()
        }
        await addPausa(user, fecha, registro);
      } 
      else {
        if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
          registro.pausas.push({
            inicio: fechaPausa,
            comentarioPausa: comentarioPausa,
            tipoPausaJustificada: tipoPausaJustificada,
            tipo: 2,
          });
        }
        else if (PROJECT_APP === CARITASTOLEDO) {
          registro.pausas.push({
            inicio: fechaPausa,
            dispositivoInicio: detectarMovil(),
            comentarioPausa: comentarioPausa,
            tipoPausaJustificada: tipoPausaJustificada,
            tipo: 2,
          });
        }
        await addPausa(user, fecha, registro);
      }
    }

    setRegistroLunes(null)
    setRegistroMartes(null)
    setRegistroMiercoles(null)
    setRegistroJueves(null)
    setRegistroViernes(null)
    setRegistroSabado(null)
    setRegistroDomingo(null)
    setSemana(null)
    setReload(true)
    window.location.reload() // Hago un reload porque si no el intervalo de HorasDiaSemana no se para, (hacer mas pruebas)
  }

  async function botonDiaCompleto(comentarioDiaCompleto) {
    const diaHoy = diaSemana()
    const fecha = semana[diaHoy].nombreDia
    const inicio = new Date(`${semana[diaHoy].nombreDia} ${semana[diaHoy].horario.inicio}`)
    const añoMes = formatAñoMes(inicio)
    const año = inicio.getFullYear()

    if (semana[diaHoy].horario.inicioTarde === "") {
      const fin = new Date(`${semana[diaHoy].nombreDia} ${semana[diaHoy].horario.fin}`)

      const registro = {
        fecha: fecha,
        inicio: inicio,
        añomes: añoMes,
        año: `${año}`,
        trabajado: true,
        fin: fin,
        comentarioDiaCompleto: comentarioDiaCompleto
      }
      
      await iniciarDia(user, registro, fecha);  
    }
    else {
      const fin = new Date(`${semana[diaHoy].nombreDia} ${semana[diaHoy].horario.finTarde}`)
      const pausas = [
        {
          inicio: new Date(`${semana[diaHoy].nombreDia} ${semana[diaHoy].horario.fin}`),
          fin: new Date(`${semana[diaHoy].nombreDia} ${semana[diaHoy].horario.inicioTarde}`),
          tipo: 0,
        },
      ]

      const registro = {
        fecha: fecha,
        inicio: inicio,
        añomes: añoMes,
        año: `${año}`,
        trabajado: true,
        fin: fin,
        pausas: pausas,
        comentarioDiaCompleto: comentarioDiaCompleto
      }

      await iniciarDia(user, registro, fecha);
    }

    setRegistroLunes(null)
    setRegistroMartes(null)
    setRegistroMiercoles(null)
    setRegistroJueves(null)
    setRegistroViernes(null)
    setRegistroSabado(null)
    setRegistroDomingo(null)
    setSemana(null)
    setReload(true)
    window.location.reload() // Hago un reload porque si no el intervalo de HorasDiaSemana no se para, (hacer mas pruebas)
  }

  async function botonReiniciarPausa(botonAccionDiaAnterior) {
    const fechaPausa = new Date();
    let fecha, registro
    if (botonAccionDiaAnterior === false) {
      fecha = formatDate(new Date());
      registro = semana[diaSemana()].registro
    }
    else {
      fecha = formatDate(sumarDias(new Date(), -1))
      registro = semana[diaAnteriorSemana()].registro
    }

    registro.pausas[registro.pausas.length-1].fin = fechaPausa

    if (PROJECT_APP === ECINNOVA || PROJECT_APP === INSERTATOLEDO) {
      // Nada porque no añado dispositivo
    }
    else if (PROJECT_APP === CARITASTOLEDO) {
      registro.pausas[registro.pausas.length-1].dispositivoFin = detectarMovil()
    }
    
    await addPausa(user, fecha, registro);

    setRegistroLunes(null)
    setRegistroMartes(null)
    setRegistroMiercoles(null)
    setRegistroJueves(null)
    setRegistroViernes(null)
    setRegistroSabado(null)
    setRegistroDomingo(null)
    setSemana(null)
    setReload(true)
    window.location.reload() // Hago un reload porque si no el intervalo de HorasDiaSemana no se para, (hacer mas pruebas)
  }

  function definirSemana(userDB) {
    for (let i = -1; i < 7; i++) {
      let fecha = new Date();
      //Día de la semana 0-6
      let dia = fecha.getDay() - 1;
      if (dia === -1) dia = 6;
      //inicio Date() del inicio de la semana actual.
      let inicio = new Date(new Date().setDate(fecha.getDate() - (dia - i)));
      //inicio String() del inicio de la semana actual(YYYY-mm-dd).
      let fechaDia = formatDate(inicio);
      // var fecha_dia = inicio.getFullYear()+"_"+inicio.getMonth()+"_"+inicio.getDate();
      //console.log("SEMANA [i]-> i = " + i)
      //console.log("REGISTROS [fecha_dia]-> fecha_dia = " + fecha_dia)
      setDiasSemana(i, fechaDia, userDB);
    }
  }

  async function setDiasSemana(i, fechaDia, userDB) {
    if (i === -1) { // domingo anterior para doble dia
      setFestivoDomingoAnterior(await existDiaFestivo(fechaDia));
      if (await existeRegistro(userDB, fechaDia)) {
        setRegistroDomingoAnterior(await getRegistro(userDB, fechaDia));
      } else {
        setRegistroDomingoAnterior("");
      }
      setDiaDomingoAnterior(fechaDia);
    }
    if (i === 0) {
      setFestivoLunes(await existDiaFestivo(fechaDia));
      if (await existeRegistro(userDB, fechaDia)) {
        setRegistroLunes(await getRegistro(userDB, fechaDia));
      } else {
        setRegistroLunes("");
      }
      setDiaLunes(fechaDia);
    }
    if (i === 1) {
      setFestivoMartes(await existDiaFestivo(fechaDia));
      if ((await existeRegistro(userDB, fechaDia)) === true) {
        setRegistroMartes(await getRegistro(userDB, fechaDia));
      } else {
        setRegistroMartes("");
      }
      setDiaMartes(fechaDia);
    }
    if (i === 2) {
      setFestivoMiercoles(await existDiaFestivo(fechaDia));
      if ((await existeRegistro(userDB, fechaDia)) === true) {
        setRegistroMiercoles(await getRegistro(userDB, fechaDia));
      } else {
        setRegistroMiercoles("");
      }
      setDiaMiercoles(fechaDia);
    }
    if (i === 3) {
      setFestivoJueves(await existDiaFestivo(fechaDia));
      if ((await existeRegistro(userDB, fechaDia)) === true) {
        setRegistroJueves(await getRegistro(userDB, fechaDia));
      } else {
        setRegistroJueves("");
      }
      setDiaJueves(fechaDia);
    }
    if (i === 4) {
      setFestivoViernes(await existDiaFestivo(fechaDia));
      if ((await existeRegistro(userDB, fechaDia)) === true) {
        setRegistroViernes(await getRegistro(userDB, fechaDia));
      } else {
        setRegistroViernes("");
      }
      setDiaViernes(fechaDia);
    }
    if (i === 5) {
      setFestivoSabado(await existDiaFestivo(fechaDia));
      if ((await existeRegistro(userDB, fechaDia)) === true) {
        setRegistroSabado(await getRegistro(userDB, fechaDia));
      } else {
        setRegistroSabado("");
      }
      setDiaSabado(fechaDia);
    }
    if (i === 6) {
      setFestivoDomingo(await existDiaFestivo(fechaDia));
      if ((await existeRegistro(userDB, fechaDia)) === true) {
        setRegistroDomingo(await getRegistro(userDB, fechaDia));
      } else {
        setRegistroDomingo("");
      }
      setDiaDomingo(fechaDia);
    }
  }

  function setDatosSemana() {
    const semanaAux = [];

    semanaAux[0] = {
      nombreDia: diaDomingoAnterior,
      horario: horario[0],
      registro: registroDomingoAnterior,
      festivo: festivoDomingoAnterior,
    };
    semanaAux[1] = {
      nombreDia: diaLunes,
      horario: horario[1],
      registro: registroLunes,
      festivo: festivoLunes,
    };
    semanaAux[2] = {
      nombreDia: diaMartes,
      horario: horario[2],
      registro: registroMartes,
      festivo: festivoMartes,
    };
    semanaAux[3] = {
      nombreDia: diaMiercoles,
      horario: horario[3],
      registro: registroMiercoles,
      festivo: festivoMiercoles,
    };
    semanaAux[4] = {
      nombreDia: diaJueves,
      horario: horario[4],
      registro: registroJueves,
      festivo: festivoJueves,
    };
    semanaAux[5] = {
      nombreDia: diaViernes,
      horario: horario[5],
      registro: registroViernes,
      festivo: festivoViernes,
    };
    semanaAux[6] = {
      nombreDia: diaSabado,
      horario: horario[6],
      registro: registroSabado,
      festivo: festivoSabado,
    };
    semanaAux[7] = {
      nombreDia: diaDomingo,
      horario: horario[0],
      registro: registroDomingo,
      festivo: festivoDomingo,
    };

    // for (let i = 0; i <= 6; i++) {
    //   if (i !== 6) {
    //     semanaAux.push({nombreDia: nombreDiasSemana[i], horario: horario[i+1], registro: registroSemana[i], festivo: festivoSemana[i]})
    //   }
    //   else {
    //     semanaAux.push({nombreDia: nombreDiasSemana[i], horario: horario[0], registro: registroSemana[i], festivo: festivoSemana[i]})
    //   }
    // }

    setSemana(semanaAux);
  }

  if (
    user !== null &&
    semana !== null &&
    reload === false
  ) {
    loading = false;
  }

  if (
    reload === true
  ) {
    loading = true;
  }

  return (
    <ControlHorarioContext.Provider
      value={{
        loading,
        user,
        semana,
        botonIniciar,
        botonFin,
        botonPausa,
        botonReiniciarPausa,
        botonDiaCompleto
      }}
    >
      {children}
    </ControlHorarioContext.Provider>
  );
}

export { ControlHorarioContext, ControlHorarioProvider };
