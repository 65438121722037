import React from "react"
import logoblanco from "../../img/logo-blanco.svg"
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO, colorProject } from "./../../js/project"

export default function HeaderLoading() {
  return (
    <header>
      <nav style={{backgroundColor: colorProject}} className="navheader">
        { PROJECT_APP === ECINNOVA && 
          <>
            <a>
              <img src={logoblanco}
                width="50"
                height="50"
              />
            </a>
            <h1 className="colorwhite title-page">EC-INNOVA</h1>
          </>
        }
        { PROJECT_APP === INSERTATOLEDO && 
          <h1 className="colorwhite pm-0">INSERTA TOLEDO</h1>
        }
        { PROJECT_APP === CARITASTOLEDO && 
          <h1 className="colorwhite pm-0">CARITAS TOLEDO</h1>
        }
      </nav>
    </header>
  );
}
