import React from "react"
import { useState, useEffect } from "react";
import { getHorario } from "../../firebase/firebase";
import { LaboralesFestivos } from "./LaboralesFestivos";
import { Registro } from "./Registro";
import { formatSeconds } from "../../js/functions";

export function RegistroDiasAño({ trabajador, registros, bRegistros, admin, bajaVacaciones, motivoLibre, comentario }) {
  const [horario, setHorario] = useState(null)
  let totalSegundosRegistrosAño = 0
  let totalSegundosHorarioAño = 0

  useEffect(() => {
    if (trabajador !== null) verHorario()
  }, []);

  async function verHorario() {
    if (trabajador.horarioPre === true) {
        const horarioDB = await getHorario(trabajador.horario);
        if (horarioDB !== undefined) setHorario(horarioDB.dias);
    }
    else setHorario(trabajador.dias)
  }

  // Calcula todos los segundos del mes teniendo el cuenta el horario
  function setTiempoTotalMesHorario(registro, fecha, estado) {

    // Si el estado del dia es festivo y el registro está en blanco (o no ha llegado el dia 
    // o el trabajador no ha fichado ese dia) devuelve la funcion y no suma segundos
    if (estado === "FESTIVO" && registro === "") return;

    // Si el registro de ese día es "no trabajado" devuelve la funcion y no suma segundos
    if (registro !== "" && registro.trabajado === false) return;

    // Si el horario no tiene fecha de inicio devuelve la funcion y no suma segundos
    if (horario[new Date(fecha).getDay()].inicio === "") return;
    
    // Si el horario de ese dia es doble dia se suma 1 dia entero a los segundos de fin (1440 * 3600) para simular el dia siguiente
    if (horario[new Date(fecha).getDay()].dobleDia === true) {
      const arrIni = (horario[new Date(fecha).getDay()].inicio).split(":")
      const arrFin = (horario[new Date(fecha).getDay()].fin).split(":")
      const segundosInicio = parseInt(arrIni[0]) * 3600 + parseInt(arrIni[1]) * 60
      const segundosFin = parseInt(arrFin[0]) * 3600 + parseInt(arrFin[1]) * 60
      totalSegundosHorarioAño = totalSegundosHorarioAño + ((segundosFin + (1440 * 3600)) - segundosInicio)
    }

    // Si el horario tiene turno de tarde se ejecuta este if para calcular los segundos del dia (sin doble dias)
    else if (horario[new Date(fecha).getDay()].inicioTarde !== "") {
      const arrIni = (horario[new Date(fecha).getDay()].inicio).split(":")
      const arrFin = (horario[new Date(fecha).getDay()].fin).split(":")
      const segundosInicio = parseInt(arrIni[0]) * 3600 + parseInt(arrIni[1]) * 60
      const segundosFin = parseInt(arrFin[0]) * 3600 + parseInt(arrFin[1]) * 60

      const arrIniTarde = (horario[new Date(fecha).getDay()].inicioTarde).split(":")
      const arrFinTarde = (horario[new Date(fecha).getDay()].finTarde).split(":")
      const segundosInicioTarde = parseInt(arrIniTarde[0]) * 3600 + parseInt(arrIniTarde[1]) * 60
      const segundosFinTarde = parseInt(arrFinTarde[0]) * 3600 + parseInt(arrFinTarde[1]) * 60

      totalSegundosHorarioAño = totalSegundosHorarioAño + ((segundosFin - segundosInicio) + (segundosFinTarde - segundosInicioTarde))
    }

    // Si no tiene turno de tarde pues se ejecuta el else de solo el turno de mañana (sin doble dias)
    else {
      const arrIni = (horario[new Date(fecha).getDay()].inicio).split(":")
      const arrFin = (horario[new Date(fecha).getDay()].fin).split(":")
      const segundosInicio = parseInt(arrIni[0]) * 3600 + parseInt(arrIni[1]) * 60
      const segundosFin = parseInt(arrFin[0]) * 3600 + parseInt(arrFin[1]) * 60
      totalSegundosHorarioAño = totalSegundosHorarioAño + (segundosFin - segundosInicio)
    }
  }

  function setTiempoTotalAño(registro, fecha) {
    if(registro.trabajado === false) return ""
    if(registro.inicio === undefined) return ""

    let pausas = "", totalSegundos = 0, total = "DIA SIN COMPLETAR";

    if (registro.pausas !== undefined && registro.pausas !== null) {
      if (registro !== "") {
        pausas = registro.pausas.filter(pausa => pausa.tipo !== 2)
      }
      else {
        pausas = ""
      }
    } 

    if (pausas !== "" && pausas.length > 0 && registro.fin !== undefined){
      for (let i = 0; i<pausas.length; i++) {
        if (pausas[i] !== undefined) {
          if (pausas[i].fin !== undefined) {
            if (pausas.length === 1) { //solo si hay 1 pausa
              const segundosAux = pausas[0].inicio.seconds - registro.inicio.seconds
              const segundosAux2 = registro.fin.seconds - pausas[0].fin.seconds
              totalSegundos = segundosAux + segundosAux2
              total = formatSeconds(totalSegundos)
              totalSegundosRegistrosAño = totalSegundosRegistrosAño + totalSegundos
              //console.log("una pausa")
            } else if (pausas.length === 2) { //hay 2 pausas
              if (i === 0) { //Solo se ejecute 1 vez y no 2 veces como pasaba al haber 2 pausas
                const segundosAux = pausas[0].inicio.seconds - registro.inicio.seconds
                const segundosAux2 = pausas[1].inicio.seconds - pausas[0].fin.seconds
                const segundosAux3 = registro.fin.seconds - pausas[1].fin.seconds
                totalSegundos = segundosAux + segundosAux2 + segundosAux3
                total = formatSeconds(totalSegundos)
                totalSegundosRegistrosAño = totalSegundosRegistrosAño + totalSegundos
              }
              //console.log("2 pausas")
            } else { // hay mas de 2 pausas
              if (i === 0) { //primera pausa
                const segundosAux = pausas[i].inicio.seconds - registro.inicio.seconds
                totalSegundos = totalSegundos + segundosAux
                //console.log("tres pausas 1, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundos)
              } else if (i < pausas.length) { //pausas intermedias
                const segundosAux = pausas[i].inicio.seconds - pausas[i-1].fin.seconds
                totalSegundos = totalSegundos + segundosAux
                //console.log("tres pausas 2, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundos)
              } 
              if(i === pausas.length-1) { //ultima pausa
                const segundosAux = registro.fin.seconds - pausas[i].fin.seconds
                totalSegundos = totalSegundos + segundosAux
                //console.log("tres pausas 3, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundos)
                total = formatSeconds(totalSegundos)
                totalSegundosRegistrosAño = totalSegundosRegistrosAño + totalSegundos
              }
            }
          }
        }
      }
    }
    else if (registro.fin !== undefined) {
      const totalSegundos = registro.fin.seconds - registro.inicio.seconds
      total = formatSeconds(totalSegundos)
      totalSegundosRegistrosAño = totalSegundosRegistrosAño + totalSegundos
    }
    else if (new Date(fecha).getDay() === 0 || new Date(fecha).getDay() === 6) {
      total = ""
    }
    if (registro === "" && total === "DIA SIN COMPLETAR") return ""
    return ""
  }

  if (trabajador !== null && bRegistros === true && bajaVacaciones === false && horario !== null) {
    return (
      <table>
        <thead>
          <tr>
            <th>*</th>
            <th>Inicio</th>
            <th>Pausa</th>
            <th>Fin</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {registros.map((registro) => (
            <Registro key={registro.fechaDia} registroAux={registro.registro} fechaDia={registro.fechaDia} estado={registro.estado} trabajador={trabajador} admin={admin} horario={horario}>
              {setTiempoTotalAño(registro.registro, registro.fechaDia)}
              {setTiempoTotalMesHorario(registro.registro, registro.fechaDia, registro.estado)}
            </Registro>
          ))}
          <tr>
            <td>TOTAL HORAS {new Date(registros[0].fechaDia).getFullYear()}</td>
            <td></td>
            <td></td>
            <td></td>
            <td>{formatSeconds(totalSegundosRegistrosAño)} / {formatSeconds(totalSegundosHorarioAño)}</td>
          </tr>
        </tbody>
      </table>
    )
  } 
  else if (trabajador !== null && bRegistros === true && admin === true && bajaVacaciones === true && horario !== null) {
    return (
      <table>
        <thead>
        <tr>
          <th>Dia</th>
          <th>#</th>
          <th>Baja/Vacaciones</th>
          <th>Motivo</th>
          <th>Cambiar</th>
        </tr>
        </thead>
        <tbody>
          {registros.map((registro) => (
            <LaboralesFestivos key={registro.fechaDia} diaMes={null} registro={registro} horario={horario} motivoLibre={motivoLibre} comentario={comentario} trabajador={trabajador}></LaboralesFestivos>
          ))}
        </tbody>
      </table>
    ) 
  } else return(<></>);
}
