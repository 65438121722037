export const ModalCambiarRegistros = ({children, isOpen, closeModal}) => {

  const handleModalContainerClick = e => e.stopPropagation()
  
  return (
    <article className={`modal-cambiar-registros ${isOpen && "is-open"}`} onClick={closeModal}>
      <div className="modal-container-cambiar-registros" onClick={handleModalContainerClick}>
      <button className="modal-close-cambiar-registros cancelButton" onClick={closeModal}>X</button>
        {children}
      </div>
    </article>
  )
}