import React from "react";
import { useState } from "react";
import { ModalPausa } from "./ModalPausa";
import { useModalPausa } from "./UseModalPausa";
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO } from "./../../../js/project"
import { BLANK, REASONS_JUSTIFIED_PAUSE, TRABAJADOR_SOCIAL } from "../../../js/consts";
import { formatMayus } from "../../../js/functions";

export const ModalsPausa = ({ disableButtonf, botonPausa, botonAccionDiaAnterior }) => {
  const [isOpenModal, openModal, closeModal] = useModalPausa(false);
  const [pausaJornadaPartida, setPausaJornadaPartida] = useState(true);
  const [pausaJustificada, setPausaJustificada] = useState(false);
  const [pausaPersonal, setPausaPersonal] = useState(false);
  const [comentarioPausa, setComentarioPausa] = useState("");
  const [comentarioPausaPersonal, setComentarioPausaPersonal] = useState("");
  const [disableButtonPausa, setDisableButtonPausa] = useState(false);
  const [tipoPausaJustificada, setTipoPausaJustificada] = useState(BLANK);
  const [mensajeError, setMensajeError] = useState("");

  function onChangePausaJornadaPartida(e) {
    if (e.target.value === "false") {
      setPausaJornadaPartida(true);
      setPausaPersonal(false);
      setPausaJustificada(false);
      setMensajeError("")
    }
  }

  function onChangePausaPersonal(e) {
    if (e.target.value === "false") {
      setPausaJornadaPartida(false);
      setPausaPersonal(true);
      setPausaJustificada(false);
      setMensajeError("")
    }
  }

  function onChangePausaJustificada(e) {
    if (e.target.value === "false") {
      setPausaJornadaPartida(false);
      setPausaPersonal(false);
      setPausaJustificada(true);
    }
  }
  
  async function onClickAceptar() {
    if (tipoPausaJustificada === BLANK && pausaJustificada === true) {
      setMensajeError("Elije tipo de pausa")
      return;
    }

    setMensajeError("")

    setDisableButtonPausa(true);
    disableButtonf();

    if (pausaJornadaPartida === true) {
      botonPausa(0, tipoPausaJustificada, comentarioPausa, botonAccionDiaAnterior)
    }
    else if (pausaPersonal === true) {
      botonPausa(1, tipoPausaJustificada, comentarioPausaPersonal, botonAccionDiaAnterior)
    }
    else if (pausaJustificada === true) {
      botonPausa(2, tipoPausaJustificada, comentarioPausa, botonAccionDiaAnterior)
    }
  }

  return (
    <>
      <button
        className="pausaButton"
        disabled={disableButtonPausa}
        onClick={openModal}
      >
        Pausa
      </button>

      <ModalPausa isOpen={isOpenModal} closeModal={closeModal}>
        <div className="textleft titulo2">Pausa</div>
        <hr />
        <div className="textleft modalPausa pbottom-16 margintop-2">
          <label>
            <input
              type="checkbox"
              className="marginright-8"
              checked={pausaJornadaPartida}
              value={pausaJornadaPartida}
              onChange={onChangePausaJornadaPartida}
            />
            Pausa jornada partida
          </label>
          <label>
            <input
              type="checkbox"
              className="marginright-8"
              checked={pausaPersonal}
              value={pausaPersonal}
              onChange={onChangePausaPersonal}
            />
            Pausa personal
          </label>
          <textarea
            className="hw-100 mmw-100"
            rows={4}
            placeholder="Mas información..."
            disabled={!pausaPersonal}
            value={comentarioPausaPersonal}
            onChange={(e) => {
              setComentarioPausaPersonal(e.target.value);
            }}
          />
          
          <label>
            <input
              type="checkbox"
              className="marginright-8"
              checked={pausaJustificada}
              value={pausaJustificada}
              onChange={onChangePausaJustificada}
            />
            Pausa Justificada
          </label>
          <select disabled={!pausaJustificada} value={tipoPausaJustificada} onChange={(e) => {setTipoPausaJustificada(e.target.value)}}>
            <option key={BLANK} value={BLANK}></option>

            { PROJECT_APP === ECINNOVA && 
              <></>
            }
            { PROJECT_APP === INSERTATOLEDO && 
              <option key={TRABAJADOR_SOCIAL} value={TRABAJADOR_SOCIAL}>{formatMayus(TRABAJADOR_SOCIAL)}</option>
            }
            { PROJECT_APP === CARITASTOLEDO && 
              <></>
            }

            {REASONS_JUSTIFIED_PAUSE.map((reason) => (
              <option key={reason} value={reason}>{formatMayus(reason)}</option>
            ))}
          </select>
          <textarea
            className="hw-100 mmw-100"
            rows={4}
            placeholder="Mas información..."
            disabled={!pausaJustificada}
            value={comentarioPausa}
            onChange={(e) => {
              setComentarioPausa(e.target.value);
            }}
          />
          {mensajeError === "" && <div>&nbsp;</div>}
          {mensajeError !== "" && <div className="colorred">{mensajeError}</div>}
        </div>
        <div className="textright">
          <button
            disabled={disableButtonPausa}
            className="cancelButton"
            onClick={closeModal}
          >
            Cancelar
          </button>
          <button disabled={disableButtonPausa} className="acceptButton" onClick={onClickAceptar}>
            Aceptar
          </button>
        </div>
      </ModalPausa>
    </>
  );
};
