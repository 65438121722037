export const ModalEditarTrabajador = ({children, isOpen, closeModal}) => {

  const handleModalContainerClick = e => e.stopPropagation()
  
  return (
    <article className={`modal-editar-trabajador ${isOpen && "is-open"}`} onClick={closeModal}>
      <div className="modal-container-editar-trabajador" onClick={handleModalContainerClick}>
      <button className="modal-close-editar-trabajador cancelButton" onClick={closeModal}>X</button>
        {children}
      </div>
    </article>
  )
}