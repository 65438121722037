import { useState, useEffect } from "react";
import { diaAnteriorSemana, diaSemana, formatSeconds } from "../../js/functions";

export default function HorasDiaSemana({ semana }) {
  const [tiempoDia, setTiempoDia] = useState(null)
  const [tiempoSemana, setTiempoSemana] = useState(null)
  const [labelDia, setLabelDia] = useState(null)
  const [labelSemana, setLabelSemana] = useState(null)
  const [porcentajeDia, setPorcentajeDia] = useState(null)
  const [porcentajeSemana, setPorcentajeSemana] = useState(null)
  const diaActual = diaSemana()
  const diaAnterior = diaAnteriorSemana()
  let fechaAlSegundo = new Date()

  useEffect(() => {
    intervalo()
    horasMinutosDia()
    horasMinutosSemanales()
    porcentajeHorasDia()
    porcentajeHorasSemana()
  }, []);

  function intervalo() {
     setInterval(() => {
      fechaAlSegundo = new Date()
      porcentajeHorasDia()
      porcentajeHorasSemana()
      if (diaSemana() !== diaActual) {
        window.location.reload()
      }
    }, 1000);
  }

  function horasMinutosDia() {
    let diaActualOAnterior = diaActual
    if (semana[diaAnterior].registro !== undefined &&
        semana[diaAnterior].registro.inicio !== undefined &&
        semana[diaAnterior].registro.fin === undefined) {
      diaActualOAnterior = diaAnterior
    }
    else {
      diaActualOAnterior = diaActual
    }
    // if (semana[diaActualOAnterior].horario.inicio === "") {
    //   setTiempoDia("00:00")
    //   return
    // } 
    
    let minutosInicio = 0
    let minutosFin = 0

    if (semana[diaActualOAnterior].horario.inicio !== "") {
      const arrIni = (semana[diaActualOAnterior].horario.inicio).split(":")
      const arrFin = (semana[diaActualOAnterior].horario.fin).split(":")
      minutosInicio = parseInt(arrIni[0]) * 60 + parseInt(arrIni[1])
      minutosFin = parseInt(arrFin[0]) * 60 + parseInt(arrFin[1])
    }

    let minutosTotalesTarde = 0

    if (semana[diaActualOAnterior].horario.inicioTarde !== "") {
      const arrIniTarde = (semana[diaActualOAnterior].horario.inicioTarde).split(":")
      const arrFinTarde = (semana[diaActualOAnterior].horario.finTarde).split(":")
      const minutosInicioTarde = parseInt(arrIniTarde[0]) * 60 + parseInt(arrIniTarde[1])
      const minutosFinTarde = parseInt(arrFinTarde[0]) * 60 + parseInt(arrFinTarde[1])
      minutosTotalesTarde = minutosFinTarde - minutosInicioTarde
    }

    let minutosTotalesDiarios = 0

    if (semana[diaActualOAnterior].horario.dobleDia === true) {
      minutosTotalesDiarios = minutosFin + 1440 - minutosInicio
    }
    else {
      minutosTotalesDiarios = minutosFin - minutosInicio + minutosTotalesTarde
    }
    
    
    let horasDiarias = ""
    let minutosDiarios = ""
    
    if (minutosTotalesDiarios/60 < 10) {
      horasDiarias = `0${parseInt(minutosTotalesDiarios/60)}`
    }
    else {
      horasDiarias = `${parseInt(minutosTotalesDiarios/60)}`
    }
    if (minutosTotalesDiarios%60 < 10) {
      minutosDiarios = `0${parseInt(minutosTotalesDiarios%60)}`
    }
    else {
      minutosDiarios = `${parseInt(minutosTotalesDiarios%60)}`
    }
    setTiempoDia(`${horasDiarias}:${minutosDiarios}`)
  }

  function calcHorasSemanales(diaSemanaFor) {
    if (semana[diaSemanaFor].horario.inicio !== "") {
      if(semana[diaSemanaFor].horario.dobleDia === true) {
        const arrIni = (semana[diaSemanaFor].horario.inicio).split(":")
        const arrFin = (semana[diaSemanaFor].horario.fin).split(":")
        const minutosInicio = parseInt(arrIni[0]) * 60 + parseInt(arrIni[1])
        const minutosFin = parseInt(arrFin[0]) * 60 + parseInt(arrFin[1])
        return minutosFin + 1440 - minutosInicio
      }
      if(semana[diaSemanaFor].horario.inicioTarde !== "") {
        const arrIni = (semana[diaSemanaFor].horario.inicio).split(":")
        const arrFin = (semana[diaSemanaFor].horario.fin).split(":")
        const arrIniTarde = (semana[diaSemanaFor].horario.inicioTarde).split(":")
        const arrFinTarde = (semana[diaSemanaFor].horario.finTarde).split(":")

        const minutosInicio = parseInt(arrIni[0]) * 60 + parseInt(arrIni[1])
        const minutosFin = parseInt(arrFin[0]) * 60 + parseInt(arrFin[1])
        const minutosInicioTarde = parseInt(arrIniTarde[0]) * 60 + parseInt(arrIniTarde[1])
        const minutosFinTarde = parseInt(arrFinTarde[0]) * 60 + parseInt(arrFinTarde[1])
        return ((minutosFin - minutosInicio) + (minutosFinTarde - minutosInicioTarde));
      }
      else {
        const arrIni = (semana[diaSemanaFor].horario.inicio).split(":")
        const arrFin = (semana[diaSemanaFor].horario.fin).split(":")
        const minutosInicio = parseInt(arrIni[0]) * 60 + parseInt(arrIni[1])
        const minutosFin = parseInt(arrFin[0]) * 60 + parseInt(arrFin[1])
        return minutosFin - minutosInicio
      }
    } else return 0
  }

  function horasMinutosSemanales() {
    let minutosTotalesSemanales = 0;
    let horasSemanales = ""
    let minutosSemanales = ""
    for(let i=1; i<=7; i++) {
      minutosTotalesSemanales = calcHorasSemanales(i) + minutosTotalesSemanales
    }
    
    if (minutosTotalesSemanales/60 < 10) {
      horasSemanales = `0${parseInt(minutosTotalesSemanales/60)}`
    }
    else {
      horasSemanales = `${parseInt(minutosTotalesSemanales/60)}`
    }
    if (minutosTotalesSemanales%60 < 10) {
      minutosSemanales = `0${parseInt(minutosTotalesSemanales%60)}`
    }
    else {
      minutosSemanales = `${parseInt(minutosTotalesSemanales%60)}`
    }

    setTiempoSemana(`${horasSemanales}:${minutosSemanales}`)
  }

  function calcPorcentajeHorasSemana(diaActualFor) {
    let pausasHoy = "", totalSegundosHoy = 0;
    if (semana[diaActualFor].registro.pausas !== undefined && semana[diaActualFor].registro.pausas !== null) {
      if (semana[diaActualFor].registro !== "") {
        pausasHoy = semana[diaActualFor].registro.pausas.filter(pausa => pausa.tipo !== 2)
      }
      else {
        pausasHoy = ""
      }
    } 

    if (semana[diaActualFor].registro.inicio !== undefined) {
      if (pausasHoy !== "" && pausasHoy !== null && pausasHoy.length > 0) {
        for (let i = 0; i<pausasHoy.length; i++) {
          if (pausasHoy[i] !== undefined) {
            if (pausasHoy.length === 1 && semana[diaActualFor].registro.fin !== undefined) { //hay 1 pausa con fin
              const segundosAux = pausasHoy[0].inicio.seconds - semana[diaActualFor].registro.inicio.seconds
              const segundosAux2 = semana[diaActualFor].registro.fin.seconds - pausasHoy[0].fin.seconds
              totalSegundosHoy = segundosAux + segundosAux2
              //totalHoy = formatSeconds(totalSegundosHoy)
              //console.log("una pausa")
            } 
            if (pausasHoy.length === 1 && 
              semana[diaActualFor].registro.fin === undefined && 
              pausasHoy[0].fin !== undefined && 
              (
                new Date(semana[diaActualFor].registro.fecha).getDay() === new Date().getDay() || 
                new Date(semana[diaActualFor].registro.fecha).getDay() === new Date().getDay() - 1
              )
              ) { //hay 1 pausa sin fin
              const segundosAux = pausasHoy[0].inicio.seconds - semana[diaActualFor].registro.inicio.seconds
              const segundosAux2 = new Date().getTime() / 1000 - pausasHoy[0].fin.seconds
              totalSegundosHoy = segundosAux + segundosAux2
              //console.log(totalSegundosHoy)
            } 
            if (pausasHoy.length === 1 && semana[diaActualFor].registro.fin === undefined && pausasHoy[0].fin === undefined) { //hay 1 pausa sin fin y pausa activa
              const segundosAux = pausasHoy[0].inicio.seconds - semana[diaActualFor].registro.inicio.seconds
              totalSegundosHoy = segundosAux
              //console.log(totalSegundosHoy)
            } 
            if (pausasHoy.length === 2 && semana[diaActualFor].registro.fin !== undefined) { //hay 2 pausas con fin
              const segundosAux = pausasHoy[0].inicio.seconds - semana[diaActualFor].registro.inicio.seconds
              const segundosAux2 = pausasHoy[1].inicio.seconds - pausasHoy[0].fin.seconds
              const segundosAux3 = semana[diaActualFor].registro.fin.seconds - pausasHoy[1].fin.seconds
              totalSegundosHoy = segundosAux + segundosAux2 + segundosAux3
              //totalHoy = formatSeconds(totalSegundosHoy)
              //console.log("2 pausas")
            }
            if (pausasHoy.length === 2 && 
              semana[diaActualFor].registro.fin === undefined && 
              pausasHoy[1].fin !== undefined && 
              (
                new Date(semana[diaActualFor].registro.fecha).getDay() === new Date().getDay() || 
                new Date(semana[diaActualFor].registro.fecha).getDay() === new Date().getDay() - 1
              )
              ) { //hay 2 pausas sin fin
              const segundosAux = pausasHoy[0].inicio.seconds - semana[diaActualFor].registro.inicio.seconds
              const segundosAux2 = pausasHoy[1].inicio.seconds - pausasHoy[0].fin.seconds
              const segundosAux3 = new Date().getTime() / 1000 - pausasHoy[1].fin.seconds
              totalSegundosHoy = segundosAux + segundosAux2 + segundosAux3
              //totalHoy = formatSeconds(totalSegundosHoy)
            }  
            if (pausasHoy.length === 2 && semana[diaActualFor].registro.fin === undefined && pausasHoy[1].fin === undefined) { //hay 2 pausas sin fin y pausa activa
              const segundosAux = pausasHoy[0].inicio.seconds - semana[diaActualFor].registro.inicio.seconds
              const segundosAux2 = pausasHoy[1].inicio.seconds - pausasHoy[0].fin.seconds
              totalSegundosHoy = segundosAux + segundosAux2
              //totalHoy = formatSeconds(totalSegundosHoy)
            } 
            if (pausasHoy.length > 2) { // hay mas de 2 pausas
              if (i === 0) { //primera pausa
                const segundosAux = pausasHoy[i].inicio.seconds - semana[diaActualFor].registro.inicio.seconds
                totalSegundosHoy = totalSegundosHoy + segundosAux
                //console.log("tres pausas 1, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundosHoy)
              } else if (i < pausasHoy.length) { //pausas intermedias
                const segundosAux = pausasHoy[i].inicio.seconds - pausasHoy[i-1].fin.seconds
                totalSegundosHoy = totalSegundosHoy + segundosAux
                //console.log("tres pausas 2, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundosHoy)
              } 
              if (i === pausasHoy.length-1 && semana[diaActualFor].registro.fin !== undefined) { //ultima pausa
                const segundosAux = semana[diaActualFor].registro.fin.seconds - pausasHoy[i].fin.seconds
                totalSegundosHoy = totalSegundosHoy + segundosAux
                //console.log("tres pausas 3, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundosHoy)
                //totalHoy = formatSeconds(totalSegundosHoy)
              }
              if (i === pausasHoy.length-1 && 
                semana[diaActualFor].registro.fin === undefined && 
                pausasHoy[i].fin !== undefined && 
                (
                  new Date(semana[diaActualFor].registro.fecha).getDay() === new Date().getDay() || 
                  new Date(semana[diaActualFor].registro.fecha).getDay() === new Date().getDay() - 1
                )
                ) { //ultima pausa
                const segundosAux = new Date().getTime() / 1000 - pausasHoy[i].fin.seconds
                totalSegundosHoy = totalSegundosHoy + segundosAux
                //totalHoy = formatSeconds(totalSegundosHoy)
              }
            }
          }
        }
      } 
      else if (semana[diaActualFor].registro.fin === undefined) {
        if (new Date(semana[diaActualFor].registro.fecha).getDay() === new Date().getDay() || new Date(semana[diaActualFor].registro.fecha).getDay() === new Date().getDay() - 1) {
          totalSegundosHoy = new Date().getTime() / 1000 - semana[diaActualFor].registro.inicio.seconds
        } 
        else {
          totalSegundosHoy = 0
        } 
      }
      else if (semana[diaActualFor].registro.fin !== undefined) {
        totalSegundosHoy = semana[diaActualFor].registro.fin.seconds - semana[diaActualFor].registro.inicio.seconds
      }

      if (semana[diaActualFor].registro.trabajado === false) {
        totalSegundosHoy = 0
      }
    }
    return totalSegundosHoy
  }

  function porcentajeHorasDia() {
    let diaActualOAnterior = ""
    if (semana[diaAnterior].registro !== undefined &&
        semana[diaAnterior].registro.inicio !== undefined &&
        semana[diaAnterior].registro.fin === undefined) {
      diaActualOAnterior = diaAnterior
    }
    else {
      diaActualOAnterior = diaActual
    }

    // if (semana[diaActual].horario.inicio === "") {
    //   setPorcentajeDia("100")
    //   setLabelDia("100%")
    //   return
    // }
    
    let pausasHoy = "", totalSegundosHoy = 0;
    if (semana[diaActualOAnterior].registro.pausas !== undefined && semana[diaActualOAnterior].registro.pausas !== null) {
      if (semana[diaActualOAnterior].registro !== "") {
        pausasHoy = semana[diaActualOAnterior].registro.pausas.filter(pausa => pausa.tipo !== 2)
      }
      else {
        pausasHoy = ""
      }
    } 

    if (semana[diaActualOAnterior].registro.inicio !== undefined) {
      if (pausasHoy !== "" && pausasHoy !== null && pausasHoy.length > 0) {
        for (let i = 0; i<pausasHoy.length; i++) {
          if (pausasHoy[i] !== undefined) {
            if (pausasHoy.length === 1 && semana[diaActualOAnterior].registro.fin !== undefined) { //hay 1 pausa con fin
              const segundosAux = pausasHoy[0].inicio.seconds - semana[diaActualOAnterior].registro.inicio.seconds
              const segundosAux2 = semana[diaActualOAnterior].registro.fin.seconds - pausasHoy[0].fin.seconds
              totalSegundosHoy = segundosAux + segundosAux2
              //totalHoy = formatSeconds(totalSegundosHoy)
              //console.log("una pausa")
            } 
            if (pausasHoy.length === 1 && semana[diaActualOAnterior].registro.fin === undefined && pausasHoy[0].fin !== undefined) { //hay 1 pausa sin fin
              const segundosAux = pausasHoy[0].inicio.seconds - semana[diaActualOAnterior].registro.inicio.seconds
              const segundosAux2 = new Date().getTime() / 1000 - pausasHoy[0].fin.seconds
              totalSegundosHoy = segundosAux + segundosAux2
            } 
            if (pausasHoy.length === 1 && semana[diaActualOAnterior].registro.fin === undefined && pausasHoy[0].fin === undefined) { //hay 1 pausa sin fin y pausa activa
              const segundosAux = pausasHoy[0].inicio.seconds - semana[diaActualOAnterior].registro.inicio.seconds
              totalSegundosHoy = segundosAux
            } 
            if (pausasHoy.length === 2 && semana[diaActualOAnterior].registro.fin !== undefined) { //hay 2 pausas con fin
              const segundosAux = pausasHoy[0].inicio.seconds - semana[diaActualOAnterior].registro.inicio.seconds
              const segundosAux2 = pausasHoy[1].inicio.seconds - pausasHoy[0].fin.seconds
              const segundosAux3 = semana[diaActualOAnterior].registro.fin.seconds - pausasHoy[1].fin.seconds
              totalSegundosHoy = segundosAux + segundosAux2 + segundosAux3
              //totalHoy = formatSeconds(totalSegundosHoy)
              //console.log("2 pausas")
            }
            if (pausasHoy.length === 2 && semana[diaActualOAnterior].registro.fin === undefined && pausasHoy[1].fin !== undefined) { //hay 2 pausas sin fin
              const segundosAux = pausasHoy[0].inicio.seconds - semana[diaActualOAnterior].registro.inicio.seconds
              const segundosAux2 = pausasHoy[1].inicio.seconds - pausasHoy[0].fin.seconds
              const segundosAux3 = new Date().getTime() / 1000 - pausasHoy[1].fin.seconds
              totalSegundosHoy = segundosAux + segundosAux2 + segundosAux3
              //totalHoy = formatSeconds(totalSegundosHoy)
            }  
            if (pausasHoy.length === 2 && semana[diaActualOAnterior].registro.fin === undefined && pausasHoy[1].fin === undefined) { //hay 2 pausas sin fin y pausa activa
              const segundosAux = pausasHoy[0].inicio.seconds - semana[diaActualOAnterior].registro.inicio.seconds
              const segundosAux2 = pausasHoy[1].inicio.seconds - pausasHoy[0].fin.seconds
              totalSegundosHoy = segundosAux + segundosAux2
              //totalHoy = formatSeconds(totalSegundosHoy)
            } 
            if (pausasHoy.length > 2) { // hay mas de 2 pausas
              if (i === 0) { //primera pausa
                const segundosAux = pausasHoy[i].inicio.seconds - semana[diaActualOAnterior].registro.inicio.seconds
                totalSegundosHoy = totalSegundosHoy + segundosAux
                //console.log("tres pausas 1, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundosHoy)
              } 
              else if (i < pausasHoy.length) { //pausas intermedias
                const segundosAux = pausasHoy[i].inicio.seconds - pausasHoy[i-1].fin.seconds
                totalSegundosHoy = totalSegundosHoy + segundosAux
                //console.log("tres pausas 2, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundosHoy)
              } 
              if(i === pausasHoy.length-1 && semana[diaActualOAnterior].registro.fin !== undefined) { //ultima pausa
                const segundosAux = semana[diaActualOAnterior].registro.fin.seconds - pausasHoy[i].fin.seconds
                totalSegundosHoy = totalSegundosHoy + segundosAux
                //console.log("tres pausas 3, segundos aux -> "+segundosAux+",    segundos totales -> "+totalSegundosHoy)
                //totalHoy = formatSeconds(totalSegundosHoy)
              }
              if(i === pausasHoy.length-1 && semana[diaActualOAnterior].registro.fin === undefined && pausasHoy[i].fin !== undefined) { //ultima pausa
                const segundosAux = new Date().getTime() / 1000 - pausasHoy[i].fin.seconds
                totalSegundosHoy = totalSegundosHoy + segundosAux
                //totalHoy = formatSeconds(totalSegundosHoy)
              }
            }
          }
        }
      } 
      else if (semana[diaActualOAnterior].registro.fin === undefined) {
        totalSegundosHoy = fechaAlSegundo.getTime() / 1000 - semana[diaActualOAnterior].registro.inicio.seconds
      } 
      else if (semana[diaActualOAnterior].registro.fin !== undefined) {
        totalSegundosHoy = semana[diaActualOAnterior].registro.fin.seconds - semana[diaActualOAnterior].registro.inicio.seconds
        
      }
    }

    let segundosTotalesDiarios = 0
    if (semana[diaActualOAnterior].horario.dobleDia === true) {
      const arrIni = (semana[diaActualOAnterior].horario.inicio).split(":")
      const arrFin = (semana[diaActualOAnterior].horario.fin).split(":")
      const segundosInicio = parseInt(arrIni[0]) * 3600 + parseInt(arrIni[1] * 60)
      const segundosFin = parseInt(arrFin[0]) * 3600 + parseInt(arrFin[1] * 60)
      segundosTotalesDiarios = segundosFin + 1440 * 60 - segundosInicio
    }
    else if (semana[diaActualOAnterior].horario.inicioTarde !== "") {
      const arrIni = (semana[diaActualOAnterior].horario.inicio).split(":")
      const arrFin = (semana[diaActualOAnterior].horario.fin).split(":")
      const segundosInicio = parseInt(arrIni[0]) * 3600 + parseInt(arrIni[1] * 60)
      const segundosFin = parseInt(arrFin[0]) * 3600 + parseInt(arrFin[1] * 60)
      const segundosTotalesDiariosMañana = segundosFin - segundosInicio

      const arrIniTarde = (semana[diaActualOAnterior].horario.inicioTarde).split(":")
      const arrFinTarde = (semana[diaActualOAnterior].horario.finTarde).split(":")
      const segundosInicioTarde = parseInt(arrIniTarde[0]) * 3600 + parseInt(arrIniTarde[1] * 60)
      const segundosFinTarde = parseInt(arrFinTarde[0]) * 3600 + parseInt(arrFinTarde[1] * 60)
      const segundosTotalesDiariosTarde = segundosFinTarde - segundosInicioTarde
      segundosTotalesDiarios = segundosTotalesDiariosMañana + segundosTotalesDiariosTarde
    }
    else {
      const arrIni = (semana[diaActualOAnterior].horario.inicio).split(":")
      const arrFin = (semana[diaActualOAnterior].horario.fin).split(":")
      const segundosInicio = parseInt(arrIni[0]) * 3600 + parseInt(arrIni[1] * 60)
      const segundosFin = parseInt(arrFin[0]) * 3600 + parseInt(arrFin[1] * 60)
      segundosTotalesDiarios = segundosFin - segundosInicio
    }

    if (semana[diaActualOAnterior].registro.trabajado === true) {
      setLabelDia(`${(totalSegundosHoy * 100 / segundosTotalesDiarios).toFixed(2)}% - ${formatSeconds(totalSegundosHoy)} horas`)
      setPorcentajeDia((totalSegundosHoy * 100 / segundosTotalesDiarios).toFixed(2))
    }
    else {
      setLabelDia(``)
      setPorcentajeDia(0)
    }
  }

  function porcentajeHorasSemana() {
    let segundosHechosSemana = 0;
    for (let i = 1; i <= 7; i++) {
      segundosHechosSemana = segundosHechosSemana + calcPorcentajeHorasSemana(i);
    }

    let segundosTotalesSemanales = 0;
    for (let i = 1; i <= 7; i++) {
      segundosTotalesSemanales = calcHorasSemanales(i) * 60 + segundosTotalesSemanales
    }
    setLabelSemana(`${(segundosHechosSemana * 100 / segundosTotalesSemanales).toFixed(2)}% - ${formatSeconds(segundosHechosSemana)} horas`)
    setPorcentajeSemana((segundosHechosSemana * 100 / segundosTotalesSemanales).toFixed(2))
  }

  return (
    <section className="hoursDaysWeekContainer">
      <article>
        <div className="text-bold font-size-2-rem">Dia</div>
        <hr className="pm-0"/>
        <div className="text-bold font-size-2_5-rem">{tiempoDia}</div>
        <hr className="pm-0"/>
        <div className="text-bold font-size-1_25-rem">Horas</div>
        <div className="progresscss">
          <div className="progressbarcss" style={{width: `${porcentajeDia}%`}}>{labelDia}</div>
        </div>
      </article>
      <article>
        <div className="text-bold font-size-2-rem">Semana</div>
        <hr className="pm-0"/>
        <div className="text-bold font-size-2_5-rem">{tiempoSemana}</div>
        <hr className="pm-0"/>
        <div className="text-bold font-size-1_25-rem">Horas</div>
        <div className="progresscss">
          <div className="progressbarcss" style={{width: `${porcentajeSemana}%`}}>{labelSemana}</div>
        </div>
      </article>
    </section>
  )
}
