import React from "react"
import "./ListaTrabajadoresLoading.css"

export default function ListaTrabajadoresLoading() {
  return (
    <>
      <div className="headerLT" />
      <div className="listaTrabajadores" />
      <div className="botones">
        <div />
        <div />
        <div />
      </div>
    </>
  );
}
