import React from "react"
import { useState, useEffect } from "react";
import { addDiaFestivo, existeRegistro, getRegistro, iniciarDia, quitarDiaFestivo, updateRegistro } from "../../firebase/firebase";
import { formatAñoMes, getAñoByFecha, getDiaByFecha, getNombreDiaSemanaByFecha, getNombreMesByFecha } from "../../js/functions";

export function LaboralesFestivos({diaMes, registro, horario, motivoLibre, comentario, trabajador}) {
  const [diaFestivo, setDiaFestivo] = useState(null)
  const [msgDiaFestivo, setMsgDiaFestivo] = useState("LABORAL")
  const [diaTrabaja, setDiaTrabaja] = useState(true)
  const [msgTrabajaNoTrabaja, setMsgTrabajaNoTrabaja] = useState("")
  const [motivoNoTrabaja, setMotivoNoTrabaja] = useState("")

  useEffect(() => {
    if (diaMes !== null) {
      if (diaMes.estado === "FESTIVO") { setDiaFestivo(true); setMsgDiaFestivo("FESTIVO") }
      else { setDiaFestivo(false); setMsgDiaFestivo("LABORAL") }
    } else {
      if (registro.estado === "FESTIVO") { setDiaFestivo(true); setMsgDiaFestivo("FESTIVO") }
      else { setDiaFestivo(false); setMsgDiaFestivo("LABORAL") }
    }
    if (registro !== null && registro.registro.trabajado === false) setDiaTrabaja(false)
    else setDiaTrabaja(true) 
    if (registro !== null) {
      trabajaNoTrabaja()
      motivoNoTrabajaf()
    }
  }, []);

  async function onClickCambiarAFestivo() {
    const arrFecha = (diaMes.fechaDia).split("-")
    const añomes = `${arrFecha[0]}-${arrFecha[1]}`
    const año = arrFecha[0]

    const diaFestivo = {
      dia: diaMes.fechaDia,
      fecha: new Date(diaMes.fechaDia),
      añomes: añomes,
      año: año
    }
    await addDiaFestivo(diaFestivo)
    setDiaFestivo(true)
    setMsgDiaFestivo("FESTIVO")
  }

  async function onClickCambiarALaboral() {
    await quitarDiaFestivo(diaMes.fechaDia)
    setDiaFestivo(false)
    setMsgDiaFestivo("LABORAL")
  }

  function trabajaNoTrabaja() {
    if (registro.registro === "") setMsgTrabajaNoTrabaja("TRABAJA")
    else if (registro.registro.trabajado === true) setMsgTrabajaNoTrabaja("TRABAJA")
    else if (registro.registro.trabajado === false) setMsgTrabajaNoTrabaja("NO TRABAJA")
  }

  function motivoNoTrabajaf() {
    if (registro.registro === "") setMotivoNoTrabaja("")
    else if(registro.registro.trabajado === false) return setMotivoNoTrabaja(registro.registro.motivoLibre) 
    else return setMotivoNoTrabaja("")
  }

  async function onClickTrabaja() {
    const añoMes = formatAñoMes(new Date(registro.fechaDia));
    const año = new Date(registro.fechaDia).getFullYear();

    registro.registro = {}
    registro.registro.trabajado = false
    registro.registro.motivoLibre = motivoLibre
    registro.registro.comentarioLibre = comentario
    registro.registro.añomes = añoMes
    registro.registro.año = `${año}`
    registro.registro.fecha = registro.fechaDia
    if (await existeRegistro(trabajador, registro.fechaDia) === true) await updateRegistro(trabajador, registro.fechaDia, registro.registro) 
    else await iniciarDia(trabajador, registro.registro, registro.fechaDia)
    
    setDiaTrabaja(false)
    setMsgTrabajaNoTrabaja("NO TRABAJA")
    setMotivoNoTrabaja(motivoLibre)
  }

  async function onClickNoTrabaja() {
    const añoMes = formatAñoMes(new Date(registro.fechaDia));
    const año = new Date(registro.fechaDia).getFullYear();

    registro.registro = {}

    const registroDB = await getRegistro(trabajador, registro.fechaDia)
    if (registroDB.inicio !== undefined) registro.registro.trabajado = true 
    else registro.registro.trabajado = null

    registro.registro.motivoLibre = ""
    registro.registro.comentarioLibre = ""
    registro.registro.añomes = añoMes
    registro.registro.año = `${año}`
    registro.registro.fecha = registro.fechaDia

    if (await existeRegistro(trabajador, registro.fechaDia) === true) await updateRegistro(trabajador, registro.fechaDia, registro.registro) 
    else await iniciarDia(trabajador, registro.registro, registro.fechaDia)

    setDiaTrabaja(true)
    setMsgTrabajaNoTrabaja("TRABAJA")
    setMotivoNoTrabaja("")
  }

  if (diaFestivo !== null && registro === null && diaMes !== null) {
    if (diaMes.estado === "DOMINGO") {
      return (
        <tr className="bgdiadomingo">
          <td>{getNombreDiaSemanaByFecha(diaMes.fechaDia)} {getDiaByFecha(diaMes.fechaDia)} {getNombreMesByFecha(diaMes.fechaDia)} de {getAñoByFecha(diaMes.fechaDia)}</td>
          <td>{diaMes.estado}</td>
          <td><button className="transparentButton" disabled onClick={onClickCambiarALaboral}>*</button></td>
        </tr>
      );
    } 
    else if (diaFestivo === true) {
      return (
        <tr className="bgdiafestivo">
          <td>{getNombreDiaSemanaByFecha(diaMes.fechaDia)} {getDiaByFecha(diaMes.fechaDia)} {getNombreMesByFecha(diaMes.fechaDia)} de {getAñoByFecha(diaMes.fechaDia)}</td>
          <td>{msgDiaFestivo}</td>
          <td><button className="successButton" onClick={onClickCambiarALaboral}>Cambiar a laboral</button></td>
        </tr>
      );
    } 
    else if (diaFestivo === false) {
      return (
        <tr className="bgdialaboral">
          <td>{getNombreDiaSemanaByFecha(diaMes.fechaDia)} {getDiaByFecha(diaMes.fechaDia)} {getNombreMesByFecha(diaMes.fechaDia)} de {getAñoByFecha(diaMes.fechaDia)}</td>
          <td>{msgDiaFestivo}</td>
          <td><button className="cancelButton" onClick={onClickCambiarAFestivo}>Cambiar a festivo</button></td>
        </tr>
      );
    } 
    else return <></>
  } 
  
  ///////////////////////////////////////
  
  else if (diaFestivo !== null && registro !== null && diaMes === null && horario !== null) {
    if (horario[new Date(registro.fechaDia).getDay()].inicio === "" && diaTrabaja === false) {
      return (
        <tr className="bgdianotrabajado">
          <td>{getNombreDiaSemanaByFecha(registro.fechaDia)} {getDiaByFecha(registro.fechaDia)} {getNombreMesByFecha(registro.fechaDia)} de {getAñoByFecha(registro.fechaDia)}</td>
          <td></td>
          <td>NO TRABAJA</td>
          <td>{motivoNoTrabaja}</td>
          <td><button className="cancelButton" onClick={onClickNoTrabaja}>Cancelar Baja/Vacaciones</button></td>
        </tr>
      );
    } 
    else if (horario[new Date(registro.fechaDia).getDay()].inicio === "" && diaTrabaja === true) {
      return (
        <tr className="bgdiadomingo">
          <td>{getNombreDiaSemanaByFecha(registro.fechaDia)} {getDiaByFecha(registro.fechaDia)} {getNombreMesByFecha(registro.fechaDia)} de {getAñoByFecha(registro.fechaDia)}</td>
          <td></td>
          <td>-</td>
          <td>HORARIO</td>
          <td><button className="successButton" onClick={onClickTrabaja}>Confirmar {motivoLibre}</button></td>
        </tr>
      );
    }
    else if (diaFestivo === true && diaTrabaja === false) {
      return (
        <tr className="bgdianotrabajado">
          <td>{getNombreDiaSemanaByFecha(registro.fechaDia)} {getDiaByFecha(registro.fechaDia)} {getNombreMesByFecha(registro.fechaDia)} de {getAñoByFecha(registro.fechaDia)}</td>
          <td>{msgDiaFestivo}</td>
          <td>{msgTrabajaNoTrabaja}</td>
          <td>{motivoNoTrabaja}</td>
          <td><button className="cancelButton" onClick={onClickNoTrabaja}>Cancelar Baja/Vacaciones</button></td>
        </tr>
      );
    } 
    else if (diaFestivo === true && diaTrabaja === true) {
      return (
        <tr className="bgdiafestivo">
          <td>{getNombreDiaSemanaByFecha(registro.fechaDia)} {getDiaByFecha(registro.fechaDia)} {getNombreMesByFecha(registro.fechaDia)} de {getAñoByFecha(registro.fechaDia)}</td>
          <td>{msgDiaFestivo}</td>
          <td> - </td>
          <td>FESTIVO</td>
          <td><button className="successButton" onClick={onClickTrabaja}>Confirmar {motivoLibre}</button></td>
        </tr>
      );
    } 
    else if (diaFestivo === false && diaTrabaja === false) {
      return (
        <tr className="bgdianotrabajado">
          <td>{getNombreDiaSemanaByFecha(registro.fechaDia)} {getDiaByFecha(registro.fechaDia)} {getNombreMesByFecha(registro.fechaDia)} de {getAñoByFecha(registro.fechaDia)}</td>
          <td>{msgDiaFestivo}</td>
          <td>{msgTrabajaNoTrabaja}</td>
          <td>{motivoNoTrabaja}</td>
          <td><button className="cancelButton" onClick={onClickNoTrabaja}>Cancelar Baja/Vacaciones</button></td>
        </tr>
      );
    } 
    else if (diaFestivo === false && diaTrabaja === true) {
      return (
        <tr className="bgdialaboral">
          <td>{getNombreDiaSemanaByFecha(registro.fechaDia)} {getDiaByFecha(registro.fechaDia)} {getNombreMesByFecha(registro.fechaDia)} de {getAñoByFecha(registro.fechaDia)}</td>
          <td>{msgDiaFestivo}</td>
          <td>{msgTrabajaNoTrabaja}</td>
          <td></td>
          <td><button className="successButton" onClick={onClickTrabaja}>Confirmar {motivoLibre}</button></td>
        </tr>
      );
    } 
    else return <></>
  } 
  else return <></>
}
