import { onAuthStateChanged, signOut } from "firebase/auth";
import { useState, useEffect } from "react";
import {
  auth,
  getCentros,
  getHorarios,
  getTrabajadores,
  getUser,
} from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import ListaTrabajadores from "../components/controlhorarioadmin/ListaTrabajadores";
import Header from "../components/controlhorarioadmin/Header";
import Footer from "../components/controlhorarioadmin/Footer";
import HeaderLoading from "../components/controlhorarioadmin/HeaderLoading";
import ListaTrabajadoresLoading from "../components/controlhorarioadmin/ListaTrabajadoresLoading";
import { colorProject } from "./../js/project"

// ruta predeterminada administradores

export function ControlHorarioAdmin() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [horariosPrestablecidos, setHorariosPrestablecidos] = useState(null)
  const [centros, setCentros] = useState(null)
  const [trabajadores, setTrabajadores] = useState(null)

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDB = await getUser(user.uid);
        setUser(userDB);

        if (userDB.estado === false) {
          logout()
        } 
        else if (userDB.primerAcceso === true) {
          navigate("/changePassword")
        } 
        else if (userDB.admin === false) {
          navigate("/controlHorario")
        }
        
        const horariosPrestablecidosAux = await getHorarios()
        horariosPrestablecidosAux.sort((a, b) => parseInt(a.id) - parseInt(b.id))
        setHorariosPrestablecidos(horariosPrestablecidosAux)
        setCentros(await getCentros())
        setTrabajadores(await getTrabajadores())

      } else {
        navigate("/login");
      }
    });
  }, [navigate]);

  function logout() {
    signOut(auth)
      .then(() => {
        // console.log("OUT");
      })
      .catch((error) => {
        // console.error(error);
        // console.log("error logout");
      });

    //console.log("OUT");
    //auth().logout();
    //window.location.href = "login.html";
  }

  if (user !== null && user.admin === true && user.estado === true && horariosPrestablecidos !== null && trabajadores !== null && centros !== null) {
    return (
      <>            
        <div style={{backgroundColor: colorProject}}>
          <div className="container1140px bgwhite">
            <Header trabajador={user} horariosPrestablecidos={horariosPrestablecidos}></Header>
            <main className="padding-20">
              <ListaTrabajadores trabajadoresAux={trabajadores} centros={centros} horariosPrestablecidos={horariosPrestablecidos} />
            </main>
            <Footer/>
          </div>
        </div>
      </>
    );
  } 
  else {
    return (
      <>            
        <div style={{backgroundColor: colorProject}}>
          <div className="container1140px bgwhite">
            <HeaderLoading />
            <main className="padding-20">
              <ListaTrabajadoresLoading />
            </main>
            <Footer/>
          </div>
        </div>
      </>
    );  
  }
}
