import "./DatosUsuarioActualLoading.css"

export default function DatosUsuarioActualLoading() {
  return (
    <section>
      <div className="emailynombre"></div>
      <div className="emailynombre"></div>
      <div className="emailynombre"></div>
    </section>
  );
}
