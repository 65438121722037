import React from "react"
import { signOut } from "firebase/auth";
import { auth, getDiasFestivosPorAñoyMes, getRegistrosPorAñoyMes } from "../../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import settings from "../../img/settings.png"
import logoblanco from "../../img/logo-blanco.svg"
import { RegistroDias } from "../controlhorarioadmin/RegistroDias"
import { ModalVerRegistros } from "./modalVerRegistros/ModalVerRegistros"
import { useModalVerRegistros } from "./modalVerRegistros/UseModalVerRegistros"
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO, colorProject } from "./../../js/project"
import { formatNumber, getFormatActualMonth, getFormatActualYear } from "../../js/functions";
import { MONTHS, YEARS } from "../../js/consts";

export default function Header({trabajador}) {
  const [isOpenModal, openModal, closeModal] = useModalVerRegistros(false)
  const navigate = useNavigate();
  const [bRegistros, setBRegistros] = useState(false);
  const [registros, setRegistros] = useState([]);
  const [añoSelect, setAñoSelect] = useState(getFormatActualYear())
  const [mesSelect, setMesSelect] = useState(getFormatActualMonth())
  const [display, setDisplay] = useState("none")

  async function verRegistros(trabajador, año, mes) {
    
    // Creo los dias del mes actual pasandole el parametro año y mes
    const diasMes = new Date(año, mes, 0).getDate();

    // Traigo todos los dias festivos por mes y año de la base de datos
    const diasMesFestivos = await getDiasFestivosPorAñoyMes(`${año}-${mes}`)

    // Traigo todos los registros por mes y año de la base de datos
    const registrosDB = await getRegistrosPorAñoyMes(trabajador,`${año}-${mes}`)

    // Hago un for para que recorra cada dia del mes seleccionado
    for (let dia = 1; dia <= diasMes; dia++) {

      // Creo la fecha del dia actual con el formato YYYY-MM-DD
      const fechaDia = `${año}-${mes}-${formatNumber(dia)}`

      // Creo todos los registros en el array de registros con fechaDia, registro "", y el estado en laboral
      registros.push({fechaDia: fechaDia, registro: "", estado: "LABORAL"})

      // Si los registros obtenidos de la base de datos de este mes no es un array vacio se ejecuta el if
      if (registrosDB.length !== 0) {

        // Creo un for que recorre todos los registros de la base de datos que con el if de antes ya se que no es 0
        for (let i = 0; i<registrosDB.length; i++) {

          // Si la fechaDia (primer for) es igual a la fecha de cada registro (anterior for) entonces se ejecuta
          if (fechaDia === registrosDB[i].fecha) {

            // Se asigna en el registro[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
            // en 0) la fecha (que es la misma que ya estaba asignada), el registro, y el estado laboral ya que
            // estoy dentro del for de los registros => luego le asigno festivo si así toca
            registros[dia-1] = {fechaDia: fechaDia, registro: registrosDB[i], estado: "LABORAL"}
          }
        }
      }

      // Si los registros obtenidos de la base de datos de este mes no es un array vacio y los dias
      // festivos obtenidos de la base de datos tampoco es un string vacio entonces se ejecuta el if
      if (registrosDB.length !== 0 && diasMesFestivos.length !== 0) {

        // Creo un for que recorre todos los registros de la base de datos que con el if de antes ya se que no es 0
        for (let i = 0; i<registrosDB.length; i++) {

          // Creo otro for anidado al anterior que recorre todos los dias festivos de la base de datos
          // que como antes he dicho no es 0 por el if
          for (let d = 0; d < diasMesFestivos.length; d++) {

            // Si la fechaDia (primer for) = a la fecha de los registros (segundo for) entonces se ejecuta el if
            if (fechaDia === diasMesFestivos[d].dia) {

              // Se asigna en el estado del registros[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
              // en 0) el string "FESTIVO" ya que todos los dias que entren en este if son festivos
              registros[dia-1].estado = "FESTIVO"
            }

            // Si la fechaDia (primer for) = a la fecha de los registros (segundo for) && la fecha dia (primer for)
            // es igual a la fecha de los dias festivos (tercer for) entonces se ejecuta el if
            if (fechaDia === registrosDB[i].fecha && fechaDia === diasMesFestivos[d].dia) {

              // Se asigna en el registro[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
              // en 0) la fecha (que es la misma que ya estaba asignada), el registro, y el estado festivo ya que
              // estoy dentro del for de los dias festivos (tercer for)
              registros[dia-1] = {fechaDia: fechaDia, registro: registrosDB[i], estado: "FESTIVO"}
            }
          }
        } 
      } 

      // Else => Si los registros obtenidos de la base de datos es un array vacio entonces se ejecuta este if
      else if (registrosDB.length === 0) {

        // Creo un for que recorre todos los dias festivos ya que hemos visto que 
        // no hay registros este mes y minimo hay un dia festivo
        for (let d = 0; d < diasMesFestivos.length; d++) {

          // Si la fechaDia (primer for) es igual a la fecha de cada dia festivo (anterior for) entonces se ejecuta
          if (fechaDia === diasMesFestivos[d].dia) {

            // Se asigna en el registro[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
            // en 0) la fecha (que es la misma que ya estaba asignada), el registro se deja en blanco ya que el array
            // de registros es 0 y el estado en festivo ya que en este for estamos recorriendo los dias festivos sin registros
            registros[dia-1] = {fechaDia: fechaDia, registro: "", estado: "FESTIVO"}
          } 
        } 
      } 

      // Else => Si los dias festivos obtenidos de la base de datos es un array vacio entonces se ejecuta este if
      else if (diasMesFestivos.length === 0) {

        // Creo un for que recorre todos los registros ya que hemos visto que 
        // no hay dias festivos este mes y minimo hay un registro
        for (let i = 0; i < registrosDB.length; i++) {

          // Si la fechaDia (primer for) es igual a la fecha de cada registro (anterior for) entonces se ejecuta
          if (fechaDia === registrosDB[i].fecha) {

            // Se asigna en el registro[dia-1] (dia-1 porque el dia del mes empieza en 1 pero los arrays empiezan
            // en 0) la fecha (que es la misma que ya estaba asignada), en el registro se asigna el registro de este for
            // y el estado en laboral ya que en el if de antes estamos comprobando que no hay ningun dia festivo
            registros[dia-1] = {fechaDia: fechaDia, registro: registrosDB[i], estado: "LABORAL"}
          } 
        }
      }
    }
    setBRegistros(true)
  }

  function onClickRegistros () {
      verRegistros(trabajador, añoSelect, mesSelect)
  }

  function onChangeAño (e) {
      // console.log(e.target.value)
      setAñoSelect(e.target.value)
      setRegistrosNull()
  }

  function onChangeMes (e) {
      // console.log(e.target.value)
      setMesSelect(e.target.value)
      setRegistrosNull()
  }

  function setRegistrosNull() {
      setBRegistros(false)
      setRegistros([])
  }

  function logout() {
    signOut(auth)
      .then(() => {
        // console.log("OUT");
        navigate("/login");
      })
      .catch((error) => {
        // console.error(error);
        // console.log("error logout");
      });

    //console.log("OUT");
    //auth().logout();
    //window.location.href = "login.html";
  }

  function panelAdmin() {
    if (trabajador.admin === true) navigate("/admin")
    else alert("No tienes permisos")
  }

  window.document.body.addEventListener("click", () => {
    if (display === "block") setDisplay("none")
  }) 

  function onChangeDisplay() {
    // Aplico un timeout para que se ejecute antes el window.document.body.addEventListener y luego este if
    setTimeout(() => {
      if (display === "none") setDisplay("block")
      else setDisplay("none")
    }, 10)
  }
  
  return (
    <header>
      <nav style={{backgroundColor: colorProject}} className="navheader">
        { PROJECT_APP === ECINNOVA && 
          <>
            <a href="/#/update">
              <img src={logoblanco}
                width="50"
                height="50"
              />
            </a>
            <a href="/#/update">
              <h1 className="colorwhite title-page">EC-INNOVA</h1>
            </a>
          </>
        }
        { PROJECT_APP === INSERTATOLEDO && 
          <a href="/#/update">
            <h1 className="colorwhite pm-0">INSERTA TOLEDO</h1>
          </a>
        }
        { PROJECT_APP === CARITASTOLEDO && 
          <a href="/#/update">
            <h1 className="colorwhite pm-0">CARITAS TOLEDO</h1>
          </a>
        }

        <div className="dropdowncss mleft-auto" onClick={onChangeDisplay}>
          { PROJECT_APP === ECINNOVA && 
            <>
              <img src={settings}
                width="20"
                height="20"
                className="margintop-2"
              />
              <span className="vdropdown">v</span>
            </>
          }
          { PROJECT_APP === INSERTATOLEDO && 
            <span className="marginleft-8">v</span>
          }
          { PROJECT_APP === CARITASTOLEDO && 
            <span className="marginleft-8">v</span>
          }
          <div style={{display: display}} className="dropdownmenucss">
            <button onClick={openModal}>Ver mis registros</button>
            <button onClick={panelAdmin}>Panel admin</button>
            <button onClick={logout}>Cerrar sesión</button>
          </div>
        </div>
        <ModalVerRegistros isOpen={isOpenModal} closeModal={closeModal}>
          <div className="marginbottom-8">
            <div className="textleft titulo2">{trabajador.name} {trabajador.apellidos}</div>
            <hr /> 
            <div>
              Año:&nbsp;
              <select onChange={onChangeAño} defaultValue={añoSelect}>
                {YEARS.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
              &nbsp;

              Mes:&nbsp;
              <select onChange={onChangeMes} defaultValue={mesSelect}>
                {MONTHS.map((month) => (
                  <option key={month.number} value={month.number}>{month.name}</option>
                ))}
              </select>
              &nbsp;&nbsp;
              <button className="acceptButton" onClick={onClickRegistros}>Ver Registros</button>
            </div>
          </div>
        <RegistroDias registros={registros} trabajador={trabajador} bRegistros={bRegistros} admin={false} bajaVacaciones={false}></RegistroDias>
        </ModalVerRegistros>
      </nav>
    </header>
  );
}
