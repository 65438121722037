import { useState } from "react"
import { ModalsEditarTrabajador } from "./modalEditarTrabajador/ModalsEditarTrabajador";
import { updateTrabajador } from "../../firebase/firebase";
import { ModalsBajaVacaciones } from "./modalBajaVacaciones/ModalsBajaVacaciones";

export function Trabajador({ trabajador, mostrarTrabajador, centros, horariosPrestablecidos }) {
  const [state, setState] = useState(trabajador.estado)

  function onClickSeleccionar() {
    mostrarTrabajador(trabajador)
  }

  async function onClickDesactivar() {
    trabajador.estado = false
    await updateTrabajador(trabajador)
    setState(false)
  }

  async function onClickActivar() {
    trabajador.estado = true
    await updateTrabajador(trabajador)
    setState(true)
  }

  return (
  <>
    <tr className="alturaColumna">

      { state && 
        <td>{trabajador.name} {trabajador.apellidos}</td>
      }
      { state && 
        <td>{trabajador.email}</td>
      }
      { !state && 
        <td><del>{trabajador.name} {trabajador.apellidos}</del></td>
      }
      { !state && 
        <td><del>{trabajador.email}</del></td>
      }
      
      <td>
        <div id="buttonListInWorkersList">
          <button className="acceptButton" onClick={onClickSeleccionar}>Ver_Registros</button>
          <ModalsBajaVacaciones trabajador={trabajador} />
          <ModalsEditarTrabajador trabajador={trabajador} centros={centros} horariosPrestablecidos={horariosPrestablecidos} />

          { state && 
            <button className="cancelButton" onClick={onClickDesactivar}>Desactivar</button>
          }
          { !state && 
            <button className="successButton" onClick={onClickActivar}>&nbsp;&nbsp;Activar&nbsp;&nbsp;</button>
          }

        </div>
      </td>
    </tr>
  </>
  );
}