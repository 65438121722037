import React from "react"
import { useState, useEffect } from "react"
import { updateTrabajador, auth, auth2, deleteAlias, addAlias, aliasExists } from "../../../firebase/firebase"
import { ModalEditarTrabajador } from "./ModalEditarTrabajador"
import { useModalEditarTrabajador } from "./UseModalEditarTrabajador"
import { sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updatePassword } from "firebase/auth";
import { PROJECT_APP, ECINNOVA, INSERTATOLEDO, CARITASTOLEDO } from "./../../../js/project"
import { calcHorasSemanales } from "../../../js/functions"

export function ModalsEditarTrabajador({ trabajador, centros, horariosPrestablecidos }) {
  const [isOpenModal, openModal, closeModal] = useModalEditarTrabajador(false)
  const [nombre, setNombre] = useState(trabajador.name)
  const [dni, setDNI] = useState(trabajador.dni)
  const [stateHorarioPrestablecido, setStateHorarioPrestablecido] = useState(true)
  const [stateHorarioIndividual, setStateHorarioIndividual] = useState(false)
  const [horarioPre, setHorarioPre] = useState("1")
  const horarioVacio = [
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //DOMINGO     0
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //LUNES       1
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //MARTES      2
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //MIERCOLES   3
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //JUEVES      4
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //VIERNES     5
      {inicio:"", fin:"", inicioTarde:"", finTarde:"", dobleDia:false}, //SABADO      6
  ]
  const [horarioIndividual, setHorarioIndividual] = useState(horarioVacio)

  const stateDiasHorario = [
      {mañana:true, tarde:false, dobleDia:false}, //DOMINGO     0
      {mañana:true, tarde:false, dobleDia:false}, //LUNES       1
      {mañana:true, tarde:false, dobleDia:false}, //MARTES      2
      {mañana:true, tarde:false, dobleDia:false}, //MIERCOLES   3
      {mañana:true, tarde:false, dobleDia:false}, //JUEVES      4
      {mañana:true, tarde:false, dobleDia:false}, //VIERNES     5
      {mañana:true, tarde:false, dobleDia:false}, //SABADO      6
  ]

  const [booleansHorarioIndividual, setBooleansHorarioIndividual] = useState(stateDiasHorario)
  const [tardeLunes, setTardeLunes] = useState(false)
  const [mañanaLunes, setMañanaLunes] = useState(true)
  const [dobleDiaLunes, setDobleDiaLunes] = useState(false)
  const [tardeMartes, setTardeMartes] = useState(false)
  const [mañanaMartes, setMañanaMartes] = useState(true)
  const [dobleDiaMartes, setDobleDiaMartes] = useState(false)
  const [tardeMiercoles, setTardeMiercoles] = useState(false)
  const [mañanaMiercoles, setMañanaMiercoles] = useState(true)
  const [dobleDiaMiercoles, setDobleDiaMiercoles] = useState(false)
  const [tardeJueves, setTardeJueves] = useState(false)
  const [mañanaJueves, setMañanaJueves] = useState(true)
  const [dobleDiaJueves, setDobleDiaJueves] = useState(false)
  const [tardeViernes, setTardeViernes] = useState(false)
  const [mañanaViernes, setMañanaViernes] = useState(true)
  const [dobleDiaViernes, setDobleDiaViernes] = useState(false)
  const [tardeSabado, setTardeSabado] = useState(false)
  const [mañanaSabado, setMañanaSabado] = useState(false)
  const [dobleDiaSabado, setDobleDiaSabado] = useState(false)
  const [tardeDomingo, setTardeDomingo] = useState(false)
  const [mañanaDomingo, setMañanaDomingo] = useState(false)
  const [dobleDiaDomingo, setDobleDiaDomingo] = useState(false)
  const [mensajeErrorHorarioIndividual, setMensajeErrorHorarioIndividual] = useState("")
  const [totalHorasSemanales, setTotalHorasSemanales] = useState("00:00")
  const [trabajadorExterno, setTrabajadorExterno] = useState(trabajador.externo)
  const [trabajadorActivo, setTrabajadorActivo] = useState(trabajador.estado)
  const [admin, setAdmin] = useState(trabajador.admin)
  const [apellidos, setApellidos] = useState(trabajador.apellidos)
  const [mensajeRestablecerContraseña, setMensajeRestablecerContraseña] = useState("")
  const [stateRestablecerContraseña, setStateRestablecerContraseña] = useState(false)
  const [antiguaContraseña, setAntiguaContraseña] = useState("")
  const [nuevaContraseña, setNuevaContraseña] = useState("")
  const [mensajeContraseña, setMensajeContraseña] = useState("")
  const [stateCambiarContraseña, setStateCambiarContraseña] = useState(false)
  const [colorTextoErrorCambiarContraseña, setColorTextoErrorCambiarContraseña] = useState(true)
  const [verAntiguaContraseña, setVerAntiguaContraseña] = useState(false)
  const [verNuevaContraseña, setVerNuevaContraseña] = useState(false)
  const [alias, setAlias] = useState(trabajador.alias)
  const [centro, setCentro] = useState(trabajador.centro ?? "")

  useEffect(() => {
    if (trabajador.horarioPre !== undefined) { 
      setStateHorarioPrestablecido(trabajador.horarioPre)
      if(trabajador.horarioPre === true) {
        setStateHorarioIndividual(false)
        setStateHorarioPrestablecido(true)
        setHorarioPre(trabajador.horario)
      }
      else {
        setStateHorarioIndividual(true)
        setStateHorarioPrestablecido(false)
      }
    }
    if (trabajador.dias !== undefined) {
      setHorarioIndividual(trabajador.dias)
      setBooleans(trabajador.dias)
      setTotalHorasSemanales(calcHorasSemanales(trabajador.dias))
    } 
  }, [])

  function setBooleans(horarioTrabajador) {
    for (let i = 0; i < horarioTrabajador.length; i++) {
      if(horarioTrabajador[i].inicio !== "") {
        booleansHorarioIndividual[i].mañana = true
      }
      else {
        booleansHorarioIndividual[i].mañana = false
      }

      if(horarioTrabajador[i].inicioTarde !== "") {
        booleansHorarioIndividual[i].tarde = true
      }
      else {
        booleansHorarioIndividual[i].tarde = false
      }

      if(horarioTrabajador[i].dobleDia === true) {
        booleansHorarioIndividual[i].dobleDia = true
      }
      else {
        booleansHorarioIndividual[i].dobleDia = false
      }
    }
    setBooleansCheckboxes()
  }

  function setBooleansCheckboxes() {
    if (booleansHorarioIndividual[0].mañana === true) setMañanaDomingo(true); else setMañanaDomingo(false)
    if (booleansHorarioIndividual[0].tarde === true) setTardeDomingo(true); else setTardeDomingo(false)
    if (booleansHorarioIndividual[0].dobleDia === true) setDobleDiaDomingo(true); else setDobleDiaDomingo(false)

    if (booleansHorarioIndividual[1].mañana === true) setMañanaLunes(true); else setMañanaLunes(false)
    if (booleansHorarioIndividual[1].tarde === true) setTardeLunes(true); else setTardeLunes(false)
    if (booleansHorarioIndividual[1].dobleDia === true) setDobleDiaLunes(true); else setDobleDiaLunes(false)

    if (booleansHorarioIndividual[2].mañana === true) setMañanaMartes(true); else setMañanaMartes(false)
    if (booleansHorarioIndividual[2].tarde === true) setTardeMartes(true); else setTardeMartes(false)
    if (booleansHorarioIndividual[2].dobleDia === true) setDobleDiaMartes(true); else setDobleDiaMartes(false)

    if (booleansHorarioIndividual[3].mañana === true) setMañanaMiercoles(true); else setMañanaMiercoles(false)
    if (booleansHorarioIndividual[3].tarde === true) setTardeMiercoles(true); else setTardeMiercoles(false)
    if (booleansHorarioIndividual[3].dobleDia === true) setDobleDiaMiercoles(true); else setDobleDiaMiercoles(false)

    if (booleansHorarioIndividual[4].mañana === true) setMañanaJueves(true); else setMañanaJueves(false)
    if (booleansHorarioIndividual[4].tarde === true) setTardeJueves(true); else setTardeJueves(false)
    if (booleansHorarioIndividual[4].dobleDia === true) setDobleDiaJueves(true); else setDobleDiaJueves(false)

    if (booleansHorarioIndividual[5].mañana === true) setMañanaViernes(true); else setMañanaViernes(false)
    if (booleansHorarioIndividual[5].tarde === true) setTardeViernes(true); else setTardeViernes(false)
    if (booleansHorarioIndividual[5].dobleDia === true) setDobleDiaViernes(true); else setDobleDiaViernes(false)

    if (booleansHorarioIndividual[6].mañana === true) setMañanaSabado(true); else setMañanaSabado(false)
    if (booleansHorarioIndividual[6].tarde === true) setTardeSabado(true); else setTardeSabado(false)
    if (booleansHorarioIndividual[6].dobleDia === true) setDobleDiaSabado(true); else setDobleDiaSabado(false)
  }

  function onChangeHorarioPrestablecido(e) {
    if (e.target.value === "false") {
      setStateHorarioPrestablecido(true)
      setStateHorarioIndividual(false)
    }
  }

  function onChangeHorarioIndividual(e) {
    if (e.target.value === "false") {
      setStateHorarioIndividual(true)
      setStateHorarioPrestablecido(false)
    }
  }

  async function onClickAceptar() {
    //si el alias no está en blanco y existe en alias se lanza error y retorna la funcion para que no se ejecute
    if (alias !== "" && alias !== trabajador.alias) {
      const existe = await aliasExists(alias)
      if (existe === true) {
        setMensajeErrorHorarioIndividual("El alias proporcionado ya está en uso, por favor usa otro")
        return;
      }
    }

    comprobarCheckboxes()

    if (stateHorarioIndividual === true) {
      for (let i = 0; i < horarioIndividual.length; i++) {
        const arrInicio = horarioIndividual[i].inicio.split(":")
        const arrFin = horarioIndividual[i].fin.split(":")
        if (parseInt(arrInicio[0]) < 24 && parseInt(arrInicio[0]) >= 0) {
        } else {
          if (i === 0 && mañanaDomingo === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Domingo (turno mañana)"); return "" }
          if (i === 1 && mañanaLunes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Lunes (turno mañana)"); return "" }
          if (i === 2 && mañanaMartes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Martes (turno mañana)"); return "" }
          if (i === 3 && mañanaMiercoles === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Miercoles (turno mañana)"); return "" }
          if (i === 4 && mañanaJueves === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Jueves (turno mañana)"); return "" }
          if (i === 5 && mañanaViernes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Viernes (turno mañana)"); return "" }
          if (i === 6 && mañanaSabado === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Sabado (turno mañana)"); return "" }
        }

        if (parseInt(arrInicio[1]) < 60 && parseInt(arrInicio[1]) >= 0) {
        } else {
          if (i === 0 && mañanaDomingo === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Domingo (turno mañana)");  return "" }
          if (i === 1 && mañanaLunes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Lunes (turno mañana)"); return "" }
          if (i === 2 && mañanaMartes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Martes (turno mañana)"); return "" }
          if (i === 3 && mañanaMiercoles === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Miercoles (turno mañana)"); return "" }
          if (i === 4 && mañanaJueves === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Jueves (turno mañana)"); return "" }
          if (i === 5 && mañanaViernes === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Viernes (turno mañana)"); return "" }
          if (i === 6 && mañanaSabado === true) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Sabado (turno mañana)"); return "" }
        } 

        if (parseInt(arrFin[0]) < 24 && parseInt(arrFin[0]) >= 0) {
        } else {
          if (i === 0 && mañanaDomingo === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Domingo (turno mañana)"); return "" }
          if (i === 1 && mañanaLunes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Lunes (turno mañana)"); return "" }
          if (i === 2 && mañanaMartes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Martes (turno mañana)"); return "" }
          if (i === 3 && mañanaMiercoles === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Miercoles (turno mañana)"); return "" }
          if (i === 4 && mañanaJueves === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Jueves (turno mañana)"); return "" }
          if (i === 5 && mañanaViernes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Viernes (turno mañana)"); return "" }
          if (i === 6 && mañanaSabado === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Sabado (turno mañana)"); return "" }
        } 

        if (parseInt(arrFin[1]) < 60 && parseInt(arrInicio[1]) >= 0) {
        } else {
          if (i === 0 && mañanaDomingo === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Domingo (turno mañana)"); return "" }
          if (i === 1 && mañanaLunes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Lunes (turno mañana)"); return "" }
          if (i === 2 && mañanaMartes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Martes (turno mañana)"); return "" }
          if (i === 3 && mañanaMiercoles === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Miercoles (turno mañana)"); return "" }
          if (i === 4 && mañanaJueves === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Jueves (turno mañana)"); return "" }
          if (i === 5 && mañanaViernes === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Viernes (turno mañana)"); return "" }
          if (i === 6 && mañanaSabado === true) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Sabado (turno mañana)"); return "" }
        }
      }
      for (let i = 0; i < horarioIndividual.length; i++) {
        const arrInicio = horarioIndividual[i].inicioTarde.split(":")
        const arrFin = horarioIndividual[i].finTarde.split(":")
        if (parseInt(arrInicio[0]) < 24 && parseInt(arrInicio[0]) >= 0) {
        } else {
          if (i === 0 && tardeDomingo === true && !dobleDiaDomingo) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Domingo (turno tarde)"); return "" }
          if (i === 1 && tardeLunes === true && !dobleDiaLunes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Lunes (turno tarde)"); return "" }
          if (i === 2 && tardeMartes === true && !dobleDiaMartes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Martes (turno tarde)"); return "" }
          if (i === 3 && tardeMiercoles === true && !dobleDiaMiercoles) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Miercoles (turno tarde)"); return "" }
          if (i === 4 && tardeJueves === true && !dobleDiaJueves) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Jueves (turno tarde)"); return "" }
          if (i === 5 && tardeViernes === true && !dobleDiaViernes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Viernes (turno tarde)"); return "" }
          if (i === 6 && tardeSabado === true && !dobleDiaSabado) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Sabado (turno tarde)"); return "" }
        }

        if (parseInt(arrInicio[1]) < 60 && parseInt(arrInicio[1]) >= 0) {
        } else {
          if (i === 0 && tardeDomingo === true && !dobleDiaDomingo) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Domingo (turno tarde)"); return "" }
          if (i === 1 && tardeLunes === true && !dobleDiaLunes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Lunes (turno tarde)"); return "" }
          if (i === 2 && tardeMartes === true && !dobleDiaMartes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Martes (turno tarde)"); return "" }
          if (i === 3 && tardeMiercoles === true && !dobleDiaMiercoles) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Miercoles (turno tarde)"); return "" }
          if (i === 4 && tardeJueves === true && !dobleDiaJueves) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Jueves (turno tarde)"); return "" }
          if (i === 5 && tardeViernes === true && !dobleDiaViernes) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Viernes (turno tarde)"); return "" }
          if (i === 6 && tardeSabado === true && !dobleDiaSabado) { setMensajeErrorHorarioIndividual("Error en la hora de inicio del Sabado (turno tarde)"); return "" }
        } 

        if (parseInt(arrFin[0]) < 24 && parseInt(arrFin[0]) >= 0) {
        } else {
          if (i === 0 && tardeDomingo === true && !dobleDiaDomingo) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Domingo (turno tarde)"); return "" }
          if (i === 1 && tardeLunes === true && !dobleDiaLunes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Lunes (turno tarde)"); return "" }
          if (i === 2 && tardeMartes === true && !dobleDiaMartes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Martes (turno tarde)"); return "" }
          if (i === 3 && tardeMiercoles === true && !dobleDiaMiercoles) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Miercoles (turno tarde)"); return "" }
          if (i === 4 && tardeJueves === true && !dobleDiaJueves) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Jueves (turno tarde)"); return "" }
          if (i === 5 && tardeViernes === true && !dobleDiaViernes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Viernes (turno tarde)"); return "" }
          if (i === 6 && tardeSabado === true && !dobleDiaSabado) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Sabado (turno tarde)"); return "" }
        } 

        if (parseInt(arrFin[1]) < 60 && parseInt(arrInicio[1]) >= 0) {
        } else {
          if (i === 0 && tardeDomingo === true && !dobleDiaDomingo) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Domingo (turno tarde)"); return "" }
          if (i === 1 && tardeLunes === true && !dobleDiaLunes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Lunes (turno tarde)"); return "" }
          if (i === 2 && tardeMartes === true && !dobleDiaMartes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Martes (turno tarde)"); return "" }
          if (i === 3 && tardeMiercoles === true && !dobleDiaMiercoles) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Miercoles (turno tarde)"); return "" }
          if (i === 4 && tardeJueves === true && !dobleDiaJueves) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Jueves (turno tarde)"); return "" }
          if (i === 5 && tardeViernes === true && !dobleDiaViernes) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Viernes (turno tarde)"); return "" }
          if (i === 6 && tardeSabado === true && !dobleDiaSabado) { setMensajeErrorHorarioIndividual("Error en la hora de fin del Sabado (turno tarde)"); return "" }
        }
      }
      setMensajeErrorHorarioIndividual("")
      const aliasAntiguo = trabajador.alias

      trabajador.horarioPre = false
      trabajador.dias = horarioIndividual
      trabajador.externo = trabajadorExterno
      trabajador.estado = trabajadorActivo
      trabajador.name = nombre
      trabajador.apellidos = apellidos
      trabajador.dni = dni
      trabajador.alias = alias
      trabajador.centro = centro
      trabajador.admin = admin
      await updateTrabajador(trabajador)
      if (aliasAntiguo !== trabajador.alias) {
        await deleteAlias(aliasAntiguo)
        if (alias !== "") {
          await addAlias({ alias: alias, email: trabajador.email })
        }
      }
      window.location.reload()

    } else {
      const aliasAntiguo = trabajador.alias

      trabajador.horarioPre = true
      if (horarioPre !== null) trabajador.horario = horarioPre
      else trabajador.horario = horariosPrestablecidos[0].id
      trabajador.externo = trabajadorExterno
      trabajador.estado = trabajadorActivo
      trabajador.name = nombre
      trabajador.apellidos = apellidos
      trabajador.dni = dni
      trabajador.alias = alias
      trabajador.centro = centro
      trabajador.admin = admin
      await updateTrabajador(trabajador)
      if (aliasAntiguo !== trabajador.alias) {
        await deleteAlias(aliasAntiguo)
        if (alias !== "") {
          await addAlias({ alias: alias, email: trabajador.email })
        }
      }
      window.location.reload()
    }
  }

  function comprobarCheckboxes() {
    if (mañanaLunes === false) { horarioIndividual[1].inicio = ""; horarioIndividual[1].fin = ""; horarioIndividual[1].inicioTarde = ""; horarioIndividual[1].finTarde = "" }
    if (mañanaMartes === false) { horarioIndividual[2].inicio = ""; horarioIndividual[2].fin = ""; horarioIndividual[2].inicioTarde = ""; horarioIndividual[2].finTarde = "" }
    if (mañanaMiercoles === false) { horarioIndividual[3].inicio = ""; horarioIndividual[3].fin = ""; horarioIndividual[3].inicioTarde = ""; horarioIndividual[3].finTarde = "" }
    if (mañanaJueves === false) { horarioIndividual[4].inicio = ""; horarioIndividual[4].fin = ""; horarioIndividual[4].inicioTarde = ""; horarioIndividual[4].finTarde = "" }
    if (mañanaViernes === false) { horarioIndividual[5].inicio = ""; horarioIndividual[5].fin = ""; horarioIndividual[5].inicioTarde = ""; horarioIndividual[5].finTarde = "" }
    if (mañanaSabado === false) { horarioIndividual[6].inicio = ""; horarioIndividual[6].fin = ""; horarioIndividual[6].inicioTarde = ""; horarioIndividual[6].finTarde = "" }
    if (mañanaDomingo === false) { horarioIndividual[0].inicio = ""; horarioIndividual[0].fin = ""; horarioIndividual[0].inicioTarde = ""; horarioIndividual[0].finTarde = "" }

    if (tardeLunes === false || dobleDiaLunes === true) { horarioIndividual[1].inicioTarde = ""; horarioIndividual[1].finTarde = "" }
    if (tardeMartes === false || dobleDiaMartes === true) { horarioIndividual[2].inicioTarde = ""; horarioIndividual[2].finTarde = "" }
    if (tardeMiercoles === false || dobleDiaMiercoles === true) { horarioIndividual[3].inicioTarde = ""; horarioIndividual[3].finTarde = "" }
    if (tardeJueves === false || dobleDiaJueves === true) { horarioIndividual[4].inicioTarde = ""; horarioIndividual[4].finTarde = "" }
    if (tardeViernes === false || dobleDiaViernes === true) { horarioIndividual[5].inicioTarde = ""; horarioIndividual[5].finTarde = "" }
    if (tardeSabado === false || dobleDiaSabado === true) { horarioIndividual[6].inicioTarde = ""; horarioIndividual[6].finTarde = "" }
    if (tardeDomingo === false || dobleDiaDomingo === true) { horarioIndividual[0].inicioTarde = ""; horarioIndividual[0].finTarde = "" }
  }

  function onChangeMañanaLunes(e) {
    if (e.target.value === "true") { setMañanaLunes(false); setTardeLunes(false); setDobleDiaLunes(false); horarioIndividual[1].dobleDia = false }
    if (e.target.value === "false") { setMañanaLunes(true); }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaMartes(e) {
    if (e.target.value === "true") { setMañanaMartes(false); setTardeMartes(false); setDobleDiaMartes(false); horarioIndividual[2].dobleDia = false }
    if (e.target.value === "false") { setMañanaMartes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaMiercoles(e) {
    if (e.target.value === "true") { setMañanaMiercoles(false); setTardeMiercoles(false); setDobleDiaMiercoles(false); horarioIndividual[3].dobleDia = false }
    if (e.target.value === "false") { setMañanaMiercoles(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaJueves(e) {
    if (e.target.value === "true") { setMañanaJueves(false); setTardeJueves(false); setDobleDiaJueves(false); horarioIndividual[4].dobleDia = false }
    if (e.target.value === "false") { setMañanaJueves(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaViernes(e) {
    if (e.target.value === "true") {  setMañanaViernes(false); setTardeViernes(false); setDobleDiaViernes(false); horarioIndividual[5].dobleDia = false }
    if (e.target.value === "false") { setMañanaViernes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaSabado(e) {
    if (e.target.value === "true") { setMañanaSabado(false); setTardeSabado(false); setDobleDiaSabado(false); horarioIndividual[6].dobleDia = false }
    if (e.target.value === "false") { setMañanaSabado(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeMañanaDomingo(e) {
    if (e.target.value === "true") { setMañanaDomingo(false); setTardeDomingo(false); setDobleDiaDomingo(false); horarioIndividual[0].dobleDia = false }
    if (e.target.value === "false") { setMañanaDomingo(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeLunes(e) {
    if (e.target.value === "true") { setTardeLunes(false) }
    if (e.target.value === "false") { setTardeLunes(true); setMañanaLunes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeMartes(e) {
    if (e.target.value === "true") { setTardeMartes(false) }
    if (e.target.value === "false") { setTardeMartes(true); setMañanaMartes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeMiercoles(e) {
    if (e.target.value === "true") { setTardeMiercoles(false) }
    if (e.target.value === "false") { setTardeMiercoles(true); setMañanaMiercoles(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeJueves(e) {
    if (e.target.value === "true") { setTardeJueves(false) }
    if (e.target.value === "false") { setTardeJueves(true); setMañanaJueves(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeViernes(e) {
    if (e.target.value === "true") { setTardeViernes(false) }
    if (e.target.value === "false") { setTardeViernes(true); setMañanaViernes(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeSabado(e) {
    if (e.target.value === "true") { setTardeSabado(false) }
    if (e.target.value === "false") { setTardeSabado(true); setMañanaSabado(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTardeDomingo(e) {
    if (e.target.value === "true") { setTardeDomingo(false) }
    if (e.target.value === "false") { setTardeDomingo(true); setMañanaDomingo(true) }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeDobleDiaLunes(e) {
    if (e.target.value === "true") {
      setDobleDiaLunes(false)
      horarioIndividual[1].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaLunes(true); 
      horarioIndividual[1].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }
  
  function onChangeDobleDiaMartes(e) {
    if (e.target.value === "true") {
      setDobleDiaMartes(false); 
      horarioIndividual[2].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaMartes(true); 
      horarioIndividual[2].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }
  
  function onChangeDobleDiaMiercoles(e) {
    if (e.target.value === "true") {
      setDobleDiaMiercoles(false); 
      horarioIndividual[3].dobleDia = false  
    } 
    else if (e.target.value === "false") {
      setDobleDiaMiercoles(true); 
      horarioIndividual[3].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }
  
  function onChangeDobleDiaJueves(e) {
    if (e.target.value === "true") {
      setDobleDiaJueves(false); 
      horarioIndividual[4].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaJueves(true); 
      horarioIndividual[4].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }
  
  function onChangeDobleDiaViernes(e) {
    if (e.target.value === "true") {
      setDobleDiaViernes(false); 
      horarioIndividual[5].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaViernes(true); 
      horarioIndividual[5].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }
  
  function onChangeDobleDiaSabado(e) {
    if (e.target.value === "true") {
      setDobleDiaSabado(false); 
      horarioIndividual[6].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaSabado(true); 
      horarioIndividual[6].dobleDia = true
    } 
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }
  
  function onChangeDobleDiaDomingo(e) {
    if (e.target.value === "true") {
      setDobleDiaDomingo(false); 
      horarioIndividual[0].dobleDia = false
    } 
    else if (e.target.value === "false") {
      setDobleDiaDomingo(true); 
      horarioIndividual[0].dobleDia = true
    }
    setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))
  }

  function onChangeTrabajadorActivo(e) {
    if (e.target.value === "true") setTrabajadorActivo(false)
    if (e.target.value === "false") setTrabajadorActivo(true)
  }

  function onChangeTrabajadorExterno(e) {
    if (e.target.value === "true") setTrabajadorExterno(false)
    if (e.target.value === "false") setTrabajadorExterno(true)
  }

  function onChangeAdmin(e) {
    if (e.target.value === "true") setAdmin(false)
    if (e.target.value === "false") setAdmin(true)
  }

  function onClickRestablecerContraseña() {
    //reset password email
    sendPasswordResetEmail(auth, trabajador.email)
    .then(() => {
    //   console.log("EMAIL TO "+trabajador.email)
    })
    .catch((error) => {
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // console.log(errorCode)
      // console.log(errorMessage)
    });
    setMensajeRestablecerContraseña("Correo enviado")
    setStateRestablecerContraseña(true)
  }

  function onClickCambiarContraseña() {
    setStateCambiarContraseña(true)

    if (nuevaContraseña === "") {
      setColorTextoErrorCambiarContraseña(true)
      setMensajeContraseña("La contraseña está en blanco")
      setStateCambiarContraseña(false)
      return;
    }

    else if (nuevaContraseña.length < 6) {
      setColorTextoErrorCambiarContraseña(true)
      setMensajeContraseña("La contraseña tiene que tener mínimo 6 caracteres")
      setStateCambiarContraseña(false)
      return;
    }

    signInWithEmailAndPassword(auth2, trabajador.email, antiguaContraseña)
      .then((userCredential) => {
        // const user = userCredential.user
        // console.log(user);
        updatePassword(auth2.currentUser, nuevaContraseña)
        .then(() => {
          signOut(auth2)
          .then(() => {
            setColorTextoErrorCambiarContraseña(false)
            setMensajeContraseña("Contraseña cambiada satisfactoriamente")
          })
          .catch((error) => {
            // console.error(error);
            // console.log("error logout");
            setColorTextoErrorCambiarContraseña(true)
            setMensajeContraseña("Error al cambiar la contraseña1")
            setStateCambiarContraseña(false)
          });
        })
        .catch((error) => {
          // An error occurred
          // ...
          // console.log(error);
          setColorTextoErrorCambiarContraseña(true)
          setMensajeContraseña("Error al cambiar la contraseña2")
          setStateCambiarContraseña(false)
        })
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // console.log("error al entrar");
        // setErrorLogin("Usuario o contraseña incorrectos")
        console.error(error.code);
        setColorTextoErrorCambiarContraseña(true)
        if (error.code === "auth/wrong-password") { setMensajeContraseña("Antigua contraseña incorrecta"); setStateCambiarContraseña(false); return; }
        if (error.code === "auth/too-many-requests") { setMensajeContraseña("Cuenta bloqueada temporalmente por demasiados intentos, prueba mas tarde"); setStateCambiarContraseña(false); return; }
        else setMensajeContraseña("Error al cambiar la contraseña3")
        setStateCambiarContraseña(false)
      });
  }

  return (
    <>
      <button className="secondaryButton" onClick={openModal}>Modificar</button>
      <ModalEditarTrabajador isOpen={isOpenModal} closeModal={closeModal}>
        <article id="editWorker">
          <div className="titulo2">Editar trabajador</div>
          <hr />
          <div className="titulo3">Datos del trabajador</div>
          <table className="dataTable">
            <tbody>
              <tr>
                <td><label>Correo electronico</label></td>
                <td><input type="text" disabled={true} defaultValue={trabajador.email} /></td>
              </tr>
              <tr>
                <td><label>Nombre</label></td>
                <td><input type="text" defaultValue={nombre} onChange={(e) => {setNombre(e.target.value)}} /></td>
              </tr>
              <tr>
                <td><label>Apellidos</label></td>
                <td><input type="text" defaultValue={apellidos} onChange={(e) => {setApellidos(e.target.value)}} /></td>
              </tr>
              <tr>
                <td><label>Alias</label></td>
                <td><input type="text" defaultValue={alias} onChange={(e) => {setAlias(e.target.value)}} /></td>
              </tr>
              <tr>
                <td><label>DNI</label></td>
                <td><input type="text" defaultValue={dni} onChange={(e) => {setDNI(e.target.value)}} /></td>
              </tr>
              { PROJECT_APP === ECINNOVA && 
                <></>
              }
              { PROJECT_APP === INSERTATOLEDO && 
                <></>
              }
              { PROJECT_APP === CARITASTOLEDO && 
                <tr>
                  <td><label>Centro de trabajo</label></td>
                  <td>
                  <select value={centro} onChange={(e) => {setCentro(e.target.value)}}>
                      <option key="" value=""></option>
                      {centros.map((centro) => (
                          <option key={centro.name} value={centro.name}>{centro.name}</option>
                      ))}
                  </select>
                  </td>
                </tr>
              }
            </tbody>
          </table>
          <hr />
          {trabajador.correoFicticio !== true &&
            <>
              <div className="titulo3">Restablecimiento de contraseña</div>
              <div>
                <button className="acceptButton marginbottom-16" disabled={stateRestablecerContraseña} onClick={onClickRestablecerContraseña}>Enviar correo de restablecimiento de contraseña</button>
                <span>&nbsp;{mensajeRestablecerContraseña}</span>
              </div>
              <hr />
            </>
          }
          <div className="titulo3">Cambiar contraseña manualmente</div>
          <div>
            <label className="passwordLabel marginright-8">Antigua contraseña:&nbsp;
              {verAntiguaContraseña === false && <input type="password" value={antiguaContraseña} onChange={(e) => {setAntiguaContraseña(e.target.value); setMensajeContraseña("")}} /> }
              {verAntiguaContraseña === true && <input type="text" value={antiguaContraseña} onChange={(e) => {setAntiguaContraseña(e.target.value); setMensajeContraseña("")}} /> }
              <input type="checkbox" checked={verAntiguaContraseña} value={verAntiguaContraseña} onChange={(e) => {setVerAntiguaContraseña(!verAntiguaContraseña)}} />
            </label>
            <label className="passwordLabel marginright-8">Nueva contraseña:&nbsp;
              {verNuevaContraseña === false && <input type="password" value={nuevaContraseña} onChange={(e) => {setNuevaContraseña(e.target.value); setMensajeContraseña("")}} /> }
              {verNuevaContraseña === true && <input type="text" value={nuevaContraseña} onChange={(e) => {setNuevaContraseña(e.target.value); setMensajeContraseña("")}} /> }
              <input type="checkbox" checked={verNuevaContraseña} value={verNuevaContraseña} onChange={(e) => {setVerNuevaContraseña(!verNuevaContraseña)}} />
            </label>
            <button className="acceptButton" disabled={stateCambiarContraseña} onClick={onClickCambiarContraseña}>Cambiar contraseña</button>
          </div>
          <div> 
            {colorTextoErrorCambiarContraseña === true && 
              <span className="colorred">{mensajeContraseña}</span>
            }
            {colorTextoErrorCambiarContraseña === false && 
              <span className="colorgreen">{mensajeContraseña}</span>
            }
          </div>
          <hr />
          <div className="titulo3">Jornada del trabajador</div>
          <div className="paddingy-8">Selecciona horario individual o prestablecido</div>
          <label>
            <input type="checkbox" checked={stateHorarioPrestablecido} value={stateHorarioPrestablecido} onChange={onChangeHorarioPrestablecido}/>
            <span className="text-bold"> Horario Prestablecido</span>
          </label>
          <div>
            <select value={horarioPre} disabled={!stateHorarioPrestablecido} onChange={(e) => {setHorarioPre(e.target.value)}}>
            {horariosPrestablecidos.map((horario) => (
              <option key={horario.name} value={horario.id}>{horario.name}</option>
            ))}
            </select>
          </div>
          <br />
          <label>
            <input type="checkbox" checked={stateHorarioIndividual} value={stateHorarioIndividual} onChange={onChangeHorarioIndividual}/>
            <span className="text-bold"> Horario Individual</span>  
          </label>
          <div>Turno mañana/jornada continua</div>
          <table className="scheduleTable">
            <tbody>
              <tr>
                <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaLunes} value={mañanaLunes} onChange={onChangeMañanaLunes}/></td>
                <td>Lunes</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaLunes || !stateHorarioIndividual} defaultValue={horarioIndividual[1].inicio} onChange={(e) => {horarioIndividual[1].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaLunes || !stateHorarioIndividual} defaultValue={horarioIndividual[1].fin} onChange={(e) => {horarioIndividual[1].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaLunes} checked={dobleDiaLunes} value={dobleDiaLunes} onChange={onChangeDobleDiaLunes}/></td>
              </tr>
              <tr>
                <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaMartes} value={mañanaMartes} onChange={onChangeMañanaMartes}/></td>
                <td>Martes</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaMartes || !stateHorarioIndividual} defaultValue={horarioIndividual[2].inicio} onChange={(e) => {horarioIndividual[2].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaMartes || !stateHorarioIndividual} defaultValue={horarioIndividual[2].fin} onChange={(e) => {horarioIndividual[2].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaMartes} checked={dobleDiaMartes} value={dobleDiaMartes} onChange={onChangeDobleDiaMartes}/></td>
              </tr>
              <tr>
                <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaMiercoles} value={mañanaMiercoles} onChange={onChangeMañanaMiercoles}/></td>
                <td>Miercoles</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaMiercoles || !stateHorarioIndividual} defaultValue={horarioIndividual[3].inicio} onChange={(e) => {horarioIndividual[3].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaMiercoles || !stateHorarioIndividual} defaultValue={horarioIndividual[3].fin} onChange={(e) => {horarioIndividual[3].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaMiercoles} checked={dobleDiaMiercoles} value={dobleDiaMiercoles} onChange={onChangeDobleDiaMiercoles}/></td>
              </tr>
              <tr>
                <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaJueves} value={mañanaJueves} onChange={onChangeMañanaJueves}/></td>
                <td>Jueves</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaJueves || !stateHorarioIndividual} defaultValue={horarioIndividual[4].inicio} onChange={(e) => {horarioIndividual[4].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaJueves || !stateHorarioIndividual} defaultValue={horarioIndividual[4].fin} onChange={(e) => {horarioIndividual[4].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaJueves} checked={dobleDiaJueves} value={dobleDiaJueves} onChange={onChangeDobleDiaJueves}/></td>
              </tr>
              <tr>
                <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaViernes} value={mañanaViernes} onChange={onChangeMañanaViernes}/></td>
                <td>Viernes</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaViernes || !stateHorarioIndividual} defaultValue={horarioIndividual[5].inicio} onChange={(e) => {horarioIndividual[5].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaViernes || !stateHorarioIndividual} defaultValue={horarioIndividual[5].fin} onChange={(e) => {horarioIndividual[5].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaViernes} checked={dobleDiaViernes} value={dobleDiaViernes} onChange={onChangeDobleDiaViernes}/></td>
              </tr>
              <tr>
                <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaSabado} value={mañanaSabado} onChange={onChangeMañanaSabado}/></td>
                <td>Sabado</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaSabado || !stateHorarioIndividual} defaultValue={horarioIndividual[6].inicio} onChange={(e) => {horarioIndividual[6].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaSabado || !stateHorarioIndividual} defaultValue={horarioIndividual[6].fin} onChange={(e) => {horarioIndividual[6].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaSabado} checked={dobleDiaSabado} value={dobleDiaSabado} onChange={onChangeDobleDiaSabado}/></td>
              </tr>
              <tr>
                <td><input type="checkbox" disabled={!stateHorarioIndividual} checked={mañanaDomingo} value={mañanaDomingo} onChange={onChangeMañanaDomingo}/></td>
                <td>Domingo</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaDomingo || !stateHorarioIndividual} defaultValue={horarioIndividual[0].inicio} onChange={(e) => {horarioIndividual[0].inicio = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!mañanaDomingo || !stateHorarioIndividual} defaultValue={horarioIndividual[0].fin} onChange={(e) => {horarioIndividual[0].fin = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td><span>&nbsp;&nbsp;&nbsp;&nbsp;Doble_día:</span></td>
                <td><input type="checkbox" disabled={!stateHorarioIndividual || !mañanaDomingo} checked={dobleDiaDomingo} value={dobleDiaDomingo} onChange={onChangeDobleDiaDomingo}/></td>
              </tr>
            </tbody>
          </table>

          <div>Turno tarde</div>
          <table className="scheduleTable">
            <tbody>
              <tr>
                <td><input type="checkbox" disabled={dobleDiaLunes || !stateHorarioIndividual} checked={tardeLunes} value={tardeLunes} onChange={onChangeTardeLunes}/></td>
                <td>Lunes</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!tardeLunes || dobleDiaLunes || !stateHorarioIndividual} defaultValue={horarioIndividual[1].inicioTarde} onChange={(e) => {horarioIndividual[1].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!tardeLunes || dobleDiaLunes || !stateHorarioIndividual} defaultValue={horarioIndividual[1].finTarde} onChange={(e) => {horarioIndividual[1].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              </tr>
              <tr>
                <td><input type="checkbox" disabled={dobleDiaMartes || !stateHorarioIndividual} checked={tardeMartes} value={tardeMartes} onChange={onChangeTardeMartes}/></td>
                <td>Martes</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!tardeMartes || dobleDiaMartes || !stateHorarioIndividual} defaultValue={horarioIndividual[2].inicioTarde} onChange={(e) => {horarioIndividual[2].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!tardeMartes || dobleDiaMartes || !stateHorarioIndividual} defaultValue={horarioIndividual[2].finTarde} onChange={(e) => {horarioIndividual[2].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              </tr>
              <tr>
                <td><input type="checkbox" disabled={dobleDiaMiercoles || !stateHorarioIndividual} checked={tardeMiercoles} value={tardeMiercoles} onChange={onChangeTardeMiercoles}/></td>
                <td>Miercoles</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!tardeMiercoles || dobleDiaMiercoles || !stateHorarioIndividual} defaultValue={horarioIndividual[3].inicioTarde} onChange={(e) => {horarioIndividual[3].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!tardeMiercoles || dobleDiaMiercoles || !stateHorarioIndividual} defaultValue={horarioIndividual[3].finTarde} onChange={(e) => {horarioIndividual[3].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              </tr>
              <tr>
                <td><input type="checkbox" disabled={dobleDiaJueves || !stateHorarioIndividual} checked={tardeJueves} value={tardeJueves} onChange={onChangeTardeJueves}/></td>
                <td>Jueves</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!tardeJueves || dobleDiaJueves || !stateHorarioIndividual} defaultValue={horarioIndividual[4].inicioTarde} onChange={(e) => {horarioIndividual[4].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!tardeJueves || dobleDiaJueves || !stateHorarioIndividual} defaultValue={horarioIndividual[4].finTarde} onChange={(e) => {horarioIndividual[4].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              </tr>
              <tr>
                <td><input type="checkbox" disabled={dobleDiaViernes || !stateHorarioIndividual} checked={tardeViernes} value={tardeViernes} onChange={onChangeTardeViernes}/></td>
                <td>Viernes</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!tardeViernes || dobleDiaViernes || !stateHorarioIndividual} defaultValue={horarioIndividual[5].inicioTarde} onChange={(e) => {horarioIndividual[5].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!tardeViernes || dobleDiaViernes || !stateHorarioIndividual} defaultValue={horarioIndividual[5].finTarde} onChange={(e) => {horarioIndividual[5].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              </tr>
              <tr>
                <td><input type="checkbox" disabled={dobleDiaSabado || !stateHorarioIndividual} checked={tardeSabado} value={tardeSabado} onChange={onChangeTardeSabado}/></td>
                <td>Sabado</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!tardeSabado || dobleDiaSabado || !stateHorarioIndividual} defaultValue={horarioIndividual[6].inicioTarde} onChange={(e) => {horarioIndividual[6].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!tardeSabado || dobleDiaSabado || !stateHorarioIndividual} defaultValue={horarioIndividual[6].finTarde} onChange={(e) => {horarioIndividual[6].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              </tr>
              <tr>
                <td><input type="checkbox" disabled={dobleDiaDomingo || !stateHorarioIndividual} checked={tardeDomingo} value={tardeDomingo} onChange={onChangeTardeDomingo}/></td>
                <td>Domingo</td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Inicio:</td>
                <td><input type="text" maxLength="5" disabled={!tardeDomingo || dobleDiaDomingo || !stateHorarioIndividual} defaultValue={horarioIndividual[0].inicioTarde} onChange={(e) => {horarioIndividual[0].inicioTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;Fin:</td>
                <td><input type="text" maxLength="5" disabled={!tardeDomingo || dobleDiaDomingo || !stateHorarioIndividual} defaultValue={horarioIndividual[0].finTarde} onChange={(e) => {horarioIndividual[0].finTarde = e.target.value; setTotalHorasSemanales(calcHorasSemanales(horarioIndividual))}}/></td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
              </tr>
            </tbody>
          </table>
          <div className="text-bold">Total Horas: {totalHorasSemanales}</div>
          <hr />
          <div className="titulo3">Otras opciones del trabajador</div>
          <div>
            <div><label><input type="checkbox" checked={trabajadorActivo} value={trabajadorActivo} onChange={onChangeTrabajadorActivo}/>&nbsp; Trabajador Activo</label></div>
            <div><label><input type="checkbox" checked={trabajadorExterno} value={trabajadorExterno} onChange={onChangeTrabajadorExterno}/>&nbsp; Trabajador Externo</label></div>
            <div><label><input type="checkbox" checked={admin} value={admin} onChange={onChangeAdmin}/>&nbsp; Usuario Administrador</label></div>
          </div>
          <br />
          <div className="textright">
            <span className="colorred">{mensajeErrorHorarioIndividual}</span>
            <button className="cancelButton" onClick={closeModal}>Cancelar</button>
            <button className="acceptButton" onClick={onClickAceptar}>Aceptar</button>
          </div>
        </article>
      </ModalEditarTrabajador>
    </>
  )
}
