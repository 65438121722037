import { Trabajador } from "./Trabajador"

export function Wrapper({ trabajadores, currentPage, prevHandler, nextHandler, mostrarTrabajador, paginasTotales, centros, horariosPrestablecidos }) {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th className="anchoCelda">Acciones</th>
          </tr>
        </thead>
        <tbody>
        {trabajadores.map((trabajador) => (
          <Trabajador key={trabajador.uid} trabajador={trabajador} mostrarTrabajador={mostrarTrabajador} centros={centros} horariosPrestablecidos={horariosPrestablecidos} />
        ))}
        </tbody>
      </table>
      <div className="navButtonsListWorkers">
        <button className="acceptButton" onClick={prevHandler}>Anterior</button>
        <span className="px-4">Pagina {currentPage} de {paginasTotales}</span>
        <button className="acceptButton" onClick={nextHandler}>Siguiente</button>
      </div>
    </>
  );
}
